import React from "react";

function Footer() {
  return (
    <footer className="bottom-0 bg-zinc-50 border-t absolute w-full">
      <p className="px-4 py-1 tracking-widest text-start text-xs">
        {new Date().getFullYear()} <span className="font-sans">&copy;</span>{" "}
        Copyrights - Inxite Out
      </p>
    </footer>
  );
}

export default Footer;
