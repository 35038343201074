import React, { useState } from "react";
import FileHandler from "./FileHandler";
import { ChevronDoubleLeftIcon, ClipboardDocumentListIcon, InboxArrowDownIcon, InformationCircleIcon, RectangleStackIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

function SidebarPanel({ panelheader, backbuttonName, upddoc, panelcontent, additionalButtons, info, scrollToBottomTrigger, navigateback }) {
  const [files, setFiles] = useState([]);
  const [showFiles, setShowFiles] = useState(false);
  const [filesTabState, setFilesTabState] = useState(1);
  const navigate=useNavigate();

  const handleResumeClick = () => {
    setShowFiles(!showFiles);
    if(backbuttonName==="Chatbot"){
      scrollToBottomTrigger();
    }
  };

  return (
    <div>
      {(panelheader || upddoc || additionalButtons || info) && (
        <div className={`flex flex-wrap md:flex-row ${!panelheader?'justify-end':'justify-between'} lg:items-center tracking-wider gap-3 md:gap-0 border-b border-gray-300 bg-white ps-[1.10rem] pe-2 py-3`}> {/*px-2 px-[1.10rem] */}
          {panelheader && 
            <p className={`${!upddoc?'text-xl':'text-base'} font-bold text-gray-900 flex gap-2 items-center`}>{panelheader}
              {info && <span>
                <span data-tooltip-id="my-tooltip-children-multiline" className="flex items-center gap-0.5 text-sky-900"><InformationCircleIcon className="h-4 w-auto"/></span>
                  <Tooltip id="my-tooltip-children-multiline" place='bottom-start' style={{ backgroundColor: "#0369a1", color: "white", padding:"0.5rem", zIndex:30, fontSize:"0.75rem", paddingTop:"0.05rem", paddingBottom:"0.05rem", fontFamily:"Google Sans, sans-serif", fontWeight:"normal"}} opacity={100}>
                    {info}
                  </Tooltip>
                </span>
              }
            </p>
          }
          {(upddoc || additionalButtons || info || navigateback) && (
            <div className="flex flex-wrap md:flex-row gap-1 justify-start md:justify-normal bg-white">
              {showFiles===false && additionalButtons && additionalButtons}
              {upddoc && (
                <>
                {showFiles && <><button className={`inline-flex items-center ps-0 xl:ps-2 px-2 py-1 text-sm tracking-wider font-medium ${filesTabState===1 && 'text-sky-700'}`} onClick={()=>setFilesTabState(1)}><RectangleStackIcon className="h-4 w-auto"/>&nbsp;Recent Documents</button><button className={`inline-flex items-center px-2 py-1 text-sm tracking-wider font-medium ${filesTabState===2 && 'text-sky-700'}`} onClick={()=>setFilesTabState(2)}><ClipboardDocumentListIcon className="h-4 w-auto"/>&nbsp;Document Database</button><button className={`inline-flex items-center px-2 py-1 text-sm tracking-wider font-medium ${filesTabState===3 && 'text-sky-700'}`} onClick={()=>setFilesTabState(3)}><InboxArrowDownIcon className="h-4 w-auto"/>&nbsp;Extract Document</button></>}
                <button
                  className="w-fit px-2 py-1 mb-0.5 text-sm tracking-wider"
                  onClick={handleResumeClick}
                >
                  {showFiles? <p className="flex items-center"><ChevronDoubleLeftIcon className="h-4 w-auto"/>{backbuttonName || 'Back'}</p>:
                  <div className="bottomtooltip">
                    <p className="flex items-center gap-0.5"><ClipboardDocumentListIcon className="h-[1.05rem] w-auto"/>Documents</p>
                    <span className="tooltiptextbottom p-1 mb-0 px-2 hidden lg:block bg-sky-700 text-xs">Upload Documents</span>
                  </div>
                  }
                </button>
                </>
              )}
              {navigateback && 
                <button
                className="w-fit px-1 py-1 mb-0.5 text-sm tracking-wider"
                onClick={()=>navigate(-1)}
              ><p className="flex items-center"><ChevronDoubleLeftIcon className="h-4 w-auto"/>{(navigateback && showFiles)? 'Projects' : 'Back'}</p>
              </button>
              }
            </div>
          )}
        </div>
      )}

      {!showFiles && panelcontent}

      {showFiles && <FileHandler files={files} setFiles={setFiles} tabState={filesTabState}/>}
    </div>
  );
}

export default SidebarPanel;