import axios from "axios";
import { API_URL } from "../../http";
import { handleApiError } from "../Common/APIUtils";

// get List of propmt for LLM Ops
export const getPromptList = async () => {
    try {
      const response = await axios.get(`${API_URL}/llmops/prompt`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return response.data;
    } catch (error) {
      const errMsg = handleApiError(error);
      return {'status': 'error', 'message': errMsg};
    }
};

// get the recently deployed propmt for LLM Ops
export const getDeployedPromptsList = async () => {
    try {
      const response = await axios.get(`${API_URL}/llmops/deployprompt`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return response.data;
    } catch (error) {
      const errMsg = handleApiError(error);
      return {'status': 'error', 'message': errMsg};
    }
};

// Function to deploy the prompt
export const deployUpdatePrompt = async (patchData) => {
    try {
      const response = await axios.patch(`${API_URL}/llmops/deployprompt`, patchData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return response.data;
    } catch (error) {
      const errMsg = handleApiError(error);
      return {'status': 'error', 'message': errMsg};
    }
};

// update project
export const updateDeployedPromptStatus = async (patchData) => {
  try {
    const response = await axios.patch(`${API_URL}/llmops/deployprompt/status`, patchData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// Function to save the prompt
export const savePrompt = async (postData) => {
  try {
    const response = await axios.post(`${API_URL}/llmops/prompt`, postData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// update prompt
export const updatePrompt = async (patchData) => {
  try {
    const response = await axios.patch(`${API_URL}/llmops/prompt`, patchData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// update project
export const updatePromptStatus = async (patchData) => {
  try {
    const response = await axios.patch(`${API_URL}/llmops/prompt/status`, patchData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// delete prompt
export const deletePrompt = async (promptID) => {
  try {
    const response = await axios.delete(`${API_URL}/llmops/prompt`,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },      
      params: {
        promptID: promptID
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};