import React, { useState } from 'react'
import SidebarPanel from '../../Common/SidebarPanel'
import { ChevronDoubleLeftIcon, LockClosedIcon, UserIcon } from '@heroicons/react/24/solid';
import UpdateProfile from './UpdateProfile';
import UpdatePassword from './UpdatePassword';
import { useNavigate } from 'react-router-dom';

export default function Profile() {
  const [tabState, setTabState] = useState(1);
  const navigate=useNavigate();

  const tabs = [
    {
      id: 1,
      title: "Update Profile",
      content: (
        <UpdateProfile/>   
      ),
    },
    {
      id: 2,
      title: "Update Password",
      content: (
        <UpdatePassword/>      
      ),
    }
  ].filter(Boolean);

  // Function to get the content for the selected tab
const getTabContent = () => {
  const selectedTab = tabs.find(tab => tab.id === tabState);
  return selectedTab ? selectedTab.content : null;
};
  return (
    <>
    {localStorage.getItem("role")!=="ADM" && 
      <SidebarPanel panelheader={`Hi, ${localStorage.getItem("username")}`} additionalButtons={<><button className={`inline-flex items-center px-2 py-1 text-base tracking-wider font-medium ${tabState===1 && 'text-sky-700'}`} onClick={()=>setTabState(1)}><UserIcon className="h-4 w-auto"/>&nbsp;Update Profile</button><button className={`inline-flex items-center px-2 py-1 text-base tracking-wider font-medium ${tabState===2 && 'text-sky-700'}`} onClick={()=>setTabState(2)}><LockClosedIcon className="h-4 w-auto"/>&nbsp;Update Password</button><button className="inline-flex items-center pe-2 py-1 text-base tracking-wider font-medium" onClick={()=>navigate(-1)}><ChevronDoubleLeftIcon className="h-4 me-0.5 w-auto"/>Back</button></>}
        panelcontent={<div className='p-3'>{getTabContent()}</div>}
      />
    }
    </>
  )
}
