import React, { useEffect, useState } from "react";

export const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
        <input type="checkbox" className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600 disabled:text-gray-300 disabled:bg-gray-300"  ref={resolvedRef} {...rest} />
    );
});


// A debounced input react component
export function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  pattern = "^[a-zA-Z0-9 !@#$^&*()-_]*$",
  ...props
}) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleInputChange = React.useCallback(
    (e) => {
      const inputValue = e.target.value;
      if (new RegExp(pattern).test(inputValue)) {
        setValue(inputValue);
        onChange(inputValue);
      }
    },
    [pattern, onChange]
  );

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, debounce, onChange]);

  return <input {...props} className="block font-GoogleSans resp-search-input-w rounded-md border text-gray-900 ring-0 ring-inset ring-white border-gray-300 placeholder:text-gray-400 focus:border-gray-300 focus:ring-inset focus:ring-0 focus:ring-white text-sm" type='search' value={value} onChange={handleInputChange} />;
}

// A debounced input react component
export function SelectTextOptions({
  value: initialValue,
  onChange,
  debounce = 5000000,
  pattern = "^[a-zA-Z0-9 !@#$^&*()-_]*$",
  options,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleInputChange = React.useCallback(
    (e) => {
      const inputValue = e.target.value;
      if (new RegExp(pattern).test(inputValue)) {
        setValue(inputValue);
        onChange(inputValue);
      }
    },
    [pattern, onChange]
  );

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, debounce, onChange]);

  return (
  <select {...props} className="block w-36 font-GoogleSans rounded-md border text-gray-900 ring-0 ring-inset ring-white border-gray-300 placeholder:text-gray-400 focus:border-gray-300 focus:ring-inset focus:ring-0 focus:ring-white text-sm" value={value} onChange={handleInputChange}>
      <option value='' key='0'>All</option>
      {options.map((value, index) => value && (
          <option value={value} key={index}>{value}</option>
      ))}
  </select>
  )
}

export function SelectTextFilter({ column }) {

  const [ls, setls]=useState(null);

  useEffect(()=>{
    setls(column.getFilterValue());
  },[column])

  return (
    <SelectTextOptions
        value={ls ?? ''}
        onChange={(value) => column.setFilterValue(value)}
        options={Array.from(column.getFacetedUniqueValues().keys()).sort().reverse()}
      />
  );
}

export function SelectStatusInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleInputChange = (e) => {
    let inputVal;
    if(e.target.value===''){
      inputVal='';
    }
    else{
      inputVal = e.target.value === "true";
    }
    setValue(inputVal);
    onChange(inputVal);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, debounce, onChange]);

  return (
    <select
      {...props}
      className="block w-36 font-GoogleSans rounded-md border text-gray-900 ring-0 ring-inset ring-white border-gray-300 placeholder:text-gray-400 focus:border-gray-300 focus:ring-inset focus:ring-0 focus:ring-white text-sm"
      value={value===undefined || value===''?'':value.toString()}
      onChange={handleInputChange}
    >
      <option value="">All</option>
      <option value="true">Active</option>
      <option value="false">Inactive</option>
    </select>
  );
}

export function StatusFilter({ column }) {
  const [value, setValue] = useState(column.getFilterValue());

  useEffect(() => {
    setValue(column.getFilterValue());
  }, [column]);

  return <SelectStatusInput value={value} onChange={(value) => column.setFilterValue(value)} />;
}