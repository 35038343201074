import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { CheckBadgeIcon } from '@heroicons/react/24/solid'
import React, { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function Popup({ isOpen, msg, closeModal, isSuccess }) {
  const navigate=useNavigate();
  useEffect(() => {
    if (msg === "Invalid Access Token" || msg==="Session Expired" || msg==="Your user account is not found") {
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        navigate('/');
      }, 3000); // 6 seconds in milliseconds
    }
  }, [msg, navigate]);
  return (
    <Transition appear show={isOpen} as={Fragment}>
<Dialog
  as="div"
  className="relative align-middle justify-center flex z-10"
  onClose={closeModal}
>
  <Transition.Child
    as={Fragment}
    enter="ease-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div className="fixed inset-0 bg-black opacity-25" />
  </Transition.Child>

  <div className="fixed inset-0 overflow-auto">
    <div className="flex align-middle justify-center p-4 pt-6 text-center">
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Dialog.Panel className="transform font-GoogleSans tracking-wider overflow-hidden rounded-md bg-white p-3 text-start justify-center align-middle shadow border transition-all resp-popup-w">
          <div className="mt-2 text-break">
          {!isSuccess && <p className='flex'><ExclamationTriangleIcon className="me-1 text-red-900" style={{height:"22px",width:"22px"}}/><span className='text-sm'>{msg}</span></p>}
          {isSuccess && <p className='flex'><CheckBadgeIcon className="me-1 text-green-700" style={{height:"22px",width:"22px"}}/><span className='text-sm'>{msg}</span></p>}
          </div>

          <div className="flex w-100 justify-end mt-4">
            <button
              className="inline-flex justify-center rounded-lg border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </Dialog.Panel>
      </Transition.Child>
    </div>
  </div>
</Dialog>
</Transition>
  )
}

export default Popup