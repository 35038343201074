import axios from "axios";
import { API_URL, ENCRYPTION_KEY } from "../../http";
import { handleApiError } from "../Common/APIUtils";
import CryptoJS from 'crypto-js';

export const getProjectsList = async () => {
  try {
    const response = await axios.get(`${API_URL}/projects`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// Function to save the project
export const saveProject = async (postData) => {
  try {
    const response = await axios.post(`${API_URL}/projects`, postData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// update project
export const updateProject = async (patchData) => {
  try {
    const response = await axios.patch(`${API_URL}/projects`, patchData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// update project
export const updateProjectStatus = async (patchData) => {
  try {
    const response = await axios.patch(`${API_URL}/projects/status`, patchData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// delete project
export const deleteProject = async (project_code) => {
  try {
    const response = await axios.delete(`${API_URL}/projects`,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },      
      params: {
        project_code: project_code
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

export const ActiveProjectswithClientName = async () => {
  try {
    const response = await axios.get(`${API_URL}/projects/active`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// Encrypt function
export const encryptData = (data) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
  return btoa(encrypted.toString());
};

// Decrypt function
export const decryptData = (encryptedData) => {
  const decrypted = CryptoJS.AES.decrypt(atob(encryptedData), ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decrypted.toString());
};
