export const modules = [
    {
      id: 1,
      name: "RIA Chatbot",
      value: "M1",
    }
];

export const sources = [
  {
    id: 1,
    name: "Youtube",
    value: "youtube",
    subtypes: [
      {
      name: "Comments",
      value: "comments",
      },
      {
        name: "Transcription",
        value: "transcript",
      },
    ]
  }
];

export const experimentation_modules = modules

export const ria_features=[
  {
    name: 'LLM Ops',
    desc: 'Enables prompt engeneering, llm instructions tuening and deployment of prompts.',
  },
  {
    name: 'Resumes Insights Extractor',
    desc: 'Extracts insights from the resumes.',
  },
  {
    name: 'Resumes Analyzer',
    desc: 'Perform in-depth exploration & cross-comparision of resumes data between 1 to many & many 2 many candidates.',
  },
  {
    name: 'RIA Chatbot',
    desc: ' Experience conversational AI',
  },
  {
    name: 'Playground',
    desc: 'Play with different LLM models (Open AI & Non-Open AI) of your choice and select the best model.',
  },
  {
    name: 'Knowledge Hub',
    desc: 'Digital informaiton hub designed to be your go-to destination for treasure trove of knowledge.',
  },
]

export const standard_chat_limits = [
  {
    id: 1,
    name: "Free Limits",
    value: null,
  },
  {
    id: 2,
    name: "5",
    value: 5,
  },
  {
    id: 3,
    name: "10",
    value: 10,
  },
  {
    id: 4,
    name: "15",
    value: 15,
  },
  {
    id: 5,
    name: "20",
    value: 20,
  },
  {
    id: 6,
    name: "30",
    value: 30,
  },
];