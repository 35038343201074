import axios from "axios";
import { API_URL } from "../../http";
import { handleApiError } from "../Common/APIUtils";

// Function to fetch the list of user's roles
export const getRolesList = async () => {
  try {
    const response = await axios.get(`${API_URL}/users/roles`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

export const getusersList = async () => {
  try {
    const response = await axios.get(`${API_URL}/users`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// Function to save the user
export const saveUser = async (postData) => {
  try {
    const response = await axios.post(`${API_URL}/users`, postData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// update user
export const updateUser = async (patchData) => {
  try {
    const response = await axios.patch(`${API_URL}/users`, patchData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// update user status
export const updateUserStatus = async (patchData) => {
  try {
    const response = await axios.patch(`${API_URL}/users/status`, patchData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

export const userReset = async (patchData) => {
  try {
    const response = await axios.patch(`${API_URL}/users/autoresetpassword`, patchData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// delete user
export const deleteUser = async (_id) => {
  try {
    const response = await axios.delete(`${API_URL}/users`,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },      
      params: {
        _id: _id
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

