import axios from "axios";
import { API_URL } from "../../http";
import { handleApiError } from "../Common/APIUtils";

// Function to upload & save the files
export const uploadFiles = async (postData) => {
  try {
    const response = await axios.post(`${API_URL}/files/upload`, postData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};


// get List of files
export const getAllFilesList = async (postData) => {
  try {
    const response = await axios.post(`${API_URL}/files`, postData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// Function to download the file
export const downloadFile = async (file_id) => {
  try {
    const response = await axios.get(`${API_URL}/files/download/${file_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// Function to delete the file
export const deleteFile = async (file_id) => {
  try {
    const response = await axios.delete(`${API_URL}/files/delete/${file_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};