import React, { useCallback, useEffect, useState } from 'react'
import SidebarPanel from '../../Common/SidebarPanel'
import VirtualizedTable from '../../Common/VirtualizedTable'
import { ArrowPathIcon, PencilIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { getActiveClientOptions } from '../../Services/ClientServices';
import { deleteProjectAssignment, getProjectAssignmentList, saveProjectAssignment, updateProjectAssignment, updateProjectAssignmentStatus } from '../../Services/ProjectAssignmentServices';
import Popup from '../../Common/Popup';
import ConfirmationPopup from '../../Common/ConfirmationPopup';
import InputModalsm from '../../Common/InputModalsm';
import { formattedDate } from '../../Common/CommonUtils';

function ProjectAssignment() {
    let [data, setData]=useState([]);
    let [options, setOptions]=useState([])
    let [rowData,setRowData]=useState([]);
    let [id, setID] = useState("");
    let [loader, setLoader] = useState(true);

    let [isOpenModal, setIsOpenModal] = useState(false);
    let [editState, setEditState] = useState(false);

    function closeModal() {
      setIsOpenModal(false);
      setRowData([]);
    }

    const openModal = useCallback((option, project_data) => {
      if (option === "create") {
        setEditState(false);
        setRowData([]);
      } else {
        const projectMatch = options.client_projects.find(project => project.project_code === project_data.project_code);
        const updatedRowData = {
          ...project_data,
          client_code: projectMatch ? projectMatch.client_code : '' // Check if projectMatch exists
        };
        setEditState(true);
        setRowData(updatedRowData);
      }
      setIsOpenModal(true);
    }, [options.client_projects]);

    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);
  
    function closePopup() {
      setIsOpenPopup(false);
    }
  
    function openPopup() {
      setIsOpenPopup(true);
    }

    let [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
    let [modalTitle, setModalTitle] = useState('');
  
    function closeStatusModal() {
      setIsOpenStatusModal(false);
    }
  
    function openStatusModal(e) {
      setRowData(e);
      setModalTitle('Update Project Status')
      setIsOpenStatusModal(true);
    }
  
    const handleStatusState = async() => {
      let statusData = { status: rowData.status === false || rowData.status === 'false' ? false : true };
      updateProjectAssignmentStatus({"_id":rowData._id,"status":statusData.status})
        .then(response => {
          if (response.status==='success') {
            closeStatusModal();
            setMsg(response.message);
            setIsSuccess(true);
            openPopup();
          } else {
            closeStatusModal();
            setMsg(response.message);
            setIsSuccess(false);
            openPopup();
          }
        })
        .finally(()=>{
          refreshProjectAssignmentList();
        });
    };

    const refreshProjectAssignmentList = async () => {
        setLoader(true);
        getProjectAssignmentList().then((response) => {
            if(response.status==='success' && response.data!==null){
            setData(response.data);
            }
            else{
            setData([]);
            }
        })
        .catch((error) => {
            setData([]);
            setMsg(error);
            setIsSuccess(false);
            openPopup();
        })
        .finally(()=>{
            setLoader(false);
        });
      };
  
    const handleSaveProject = () => {
      saveProjectAssignment({"project_code":rowData.project_code,"user_id":rowData.user_id})
        .then(response => {
          if (response.status==='success') {
            closeModal();
            setMsg(response.message);
            setIsSuccess(true);
            openPopup();
          } else {
            closeModal();
            setMsg(response.message);
            setIsSuccess(false);
            openPopup();
          }
        })
        .finally(()=>{
          refreshProjectAssignmentList();
        });
    };
  
    const handleEditProject = () => {
      updateProjectAssignment({"_id":rowData._id,"project_code":rowData.project_code,"user_id":rowData.user_id})
        .then(response => {
          if (response.status==='success') {
            closeModal();
            setMsg(response.message);
            setIsSuccess(true);
            openPopup();
          } else {
            closeModal();
            setMsg(response.message);
            setIsSuccess(false);
            openPopup();
          }
        })
        .finally(()=>{
          refreshProjectAssignmentList();
        });
    };

    const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
    const [confirmationMsg, setConfirmationMsg] = useState("");
  
    function closeConfirmPopup() {
      setIsOpenConfirmPopup(false);
    }
  
    function openConfirmPopup(id_prop) {
      setID(id_prop);
      setConfirmationMsg("Are you sure that you want to delete this project assignment?");
      setIsOpenConfirmPopup(true);
    }
  
    const deleteFn = async() => {
      setIsOpenConfirmPopup(false);
      const response = await deleteProjectAssignment(id);
      if (response.status === 'success') {
        setData((prevData) => {
          const newData = prevData.filter(
              (item) => item['_id'] !== id
          );
          return newData;
        });
        setMsg(response.message);
        setIsSuccess(true);
        openPopup();
      } 
      else {
        setMsg(response.message);
        setIsSuccess(false);
        openPopup();
      }
    }
  
    const DeleteButton = ({ assignment_id }) => {  
      return (
          <button
              className="p-1 rounded-md border border-gray-300 text-red-700 disabled:text-gray-300"
              onClick={()=>openConfirmPopup(assignment_id)}
          >
                  <TrashIcon height="1em" title='Delete Project Assignment' />
          </button>
      );
    };
  
    const columns = React.useMemo(
      () => [
          {
              accessorKey: "index",
              enableColumnFilter: false,
              enableSorting: false,
              size: 50,
              header: "S.No."
          },
          localStorage.getItem('role') !== "PMA" && {
            accessorKey: "client_name",
            enableColumnFilter: true,
            size: 100,
            header: () => <span>Client Name</span>
          },
          {
            accessorKey: "project_code",
            enableColumnFilter: false,
            size: 100,
            header: () => <span>Project Code</span>
          },
          {
              accessorKey: "project_name",
              enableColumnFilter: true,
              size: 300,
              header: () => <span>Project Name</span>,
              cell: ({ row }) => (
                <div title={row.original.project_name}>
                  <span className='md:hidden'>
                  {row.original.project_name}
                  </span>
                  <span className='hidden md:flex'>
                    {row.original.project_name !== null
                        ? row.original.project_name.slice(0, 40) +
                          (row.original.project_name.length > 40 ? "..." : "")
                        : null}
                  </span>
                </div>
              ),
          },
          {
            accessorKey: "user_id",
            enableColumnFilter: false,
            size: 100,
            header: () => <span>User ID</span>
          },
          {
            accessorKey: "user_name",
            enableColumnFilter: false,
            size: 300,
            header: () => <span>User Name</span>
          },
          {
            accessorKey: "created_date",
            enableColumnFilter: false,
            size: 50,
            header: () => <span>Created Date</span>,
            cell: ({ row }) => (
                <div>
                    {row.original.created_date && row.original.created_date !== "" &&
                        formattedDate(row.original.created_date)}
                </div>
            ),
          },
          {
            accessorKey: "created_by",
            enableColumnFilter: false,
            size: 50,
            header: () => <span>Created By</span>
          },
          {
            accessorKey: "modify_date",
            enableColumnFilter: false,
            size: 50,
            header: () => <span>Modified Date</span>,
            cell: ({ row }) => (
                <div>
                    {row.original.modify_date && row.original.modify_date !== "" &&
                        formattedDate(row.original.modify_date)}
                </div>
            ),
          },
          {
            accessorKey: "modify_by",
            enableColumnFilter: false,
            size: 50,
            header: () => <span>Modified By</span>
          },
          {
            accessorKey: "status",
            enableColumnFilter: true,
            enableSorting:false,
            size: 50,
            header: () => <span>Status</span>,
            cell:({ row }) => (
              <div className='flex justify-center'>
              <div  onClick={()=>openStatusModal(row.original)}
                  className={`${row.original.status === true ? 'bg-gray-100 text-green-900' : 'bg-gray-100 text-red-900'} text-xs w-fit px-2 py-1 font-bold rounded-md cursor-default lg:cursor-pointer`}
              >
                  {row.original.status === true && "Active"}
                  {row.original.status === false && "Inactive"}
              </div>
              </div>      
            )
          },
          {
            accessorKey: "_id",
            enableColumnFilter: false,
            enableSorting: false,
            size: 50,
            header: () => <center>Actions</center>,
            cell: ({ row }) => (
              <div className="flex gap-2 items-center justify-center">
                  <button
                      className="p-1 rounded-md border border-gray-300 text-sky-700 disabled:text-gray-300"
                      disabled={!row.original.status}
                      onClick={()=>openModal("edit",row.original)}
                  >
                          <PencilIcon height="1em" title='Edit Project Assignment' />
                  </button>
                  {localStorage.getItem('role') !== "PMA" && <DeleteButton assignment_id={row.original._id}/>}
              </div>
          ),
          }
      ].filter(Boolean),
      [openModal]
    );
  
    const additionalButtons = (
      <div className='flex gap-2'>
          <span className="group relative">
            <div className="absolute right-7 top-1/2 z-20 mr-0 -translate-y-1/2 whitespace-nowrap rounded-[5px] py-1.5 px-3.5 text-sm text-white opacity-0 group-hover:opacity-100">
              <div className="bottom-full right-0 rounded-md bg-gray-400 px-4 py-2.5 text-xs text-white whitespace-nowrap">
                Assign Project
              </div>
            </div>
            <button className="inline-flex items-center rounded-md p-2 text-gray-700 ring-1 ring-inset ring-gray-300" onClick={()=>openModal("create",null)}>
              <PlusCircleIcon className="h-5 w-auto"/>
            </button>
        </span>
        <button className="inline-flex items-center rounded-md p-2 text-gray-700 ring-1 ring-inset ring-gray-300" onClick={refreshProjectAssignmentList}><ArrowPathIcon className="h-5 w-auto"/></button> 
      </div>
    );

    useEffect(()=>{
        const fetchProjectAssignedList = async () => {
          getProjectAssignmentList()
            .then((response) => {
              if(response.status==='success' && response.data!==null && response.data.length>0){
                setData(response.data);
              }
            })
            .catch((error) => {
              setData([]);
              setMsg(error);
              setIsSuccess(false);
              openPopup();
            })
            .finally(()=>{
              setLoader(false);
            });
        };
        const fetchActiveClientOptions = async () => {
          getActiveClientOptions()
          .then((response) => {
            if(response.status==='success' && response.data){
              setOptions(response.data);
            }
          })
          .catch((error) => {
            setOptions([]);
            setMsg(error);
            setIsSuccess(false);
            openPopup();
          })
          .finally(()=>{
            setLoader(false);
          });
        };
    
        fetchProjectAssignedList();
        fetchActiveClientOptions();
      },[])

  return (
    <>
    <SidebarPanel panelheader="Project Assignment" navigateback={true}/>
    <Popup
      isOpen={isOpenPopup}
      msg={msg}
      closeModal={closePopup}
      isSuccess={isSuccess}
    />
    <ConfirmationPopup
        isOpenConfirmPopup={isOpenConfirmPopup}
        confirmationMsg={confirmationMsg}
        closeConfirmPopup={closeConfirmPopup}
        onStateChange={deleteFn}
    />
    <InputModalsm
        isOpen={isOpenModal}
        closeModal={closeModal}
        title={editState?"Edit Project":"New Project"}
        saveName="Save"
        savefn={editState?handleEditProject:handleSaveProject}
        modalInputs={
            <div className="w-full bg-white space-y-1">
            {localStorage.getItem('role') !== 'PMA' &&
            <>
            <label htmlFor="name" className="block text-sm font-bold leading-6 text-gray-500 pt-2 ps-1">
              Client Name
            </label>
            <select
                  id="client"
                  name="client"
                  className="block w-auto md:w-full rounded-md border-0 py-2 mt-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs lg:max-w-full sm:text-sm sm:leading-6"
                  value={rowData.client_code} 
                  onChange={(e) => setRowData({...rowData, client_code: e.target.value})}
                >
                  <option value="">Select Client</option>
                  {options?.clients?.map((item, index) => (
                    <option key={index} value={item.client_code}>
                      {item.client_name}
                    </option>
                  ))}
                </select>
              </>
              }
                <label htmlFor="projectName" className="block text-sm font-bold leading-6 text-gray-500 pt-2 ps-1">
                  Project Name
                </label>
                <select
                  id="projectName"
                  name="projectName"
                  className="block w-auto md:w-full rounded-md border-0 py-2 mt-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs lg:max-w-full sm:text-sm sm:leading-6"
                  value={rowData.project_code} 
                  onChange={(e) => setRowData({...rowData, project_code: e.target.value})}
                >
                  <option value="">Select Project</option>
                  {options?.client_projects?.filter((client) => localStorage.getItem('role') === 'PMA' || client.client_code === rowData.client_code).map((item, index) => (
                    <option key={index} value={item.project_code}>
                      {item.project_name}
                    </option>
                  ))}
                </select>
                <label htmlFor="userName" className="block text-sm font-bold leading-6 text-gray-500 pt-2 ps-1">
                  User Name
                </label>
                <select
                  id="userName"
                  name="userName"
                  className="block w-auto md:w-full rounded-md border-0 py-2 mt-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs lg:max-w-full sm:text-sm sm:leading-6"
                  value={rowData.user_id} 
                  onChange={(e) => setRowData({...rowData, user_id: e.target.value})}
                >
                  <option value="">Select User</option>
                  {options?.client_nonadmin_users?.filter((client) => localStorage.getItem('role') === 'PMA' || client.client_code === rowData.client_code).map((item, index) => (
                    <option key={index} value={item.user_id}>
                      {item.user_name}
                    </option>
                  ))}
                </select>
            </div>
        }
    />
    <InputModalsm isOpen={isOpenStatusModal} closeModal={closeStatusModal} title={modalTitle} 
        saveName="Save" 
        savefn={handleStatusState}
        modalInputs={
          <div className='flex gap-5'>
          <div className="flex items-center gap-x-2">
                  <input
                    id="status"
                    name="status"
                    type="radio"
                    value="true"
                    checked={rowData.status === true || rowData.status === "true"}
                    onChange={(e) =>
                      setRowData({
                        ...rowData,
                        status: e.target.value,
                      })
                    }
                    autoFocus={false}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
                  />
                  <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                    Active
                  </label>
                </div>
                <div className="flex items-center gap-x-2">
                  <input
                    id="status"
                    name="status"
                    type="radio"
                    value="false"
                    checked={rowData.status === false || rowData.status === "false"}
                    onChange={(e) =>
                      setRowData({
                        ...rowData,
                        status: e.target.value,
                      })
                    }
                    autoFocus={false}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
                  />
                  <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900">
                    Inactive
                  </label>
                </div>
          </div>
        } 
      />
    <div className='m-3 border rounded border-gray-300 bg-white'>
    <VirtualizedTable hcss='max-h-[calc(100vh-16.8rem)] md:max-h-[calc(100vh-17rem)]' data={data} columns={columns} loader={loader} loadermsg="Fetching data" noDataFoundmsg="No data Found" allowSelect={false} additionalButtons={additionalButtons}/>
    </div>
    </>
  )
}

export default ProjectAssignment