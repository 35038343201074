import { DocumentTextIcon } from "@heroicons/react/24/outline";

export function convertBytesToMB(bytes) {
    if (bytes === 0) {
      return "0 KB";
    } else if (bytes < 1024) {
      return `${bytes} Bytes`;
    } else if (bytes >= 1024 && bytes < 1024 * 1024) {
      const kilobytes = bytes / 1024;
      return kilobytes.toFixed(2) + " KB";
    } else {
      const megabytes = bytes / (1024 * 1024);
      return megabytes.toFixed(2) + " MB";
    }
} 

export function BreakTextFileName(file) {
    const words = file.name.split(' ');
    const threshold = 20;
  
    if (words.some(word => word.length > threshold)) {
      return (
        <p className="md:flex md:gap-0.5 border py-3 px-2 mt-2 rounded-md text-sm break-all hyphens-auto" key={file.path}>
          <DocumentTextIcon className="h-4 w-auto hidden md:block"/><span>{file.path} - <span className="text-gray-600">{convertBytesToMB(file.size)}</span></span>
        </p>
      );
    } else {
      return (
        <p className="md:flex md:gap-0.5 border py-3 px-2 mt-2 rounded-md text-sm break-words" key={file.path}>
          <DocumentTextIcon className="h-4 w-auto hidden md:block"/><span>{file.path} - <span className="text-gray-600">{convertBytesToMB(file.size)}</span></span>
        </p>
      );
    }
};

export function getFormattedCurrentDateTime() {
  const filedate = new Date();
  const year = filedate.getFullYear().toString().slice(-2);
  const month = (filedate.getMonth() + 1).toString().padStart(2, '0');
  const day = filedate.getDate().toString().padStart(2, '0');
  const hours = filedate.getHours().toString().padStart(2, '0');
  const minutes = filedate.getMinutes().toString().padStart(2, '0');
  return `${day}-${month}-${year} ${hours}:${minutes}`;
}

export const formattedDate = (dateStr) => {
  const date = new Date(dateStr);
  const formattedDateString = date
      .toLocaleString("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
      })
      .replace(/\//g, "/")
      .replace(",", " ");
  const [month, day, yearAndTime] = formattedDateString.split("/");
  return `${day}-${month}-${yearAndTime}`;
};