import axios from "axios";
import { API_URL } from "../../http";
import { handleApiError } from "../Common/APIUtils";

export const getActiveClientOptions = async () => {
  try {
    const response = await axios.get(`${API_URL}/clients/options`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// Function to fetch the deployed chatbot prompt with the user's old chat for RIA Chatbot
export const getActiveClientsList = async () => {
  try {
    const response = await axios.get(`${API_URL}/clients/active`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

export const getClientsList = async () => {
  try {
    const response = await axios.get(`${API_URL}/clients`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// Function to save the project
export const saveClient = async (postData) => {
  try {
    const response = await axios.post(`${API_URL}/clients`, postData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// update project
export const updateClient = async (patchData) => {
  try {
    const response = await axios.patch(`${API_URL}/clients`, patchData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// update project
export const updateClientStatus = async (patchData) => {
  try {
    const response = await axios.patch(`${API_URL}/clients/status`, patchData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// delete project
export const deleteClient = async (client_code) => {
  try {
    const response = await axios.delete(`${API_URL}/clients`,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },      
      params: {
        client_code: client_code
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};