import React, { Fragment } from "react";
import {
    flexRender,
    getCoreRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import NoDataFound from "./NoDataFound";
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon, FunnelIcon } from "@heroicons/react/24/outline";
import { DebouncedInput, SelectTextFilter, StatusFilter } from "./Filters";
import DataLoader from "./DataLoader";
import { Menu, Transition } from "@headlessui/react";

function VirtualizedTable({data, columns, loader, loadermsg, noDataFoundmsg, additionalButtons, allowSelect, hcss}) {

    const [rowSelection, setRowSelection] = React.useState({});
    const [sorting, setSorting] = React.useState([]);

    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(20);
    const [globalFilter, setGlobalFilter] = React.useState("");
  
  
    const pagination = React.useMemo(
      () => ({
        pageIndex,
        pageSize,
      }),
      [pageIndex, pageSize]
    )

    // Modify the pagination section
    const pageCount = Math.ceil(data?.length / pageSize);
    const pageNumbers = [];

    for (let i = 0; i < pageCount; i++) {
      pageNumbers.push(i);
    }

    const handlePageChange = (e) => {
      const newPageSize = Number(e.target.value);
      setPageSize(newPageSize);
      setPageIndex(0)
    };
  

    const table = useReactTable({
        data,
        columns,
        state: {
            rowSelection,
            sorting,
            pagination,
            globalFilter
        },
        enableRowSelection: allowSelect,
        onRowSelectionChange: setRowSelection,
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    });

    const renderTableCellIndex = (row) => {
        return table.getSortedRowModel().rows.indexOf(row)+1 || row.index+1;
    };

    const tableContainerRef = React.useRef(null);

    const { rows } = table.getRowModel();
    
    const rowVirtualizer = useVirtualizer({
        count: rows?.length,
        estimateSize: () => 33, //estimate row height for accurate scrollbar dragging
        getScrollElement: () => tableContainerRef.current,
        //measure dynamic row height, except in firefox because it measures table border height incorrectly
        measureElement:
          typeof window !== 'undefined' &&
          navigator.userAgent.indexOf('Firefox') === -1
            ? element => element?.getBoundingClientRect().height
            : undefined,
        overscan: data?.length || 0,
    })

  return (
    <div className="font-GoogleSans text-sm">
      <div className="flex justify-between m-2 gap-2">
        <DebouncedInput value={globalFilter ?? ""}  onChange={(value) => setGlobalFilter(String(value))}  placeholder="Search..."/>
        <div className="flex gap-2">
          {additionalButtons}
            <Menu as="div" className="relative">
                        <div>
                            <Menu.Button className="relative justify-center border border-gray-300 rounded-md p-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">
                                <span className="absolute -inset-1.5" />
                                <FunnelIcon className="h-5 w-auto" />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="fixed right-5 z-30 mt-3 w-fit px-2 pb-2 space-y-3 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div>
                                    {data?.length > 0 &&
                                        table
                                            .getHeaderGroups()
                                            .map((headerGroup) => (
                                                <div
                                                    key={headerGroup.id}
                                                    className="space-y-1"
                                                >
                                                    {headerGroup.headers.map(
                                                        (header) => (
                                                            <div
                                                                key={header.id}
                                                            >
                                                                {header.column.getCanFilter() &&
                                                                header.column
                                                                    .id ===
                                                                    "status" ? (
                                                                    <div>
                                                                        <label
                                                                            htmlFor="push-everything"
                                                                            className="block text-xs font-bold tracking-widest leading-6 text-gray-400 ps-1"
                                                                        >
                                                                            Status
                                                                        </label>
                                                                        <StatusFilter
                                                                            column={
                                                                                header.column
                                                                            }
                                                                            table={
                                                                                table
                                                                            }
                                                                        />
                                                                    </div>
                                                                ) : null}
                                                                {header.column.getCanFilter() &&
                                                                    (header.column.id === "client_name" ||
                                                                    header.column.id === "project_name" ||
                                                                    header.column.id === "catg" ||
                                                                    header.column.id === "role") ? (
                                                                    <div>
                                                                        <label
                                                                            htmlFor="push-everything"
                                                                            className="block text-xs font-bold tracking-widest leading-6 text-gray-400 ps-1"
                                                                        >
                                                                          {header.column.id === "client_name" ? "Client" : ""}
                                                                          {header.column.id === "project_name" ? "Project" : ""}
                                                                          {header.column.id === "catg" ? "Category" : ""}
                                                                          {header.column.id === "role" ? "Role" : ""}
                                                                        </label>
                                                                        <SelectTextFilter
                                                                            column={
                                                                                header.column
                                                                            }
                                                                            table={
                                                                                table
                                                                            }
                                                                        />
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            ))}
                                </div>
                            </Menu.Items>
                        </Transition>
            </Menu>
        </div>
      </div>
      {loader && <div className="border-t"><DataLoader msg={loadermsg}/></div>}
            {!loader && data?.length === 0 && <div className="border-t"><NoDataFound msg={noDataFoundmsg}/></div>}
            {!loader && data?.length > 0 && (
                <>
                    <div
                        ref={tableContainerRef}
                        className={`${hcss} border-y overflow-auto border-gray-200 chatboxscroll`}
                    >
                        <table className="flex-inline w-full border-collapse">
                            <thead className="bg-gray-100 text-sky-700">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th
                                                    className={`border-r border-y p-2 whitespace-nowrap border-gray-300 tracking-wider ${header.column.id===columns[columns.length - 1]?.accessorKey && 'border-r-0'}`}
                                                    key={header.id}
                                                    colSpan={header.colSpan}
                                                    style={{
                                                        width: header.getSize(),
                                                    }}
                                                >
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            <div
                                                                className={`${
                                                                    header.column.getCanSort() && header.column.id!=='index' && header.column.id!=='id'
                                                                        ? "cursor-pointer select-none justify-start"
                                                                        : "justify-center"
                                                                } flex`}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                            >
                                                                {flexRender(
                                                                    header
                                                                        .column
                                                                        .columnDef
                                                                        .header,
                                                                    header.getContext()
                                                                )}
                                                                {{
                                                                    asc: " 🔼",
                                                                    desc: " 🔽",
                                                                }[
                                                                    header.column.getIsSorted()
                                                                ] || null}
                                                            </div>
                                                        </>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                                    const row = rows[virtualRow.index];
                                    return (
                                        <tr
                                            data-index={virtualRow.index} //needed for dynamic row height measurement
                                            ref={node => rowVirtualizer.measureElement(node)} //measure dynamic row height
                                            key={row.id}
                                            className="bg-white transition duration-300 ease-in-out hover:bg-gray-50"
                                        >
                                            {row
                                                .getVisibleCells()
                                                .map((cell) => {
                                                    return (
                                                        <td
                                                            className={`border-r ${cell.column.id===columns[columns.length - 1]?.accessorKey && 'border-r-0'} border-y border-gray-300 tracking-wide p-2 ${cell.column.id==='index' && 'text-center'}`}
                                                            key={cell.id}
                                                        >
                                                          <div {...cell.column.id === 'prompt' && { title: cell.getValue() }} className={`${cell.column.id==='prompt' && 'text-ellipsis overflow-hidden w-96 lg:w-full line-clamp-1'} ${cell.column.id!=='prompt' && 'whitespace-nowrap'}`}>
                                                            {cell.column.id==='index' && renderTableCellIndex(row)}
                                                            {cell.column.id!=='index' && flexRender(cell.column.columnDef.cell,cell.getContext())}
                                                          </div>
                                                        </td>
                                                    );
                                                })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='flex gap-1 justify-between flex-row-reverse m-2'>
        <nav className="isolate inline-flex -space-x-px mx-2 md:m-0 bg-white rounded-md text-gray-900" aria-label="Pagination">
            <button title='First Page' onClick={() => setPageIndex(0)}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <ChevronDoubleLeftIcon className="h-4 w-4" aria-hidden="true" />
            </button>
            <button title='Previous Page'
            onClick={() => setPageIndex(pageIndex-1)}
            disabled={pageIndex===0}
              aria-current="page"
              className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
            </button>
            {pageNumbers.map((pageNumber, index) => {
            if (pageNumber === 0 || pageNumber === table.getPageCount() - 1 || Math.abs(pageNumber - pageIndex) <= 1) {
              return (
                <button
                  className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                  key={index}
                  onClick={()=>setPageIndex(pageNumber)}
                >
                  {pageNumber + 1}
                </button>
              );
            } else if (pageNumber === 1 || pageNumber === table.getPageCount() - 2) {
              return <button className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0' key={index}>...</button>;
            }
            return null;
          })}
            <button title='Next Page' onClick={() => setPageIndex(pageIndex+1)}  disabled={pageIndex===(table.getPageCount() - 1)}
              className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
           </button>
            <button title='Last Page' onClick={() => setPageIndex(table.getPageCount() - 1)}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <ChevronDoubleRightIcon className="h-4 w-4" aria-hidden="true" />
            </button>
          </nav>
          <div>
          <div className="sm:col-span-3 flex gap-3">
              <div>
                <select
                  id="records"
                  name="records"
                  value={pageSize}
                  onChange={handlePageChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-700 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  {[20,50,100,200,300,500,1000].map((page)=>(
                  <option className='p-1' key={page} value={page}>Show {page}</option>
                  ))}
                </select>
              </div>
              {Object.keys(rowSelection).length > 0 &&
                <>
                  <button className="rounded-md hidden cursor-default lg:cursor-pointer p-2 md:inline-flex text-sm bg-gray-100 border-gray-300 border">
                    Download
                  </button>
                  <button className="rounded-md hidden cursor-default lg:cursor-pointer p-2 md:inline-flex text-sm bg-gray-100 border-gray-300 border">
                    Delete
                  </button>
                </>
              }
            </div>
          </div>
        </div>
                </>
            )}
    </div>
  )
}

export default VirtualizedTable