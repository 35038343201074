import { Fragment, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

export default function SearchableSingleSelector({items, selected, setSelected, filterby, nooverlap, selectorIndex, additionalshowprop}) {
  const [query, setQuery] = useState('')

  const filteredItems =
  query === ''
    ? items
    : items?.filter((item) =>
        item[filterby]
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      )

  return (
    <div className="w-full">
      <Combobox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left border border-gray-300 p-1 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-sky-300 sm:text-sm">
            <Combobox.Input
              className="w-full border-none py-1.5 pl-2.5 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
              displayValue={(item) => item[selectorIndex] || item?.name}
              title={selected[selectorIndex] || selected?.name}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className={`${nooverlap?'absolute z-20':'z-10'} mt-1 max-h-48 md:max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base ring-0 border border-gray-300 shadow-lg focus:outline-none focus:ring-0 focus-visible:ring-0 sm:text-sm`}>
              {filteredItems?.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredItems?.map((item) => (
                  <Combobox.Option
                    key={item.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-sky-700 text-white' : 'text-gray-900'
                      }`
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block whitespace-pre-wrap text-xs md:text-sm ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {additionalshowprop? <>{additionalshowprop.split(',').map(prop => item[prop] + ' - ')} {item[selectorIndex] || item.name}</> : (item[selectorIndex] || item.name)}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-sky-700'
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}
