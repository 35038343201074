import React, { useState, useEffect } from "react";
import { ChevronDoubleRightIcon, FolderIcon } from "@heroicons/react/24/outline";
import Header from "./Header";
import Footer from "./Footer";
import Loader from "./Loader";
import { Link } from "react-router-dom";
import { Tooltip } from 'react-tooltip';

function Layout({
  component,
  resetLoading,
  sidebar,
  sidebarmenus,
  selectionName
}) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (resetLoading) {
      setIsLoading(true); // Reset loading state when resetLoading is true
    }

    // Simulate a loading delay of 3 seconds
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    // Clear the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [resetLoading]);

  const [selection, setSelection] = useState(selectionName || "Chatbot");
  const [showIconsOnly, setShowIconsOnly] = useState(false);

  useEffect(()=>{
    setSelection(selectionName);
  },[selectionName]);

  const handleMenuItemClick = (name) => {
    setSelection(name);
  };

  return (
    <div className="min-h-screen fixed w-full font-GoogleSans">
      {!sidebar && (
        <>
          <Header />
          <main>
            {isLoading ? (
              <Loader />
            ) : (
              <div className="overflow-auto resp-h bg-[#f4f6fa]">{component}</div>
            )}
          </main>
          <Footer />
        </>
      )}
      {sidebar && (
        <>
          <Header sidebarmenus={sidebarmenus} setSelectedSidebarmenu={handleMenuItemClick}/>
          <div className="flex">
            <div className={`relative resp-h overflow-y-auto bg-zinc-50 ${!showIconsOnly && 'lg:min-w-60 xl:min-w-72'} w-fit border-r hidden md:block`}>
              <nav className='grid py-4 gap-y-3 ease-in-out px-3 lg:px-4'>
                {sidebarmenus.map((item) => (
                  <Link
                    key={item.name}
                    to={item.to+'/'+sessionStorage.getItem('projectName')}
                    data-tooltip-id="my-tooltip" 
                    data-tooltip-content={item.name}
                    onClick={() => handleMenuItemClick(item.name)}
                    className={`p-2 md:p-3 flex items-center cursor-default lg:cursor-pointer rounded-md ${
                      item.name === selection
                        ? "text-white bg-sky-700"
                        : "hover:bg-gray-200"
                    }`}
                  >
                    <item.icon
                      className="flex-shrink-0 h-5 w-5 tooltiptitle"
                      aria-hidden="true"
                    />
                    <span
                      className={`ms-3 me-4 text-sm tracking-widest ${
                        showIconsOnly === false ? "hidden lg:flex" : "hidden"
                      }`}
                    >
                      {item.name}
                    </span>
                  </Link>
                ))}
                <Link
                    key="projects"
                    to="/projects"
                    data-tooltip-id="my-tooltip" 
                    data-tooltip-content="Projects"
                    className={`p-2 md:p-3 flex items-center cursor-default lg:cursor-pointer rounded-md ${
                      "Projects" === selection
                        ? "text-white bg-sky-700"
                        : "hover:bg-gray-200"
                    }`}
                  >
                    <FolderIcon
                      className="flex-shrink-0 h-5 w-5 tooltiptitle"
                      aria-hidden="true"
                    />
                    <span
                      className={`ms-3 me-4 text-sm tracking-widest ${
                        showIconsOnly === false ? "hidden lg:flex" : "hidden"
                      }`}
                    >
                      Projects
                    </span>
                  </Link>
              </nav>
              <button
                onClick={() => setShowIconsOnly(!showIconsOnly)}
                className={`absolute bottom-1 border-t p-2 md:px-6 lg:px-7 md:py-4 min-w-full items-center cursor-default lg:cursor-pointer hidden lg:flex`}
              >
                <ChevronDoubleRightIcon
                  className={`flex-shrink-0 h-5 w-5 ${
                    showIconsOnly === false ? "rotate-180" : ""
                  }`}
                  aria-hidden="true"
                />
                <span
                  className={`ms-3 me-4 text-sm tracking-widest ${
                    showIconsOnly === false ? "hidden lg:flex" : "hidden"
                  }`}
                >
                  Collapse
                </span>
              </button>
            </div>
            <div className="overflow-auto resp-h bg-white w-full">
            <Tooltip id="my-tooltip" place='right' className={`hidden ${showIconsOnly && 'lg:block'}`} style={{ backgroundColor: "#0369a1", color: "white", padding:"0.5rem", zIndex:30}} opacity={100}/>
              {component}
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}

export default Layout;