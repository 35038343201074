import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { Link, useNavigate} from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon, UserCircleIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Header({ sidebarmenus, setSelectedSidebarmenu }) {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("logout-event", "logout");
    navigate("/");
  };

  const [initials, setInitials]=useState('')

  useLayoutEffect(() => {
    let name = localStorage.getItem('username');
    const arr = name?.split(" ");
    if(arr){
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/gu);
    let initials = [...name.matchAll(rgx)] || [];
    if (arr.length > 1) {
      setInitials(
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
      );
    } else {
      setInitials(name.slice(0, 2));
    }
    }
  }, []);
  

  useEffect(() => {
    const handleLogoutEvent = (event) => {
      // Check if logout-event key is changed
      if (event.key === "logout-event") {
        // Clear localStorage
        localStorage.clear();
        // Redirect to login page
        navigate("/");
      }
    };
    // Add the listener to the window object
    window.addEventListener("storage", handleLogoutEvent);
    // Return a cleanup function to remove the listener
    return () => {
      window.removeEventListener("storage", handleLogoutEvent);
    };
  }, [navigate]);

  return (
    <header className="bg-white select-none">
      <nav
        className="mx-auto flex w-full border-b items-center justify-between p-1 px-2 bg-zinc-50"
        aria-label="Global"
      >
        <Link to="/" className="p-1.5 px-2">
          <img
            className="h-10 w-auto scale-110"
            src="/ixologo.png"
            alt="Logo"
          />
        </Link>
        <>
          <div className={`hidden md:flex text-xl lg:text-3xl font-bold text-sky-900 tracking-widest ${!localStorage.getItem("access_token") && 'mr-[5.6rem]'}`}> Responsive Intelligence Assistant (RIA)
          </div>
        </>
        {!localStorage.getItem("access_token") && (
          <div>&nbsp;</div>
        )}
        {localStorage.getItem("access_token") && (
        <div className="md:gap-2 hidden md:flex">
          <Menu as="div" className="hidden md:block relative">
          <div>
            <Menu.Button className="relative flex cursor-default lg:cursor-pointer rounded-full p-1 px-1.5 text-sm border border-sky-700 md:border-none ring-gray-800 bg-gray-200 uppercase">
            <div className="bottomtooltip flex">
              <span className="absolute -inset-1.5" />
              <UserCircleIcon className="h-5 w-auto" />&nbsp;{initials}&nbsp;
              <span className="tooltiptextbottom text-xs p-1 mb-1 hidden lg:block bg-black w-fit px-2 whitespace-nowrap me-1">{localStorage.getItem('username')}</span>
            </div>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-20 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {localStorage.getItem("access_token") && (
                 <>
                 { localStorage.getItem("role")!=="ADM" && 
                 <Menu.Item>
                    {({ active }) => (
                      <Link
                        to='/profile'
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700"
                        )}
                      >
                        Profile
                      </Link>
                    )}
                  </Menu.Item>
                  }
                 <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={handleLogout}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700"
                        )}
                      >
                        Sign Out
                      </div>
                    )}
                  </Menu.Item>
                 </>
              )}
            </Menu.Items>
          </Transition>
        </Menu>
        </div>
        )}


        {/*mobile devices navigation dropdown */}
        {localStorage.getItem('access_token') && (
        <div className="flex md:hidden">
        <Menu as="div" className="relative mx-3 md:hidden">
          <div>
            <Menu.Button className="relative flex cursor-default lg:cursor-pointer rounded-md p-1 text-sm border-2 bg-white border-sky-700 ring-gray-800">
              <span className="absolute -inset-1.5" />
              <EllipsisHorizontalIcon className="h-5 w-auto" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-20 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {localStorage.getItem("access_token") && (
                <>
                {localStorage.getItem("role")!=="ADM" && 
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to='/profile'
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700"
                        )}
                      >
                        Profile
                      </Link>
                    )}
                  </Menu.Item>
                }
                  {sidebarmenus &&
                    sidebarmenus.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <Link
                            to={item.to+'/'+sessionStorage.getItem('projectName')}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            {item.name}
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={handleLogout}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700"
                        )}
                      >
                        Sign Out
                      </div>
                    )}
                  </Menu.Item>
                </>
              )}
            </Menu.Items>
          </Transition>
        </Menu>
        </div>
        )}
      </nav>
    </header>
  );
}

export default Header;
