import React, { Fragment, useEffect, useState } from 'react'
import { deleteChat, getChatsList, updateChatTitle } from '../../Services/ChatbotServices';
import { EllipsisVerticalIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';
import Popup from '../../Common/Popup';
import ConfirmationPopup from '../../Common/ConfirmationPopup';

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

function ChatList({selectedqueryId, handleSelectedChat, handleNewChat}) {

  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [msg, setMsg] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);

  function closePopup() {
    setIsOpenPopup(false);
  }

  function openPopup() {
    setIsOpenPopup(true);
  }

  const [deleteChatID, setDeleteChatID] = useState(0);    
  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState("");

  function closeConfirmPopup() {
    setIsOpenConfirmPopup(false);
  }

  function openConfirmPopup(query_id) {
    setDeleteChatID(query_id);
    setConfirmationMsg("Are you sure that you want to delete this chat?");
    setIsOpenConfirmPopup(true);
  }

  const [editChatTitleID, setEditChatTitleID] = useState(0);
  const [editedChatTitle, setEditedChatTitle] = useState('')
  const [chatsList, setChatsList] = useState([]);
  const [loader, setLoader] = useState(true);

  const [maxCount, setMaxCount] = useState(0);

  useEffect(() => {
    const calculateMaxCount = () => {
      const screenHeight = window.innerHeight;
      const liHeight = 52; // Adjust this value based on your li element height
      const calculatedMaxCount = Math.floor(screenHeight / liHeight);
      setMaxCount(calculatedMaxCount);
    };

    calculateMaxCount();

    // Add event listener for window resize
    window.addEventListener('resize', calculateMaxCount);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', calculateMaxCount);
    };
  }, []);

    useEffect(()=>{
        const fetchChatsList = async () => {
            const chatlist = await getChatsList();
            if(chatlist.status === 'success' && chatlist.data!==null) {
              setChatsList(chatlist.data);
            }
            else{
              setChatsList([]);
            }
            setTimeout(() => {
              setLoader(false);
            }, 100);
        }

        fetchChatsList();
    },[])

    const validateInput = (value) => {
        // Regular expression for no consecutive spaces and not starting with a space
        const textPattern = /^(?!.* {2})[^ ]+[a-zA-Z0-9 !@#$^&*()-_]*$/;
        const errormsg= textPattern.test(value);
        return errormsg;
    };

    const handleEditChatTitle=(query_id, chat_title)=>{
        setEditChatTitleID(query_id);
        setEditedChatTitle(chat_title);
    }

    const handleUpdateChatTitle=()=>{
        if(editedChatTitle.length>0 && validateInput(editedChatTitle)){
        updateChatTitle({"queryID":editChatTitleID,"queryTitle":editedChatTitle}).then(response => {
            if (response.status==='success') {
                // Update the chatsList array with the new chat title
                setChatsList(chatsList.map(chat => {
                if (chat.query_id === editChatTitleID) {
                    // If this chat matches the edited chat, update the title
                    return {...chat, name: editedChatTitle};
                }
                return chat;
                }));

                // Reset state after successful update
                setEditChatTitleID(0);
                setEditedChatTitle('');
            }
            else {
                setEditChatTitleID(0);
                setEditedChatTitle('');
            }
        })
        }
        else {
            setEditChatTitleID(0);
            setEditedChatTitle('');
        }
    }

    const handleDeleteChat=()=>{
      setIsOpenConfirmPopup(false);
      const sessionChatbotQueryID=sessionStorage.getItem('chatbotQueryID');
      deleteChat(deleteChatID).then(response => {
          if (response.status==='success') {
              if(parseInt(sessionChatbotQueryID)===deleteChatID && chatsList.length===1){
                 // Empty the chatList and make newChat - when all the chats are deleted
                  setChatsList([]);
                  sessionStorage.removeItem('chatbotQueryID');
                  handleNewChat();
              }
              else if(parseInt(sessionChatbotQueryID)===deleteChatID && chatsList.length>1){
                // Update the chatsList array by removing the deleted chat item
                setChatsList(chatsList.filter(chat => chat.query_id !== deleteChatID));
                // set the selected chat as the latest chat - when the user is deleting the selected chat
                const latest_query_id=chatsList.filter(chat => chat.query_id !== deleteChatID)[0].query_id;
                handleSelectedChat(latest_query_id);
                sessionStorage.setItem('chatbotQueryID', latest_query_id);
              }
              else {
                // Update the chatsList array by removing the deleted chat item - if it is not the same selected chat
                setChatsList(chatsList.filter(chat => chat.query_id !== deleteChatID));
              }
              setMsg(response.message);
              setIsSuccess(true);
              openPopup();
          }
          else {
            setMsg(response.message);
            setIsSuccess(false);
            openPopup();
          }
      })
      // Reset state after successful update
      setDeleteChatID(0);
    }

    const ChatOptions=({query_idprop, chat_titleprop})=>{
        return(
        <Menu as="div" className="relative">
          <div>
            <Menu.Button className="relative cursor-default lg:cursor-pointer">
              <EllipsisVerticalIcon className="h-5 w-auto" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-30 mt-3 w-32 origin-top-right rounded-md border bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                 <>
                 <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={()=>handleEditChatTitle(query_idprop, chat_titleprop)}
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "flex gap-2 items-center px-4 py-2 text-sm text-gray-700 cursor-default lg:cursor-pointer"
                      )}
                    >
                      <PencilSquareIcon className='h-[1em] w-auto'/>Rename
                    </div>
                  )}
                </Menu.Item>
                 <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={()=>openConfirmPopup(query_idprop)}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "flex gap-2 items-center  px-4 py-2 text-sm text-red-700 cursor-default lg:cursor-pointer"
                        )}
                      >
                        <TrashIcon className='h-[1em] w-auto'/>Delete
                      </div>
                    )}
                  </Menu.Item>
                 </>
            </Menu.Items>
          </Transition>
        </Menu>
    )}

    function BreakTextChatTitlename(item) {
      
        const words = item.name.split(' ');
        const threshold = 36;
        const charLimit=45

        if (words.some(word => word.length > threshold)) {
          return (
            <li className={`border rounded-md p-2 ${selectedqueryId!==item.query_id?'bg-gray-100 border-gray-400':'text-white bg-sky-700'} flex items-center justify-between gap-2 break-all hyphens-auto`}>
              {editChatTitleID===item.query_id?<><input type='text' autoFocus className='border rounded-md p-1 text-sm px-2 w-full border-gray-300 focus:ring-1 focus:ring-gray-200 focus:border focus:border-gray-100 text-black' value={editedChatTitle} onChange={(e)=>setEditedChatTitle(e.target.value)}/><button onClick={handleUpdateChatTitle} className='text-xs tracking-wider bg-gray-50 rounded-md text-black p-1 px-2 border border-gray-300 cursor-default lg:cursor-pointer'>Save</button></>:<><div  className='cursor-default lg:cursor-pointer' onClick={()=>handleSelectedChat(item.query_id)}>{item.name.length > charLimit ? item.name.substring(0, charLimit) + '...' : item.name}</div><ChatOptions query_idprop={item.query_id} chat_titleprop={item.name}/></>}
            </li>
          );
        } else {
          return (
            <li className={`border rounded-md p-2 ${selectedqueryId!==item.query_id?'bg-gray-100 border-gray-400':'text-white bg-sky-700'} flex items-center justify-between gap-2 break-words`}>
              {editChatTitleID===item.query_id?<><input type='text' autoFocus className='border rounded-md p-1 text-sm px-2 w-full border-gray-300 focus:ring-1 focus:ring-gray-200 focus:border focus:border-gray-100 text-black' value={editedChatTitle} onChange={(e)=>setEditedChatTitle(e.target.value)}/><button onClick={handleUpdateChatTitle} className='text-xs tracking-wider bg-gray-50 rounded-md text-black p-1 px-2 border border-gray-300 cursor-default lg:cursor-pointer'>Save</button></>:<><div className='cursor-default lg:cursor-pointer' onClick={()=>handleSelectedChat(item.query_id)}>{item.name.length > charLimit ? item.name.substring(0, charLimit) + '...' : item.name}</div><ChatOptions query_idprop={item.query_id} chat_titleprop={item.name}/></>}
            </li>
          );
        }
    };

  return (
    <>
    <Popup
      isOpen={isOpenPopup}
      msg={msg}
      closeModal={closePopup}
      isSuccess={isSuccess}
    />
    <ConfirmationPopup
      isOpenConfirmPopup={isOpenConfirmPopup}
      confirmationMsg={confirmationMsg}
      closeConfirmPopup={closeConfirmPopup}
      onStateChange={handleDeleteChat}
    />
    <ul className='space-y-2.5 text-sm'>  
    {loader && Array(maxCount).fill(null).map((_, index) => (
        <li key={index} className={`rounded-md animate-pulse px-2 py-5 bg-gray-200 bg-opacity-60`}></li>
    ))}
    {!loader && chatsList && chatsList.length>0 && chatsList.map((item)=>
        <BreakTextChatTitlename key={item.query_id} {...item}/>
    )}
    {!loader && chatsList && chatsList.length===0 && (
        <li className='p-2 flex items-center justify-center'>No Chats Found</li>
    )}
    </ul>
    </>
  )
}

export default ChatList