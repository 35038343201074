import React, { useCallback, useLayoutEffect, useState } from 'react'
import Popup from '../../Common/Popup';
import { GetUserAssignedActiveProjects } from '../../Services/ProjectAssignmentServices';
import SidebarPanel from '../../Common/SidebarPanel';
import { Link } from 'react-router-dom';
import { encryptData } from '../../Services/ProjectServices';

function Projects() {
   
  const threshold = 20;
  const [projects, setProjects]=useState([]);
  const [loaders, setLoaders] = useState(true);

  

  const BreakText = (text,created_date) => {
    const words = text.split(' ');

    if (words.some(word => word.length > threshold)) {
      return <p title={created_date} className="text-left break-all hyphens-auto">{text}</p>;
    } else {
      return <p title={created_date} className="text-left break-words">{text}</p>;
    }
  };

  // const formatProjectDate = (dateString) => {
  //   const date = new Date(dateString);
  //   const options = { month: 'short', year: 'numeric' };
  //   const formattedDate = date.toLocaleDateString('en-US', options);
  //   const [month, year] = formattedDate.split(' ');
  //   return `${month}, ${year}`;
  // };

  const storeProject = (project) => {
    sessionStorage.setItem('projectCode', project.project_code);
    sessionStorage.setItem('projectName', project.encrypted_name);
  };

  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [msg, setMsg] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);

  function closePopup() {
      setIsOpenPopup(false);
  }

  const openPopup = useCallback(() => {
      setIsOpenPopup(true);
  }, [setIsOpenPopup]);

  useLayoutEffect(()=>{
    const fetchUsersProjectList = async () => {
      GetUserAssignedActiveProjects()
        .then((response) => {
          if(response.status==='success' && response.data!==null && response.data.length>0){
            // setProjects(response.data);
            const modifiedProjects = response.data.map(project => ({
              ...project,
              encrypted_name: encryptData(project.project_name)
            }));
            setProjects(modifiedProjects);
          }
        })
        .catch((error) => {
          setMsg(error);
          setIsSuccess(false);
          openPopup();
        })
        .finally(()=>{
          setLoaders(false);
        });
    };

    fetchUsersProjectList();
  },[openPopup])

  return (
    <>
    <SidebarPanel panelheader="Projects"/>
    <div className='p-3'>
      <Popup isOpen={isOpenPopup} msg={msg} closeModal={closePopup} isSuccess={isSuccess}/>
      {
      <>
      {!loaders && projects.length===0 && <p className='text-lg tracking-wider px-1'>No Projects assigned to You</p>}
      {!loaders && projects.length>0 &&
      <div className='grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6'>
      {projects && projects?.map((project, index) => {
        return (
          <Link
            to={`/chatbot/${btoa(project.project_code)}`}
            key={index}
            onClick={() => storeProject(project)} // Storing the project code in sessionStorage when the link is clicked
            className={`relative bg-sky-900 font-bold cursor-default lg:cursor-pointer flex flex-row tracking-widest space-x-1 items-center justify-start text-white text-sm py-6 px-3`}
          >
            <img width="40" height="40" src="https://img.icons8.com/fluency/48/000000/background-remover.png" alt="chatpdf"/>
            {BreakText(project.project_name,project.created_date)}
            {/* <p className='rounded-full py-1 px-2 bg-white text-sky-900 text-xs' title={project.created_date}>{formatProjectDate(project.created_date)}</p> */}
          </Link>
        )
      })}
      </div>
      }
      </>
      }
    </div>
    </>
  )
}

export default Projects