import React, { useState } from 'react'
import VirtualizedTable from '../../../Common/VirtualizedTable';
import { ArrowPathIcon, EyeIcon, PencilIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import InputModal from '../../../Common/InputModal';
import ConfirmationPopup from '../../../Common/ConfirmationPopup';
import Popup from '../../../Common/Popup';
import { deletePrompt, savePrompt, updatePrompt, updatePromptStatus } from '../../../Services/LLMOpsServices';
import ReadModal from '../../../Common/ReadModal';
import { formattedDate } from '../../../Common/CommonUtils';
import InputModalsm from '../../../Common/InputModalsm';
import { REACT_APP_SHOW_EXP_ENV } from '../../../../http';

function ExplorePrompts({promptList, options, refreshPromptsList, promptListLoader, setPromptList}) {

  // Saving a new prompt via prompts engineering
  const [prompt, setPrompt] = useState("");
  const [editState, setEditState] = useState("");
  let [promptID, setpromptID] = useState(0); 
  const [rowData, setRowData] = useState([]);

  // Handling Error/Success messages for the API Response such as saved, error occured, etc
  let [msg, setMsg] = useState('');
  let [msgID, setMsgID] = useState('');
  let [isSuccess, setIsSuccess] = useState(false);
  let [isOpen, setIsOpen] = useState(false);

  function closeMessage() {
    setIsOpen(false);
  }

  function openMessage() {
    setIsOpen(true);
    setTimeout(() => {
      setIsOpen(false);
    }, 3000);
  }

  let [isOpenModal, setIsOpenModal] = useState(false);

  function closeModal() {
    setIsOpenModal(false);
    setPrompt("");
  }

  function openModal(option, prompt_data) {
    if(option==="create"){
      setEditState(false);
      setpromptID(0);
      setRowData([]);
    }
    else{
      setEditState(true);
      setpromptID(prompt_data.id);
      setRowData(prompt_data);
    }
    setIsOpenModal(true);
  }

  let [isOpenReadModal, setIsOpenReadModal] = useState(false);

  function closeReadModal() {
    setIsOpenReadModal(false);
    setPrompt("");
  }

  function openReadModal(prompt) {
    setPrompt(prompt);
    setIsOpenReadModal(true);
  }

  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [popupmsg, setPopupMsg] = useState(false);
  let [isSuccessPopup, setIsSuccessPopup] = useState(false);

  function closePopup() {
    setIsOpenPopup(false);
  }

  function openPopup() {
    setIsOpenPopup(true);
  }

  let [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
  let [modalTitle, setModalTitle] = useState('');

  function closeStatusModal() {
    setIsOpenStatusModal(false);
  }

  function openStatusModal(e) {
    setRowData(e);
    setModalTitle('Update Prompt Status')
    setIsOpenStatusModal(true);
  }

  const handleStatusState = async() => {
    let statusData = { status: rowData.status === false || rowData.status === 'false' ? false : true };
    updatePromptStatus({"prompt_id":rowData.id,"status":statusData.status})
      .then(response => {
        if (response.status==='success') {
          closeStatusModal();
          setPopupMsg(response.message);
          setIsSuccessPopup(true);
          openPopup();
        } else {
          closeStatusModal();
          setPopupMsg(response.message);
          setIsSuccessPopup(false);
          openPopup();
        }
      })
      .finally(()=>{
        refreshPromptsList();
      });
  };

  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState("");

  function closeConfirmPopup() {
    setIsOpenConfirmPopup(false);
  }

  function openConfirmPopup(id_prop) {
    setpromptID(id_prop);
    setConfirmationMsg("Are you sure that you want to delete this prompt?");
    setIsOpenConfirmPopup(true);
  }

  const handleSavePrompt = () => {
    savePrompt({"catg":rowData.catg,"prompt":rowData.prompt,"client_code":rowData.client_code,"project_code":rowData.project_code})
      .then(response => {
        if (response.status==='success') {
          setMsg(response.message);
          setMsgID('saveprompt');
          setIsSuccess(true);
          closeModal();
          refreshPromptsList();
        } else {
          setMsg(response.message);
          setMsgID('saveprompt');
          setIsSuccess(false);
        }
        openMessage();
      })
  };

  const handleEditPrompt = () => {
    updatePrompt({"prompt_id":promptID,"catg":rowData.catg,"prompt":rowData.prompt,"client_code":rowData.client_code,"project_code":rowData.project_code})
      .then(response => {
        if (response.status==='success') {
          setMsg(response.message);
          setMsgID('saveprompt');
          setIsSuccess(true);
          closeModal();
          refreshPromptsList();
        } else {
          setMsg(response.message);
          setMsgID('saveprompt');
          setIsSuccess(false);
        }
        openMessage();
      })
  };

  const deleteFn = async() => {
    setIsOpenConfirmPopup(false);
    const response = await deletePrompt(promptID);
    if (response.status === 'success') {
      setPromptList((prevData) => {
        const newData = prevData.filter(
            (item) => item['id'] !== promptID
        );
        return newData;
      });
      setPopupMsg(response.message);
      setIsSuccessPopup(true);
      openPopup();
    } 
    else {
      setPopupMsg(response.message);
      setIsSuccessPopup(false);
      openPopup();
    }
  }

  const DeleteButton = ({ prompt_id }) => {  
    return (
        <button
            className="p-1 rounded-md border border-gray-300 text-red-700 disabled:text-gray-300"
            onClick={()=>openConfirmPopup(prompt_id)}
        >
                <TrashIcon height="1em" title='Delete Prompt' />
        </button>
    );
  };

  const EditButton = ({ prompt_data }) => {  
    return (
        <button
            className="p-1 rounded-md border border-gray-300 text-sky-700 disabled:text-gray-300"
            disabled={!prompt_data.status}
            onClick={()=>openModal("edit",prompt_data)}
        >
                <PencilIcon height="1em" title='Edit Prompt' />
        </button>
    );
  };

  const ViewButton = ({ prompt }) => {  
    return (
        <button
            className="p-1 rounded-md border border-gray-300 text-sky-700 disabled:text-gray-300"
            onClick={()=>openReadModal(prompt)}
        >
                <EyeIcon height="1em" title='View Prompt' />
        </button>
    );
  };

  const columns = React.useMemo(
    () => [
        {
            accessorKey: "index",
            enableColumnFilter: false,
            enableSorting: false,
            size: 50,
            header: "S.No."
        },
        {
          accessorKey: "client_name",
          enableColumnFilter: true,
          size: 100,
          header: () => <span>Client</span>,        
          cell: ({ row }) => (
            <div>
                {row.original.client_name && row.original.client_name}{!row.original.client_name && 'NA'}
            </div>
        ),
        },
        {
          accessorKey: "project_name",
          enableColumnFilter: true,
          size: 100,
          header: () => <span>Project</span>,
          cell: ({ row }) => (
            <div>
                {row.original.project_name && row.original.project_name}{!row.original.project_name && 'NA'}
            </div>
        ),
        },        
        {
          accessorKey: "catg",
          enableColumnFilter: true,
          size: 50,
          header: () => <span>Category</span>
        },
        {
          accessorKey: "prompt",
          enableColumnFilter: false,
          size: 1000,
          header: () => <span>Prompt</span>,
          cell: ({ row }) => (
            <div>
                {row.original.id} - {row.original.prompt}
            </div>
          ),
        },
        {
          accessorKey: "created_date",
          enableColumnFilter: false,
          size: 50,
          header: () => <span>Created Date</span>,
          cell: ({ row }) => (
              <div>
                  {row.original.created_date && row.original.created_date !== "" &&
                      formattedDate(row.original.created_date)}
              </div>
          ),
        },
        {
          accessorKey: "modify_date",
          enableColumnFilter: false,
          size: 50,
          header: () => <span>Modified Date</span>,
          cell: ({ row }) => (
              <div>
                  {row.original.modify_date && row.original.modify_date !== "" &&
                      formattedDate(row.original.modify_date)}
              </div>
          ),
        },
        {
          accessorKey: "status",
          enableColumnFilter: true,
          enableSorting:false,
          size: 50,
          header: () => <span>Status</span>,
          cell:({ row }) => (
            <div className='flex justify-center'>
            <div  onClick={()=>openStatusModal(row.original)}
                className={`${row.original.status === true ? 'bg-gray-100 text-green-900' : 'bg-gray-100 text-red-900'} text-xs w-fit px-2 py-1 font-bold rounded-md cursor-default lg:cursor-pointer`}
            >
                {row.original.status === true && "Active"}
                {row.original.status === false && "Inactive"}
            </div>
            </div>      
          )
        },
        {
          accessorKey: "id",
          enableColumnFilter: false,
          enableSorting: false,
          size: 100,
          header: () => <center>Actions</center>,
          cell: ({ row }) => (
            <div className="flex gap-2 items-center justify-center">
                <ViewButton prompt={row.original.prompt}/>
                <EditButton prompt_data={row.original}/>
                <DeleteButton prompt_id={row.original.id}/>
            </div>
        ),
        }
    ],
    []
  );

  const additionalButtons = (
    <div className='flex gap-2'>
        <span className="group relative">
          <div className="absolute right-7 top-1/2 z-20 mr-0 -translate-y-1/2 whitespace-nowrap rounded-[5px] py-1.5 px-3.5 text-sm text-white opacity-0 group-hover:opacity-100">
            <div className="bottom-full right-0 rounded-md bg-gray-400 px-4 py-2.5 text-xs text-white whitespace-nowrap">
              Add New Prompt
            </div>
          </div>
          <button className="inline-flex items-center rounded-md p-2 text-gray-700 ring-1 ring-inset ring-gray-300" onClick={()=>openModal("create",null)}>
            <PlusCircleIcon className="h-5 w-auto"/>
          </button>
      </span>
      <button className="inline-flex items-center rounded-md p-2 text-gray-700 ring-1 ring-inset ring-gray-300" onClick={refreshPromptsList}><ArrowPathIcon className="h-5 w-auto"/></button> 
    </div>
  );

  return (
    <div className='m-2'>
      <Popup
        isOpen={isOpenPopup}
        msg={popupmsg}
        closeModal={closePopup}
        isSuccess={isSuccessPopup}
      />
      <ConfirmationPopup
          isOpenConfirmPopup={isOpenConfirmPopup}
          confirmationMsg={confirmationMsg}
          closeConfirmPopup={closeConfirmPopup}
          onStateChange={deleteFn}
      />
      <ReadModal
        isOpen={isOpenReadModal}
        closeModal={closeReadModal}
        modalInputs={
                  <p className={`block w-full text-sm rounded-md border p-3 leading-6 h-[calc(100vh-10rem)] xl:h-[calc(100vh-23.5rem)] overflow-y-auto chatboxscroll`}>
                  {prompt}
                  </p>
        }
        title="View Prompt"
      />
      <InputModal
                isOpen={isOpenModal}
                closeModal={closeModal}
                title={editState?"Edit Prompt":"New Prompt"}
                saveName="Save Prompt"
                savefn={editState?handleEditPrompt:handleSavePrompt}
                modalInputs={
                <div className="w-full bg-white space-y-3">
                  <div className='flex gap-2'>
                    <div className='w-1/6'>
                  <label htmlFor="name" className="block text-sm font-bold leading-6 text-gray-500 pt-2 ps-1">
                    Category
                  </label>
                  <select
                    id="client"
                    name="client"
                    className="block w-auto md:w-full rounded-md border-0 py-2 mt-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs lg:max-w-full sm:text-sm sm:leading-6"
                    value={rowData.catg} 
                    onChange={(e) => setRowData({...rowData, catg: e.target.value})}
                  >
                    <option value="">Select Category</option>
                    <option value="CLIENT">Client</option>
                    <option value="PROJECT">Project</option>
                    {REACT_APP_SHOW_EXP_ENV !== "production" && <option value="EXP">Experimental</option>}
                    <option value="INDUSTRY">Industry</option>
                  </select>
                  </div>
                  {rowData.catg && rowData.catg!=="INDUSTRY" &&
                  <>
                  <div className='w-2/6'>
                  <label htmlFor="name" className="block text-sm font-bold leading-6 text-gray-500 pt-2 ps-1">
                    Client Name
                  </label>
                  <select
                    id="client"
                    name="client"
                    className="block w-auto md:w-full rounded-md border-0 py-2 mt-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs lg:max-w-full sm:text-sm sm:leading-6"
                    value={rowData.client_code} 
                    onChange={(e) => setRowData({...rowData, client_code: e.target.value})}
                  >
                    <option value="">Select Client</option>
                    {options && [...new Set(options.map(item => item.client_code))].map((clientCode, index) => {
                        const clientName = options.find(item => item.client_code === clientCode).client_name;
                        return (
                            <option key={index} value={clientCode}>
                                {clientName}
                            </option>
                        );
                    })}
                  </select>
                  </div>
                  {rowData.catg && rowData.catg!=="CLIENT" && 
                  <div className='w-3/6'>
                  <label htmlFor="projectName" className="block text-sm font-bold leading-6 text-gray-500 pt-2 ps-1">
                    Project Name
                  </label>
                  <select
                    id="projectName"
                    name="projectName"
                    className="block w-auto md:w-full rounded-md border-0 py-2 mt-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs lg:max-w-full sm:text-sm sm:leading-6"
                    value={rowData.project_code} 
                    onChange={(e) => setRowData({...rowData, project_code: e.target.value})}
                  >
                    <option value="">Select Project</option>
                    {options?.filter((client) => client.client_code === rowData.client_code).map((item, index) => (
                      <option key={index} value={item.project_code}>
                        {item.project_name}
                      </option>
                    ))}
                  </select>
                  </div>
                  }
                  </>
                  }
                  </div>
                  <textarea
                    autoFocus
                    placeholder=" Write your prompt here..."
                    required
                    id='promptTextarea'
                    value={rowData.prompt}
                    className={`block w-full text-sm rounded-md border-0 ring-1 ring-gray-200 focus:ring-1 focus:ring-gray-200 p-3 h-[calc(100vh-18rem)] xl:h-[calc(100vh-35rem)] resize-none max-h-[calc(100vh-18rem)] xl:max-h-[calc(100vh-35rem)] chatboxscroll`}
                    onChange={(e) => setRowData({...rowData, prompt: e.target.value})}
                  />
                  <div className="flex flex-col md:flex-row justify-start items-center">
                    {isOpen && msg && msgID==='saveprompt' && !isSuccess && <p onClick={closeMessage} className={`${isSuccess?'bg-white text-green-900':'bg-white text-red-900'} rounded-md font-medium text-sm px-1 pt-3 md:pt-1.5`}>{msg}</p>}  
                  </div>
                </div>
                }
            />
      
    <InputModalsm isOpen={isOpenStatusModal} closeModal={closeStatusModal} title={modalTitle} 
        saveName="Save" 
        savefn={handleStatusState}
        modalInputs={
          <div className='flex gap-5'>
          <div className="flex items-center gap-x-2">
                  <input
                    id="status"
                    name="status"
                    type="radio"
                    value="true"
                    checked={rowData.status === true || rowData.status === "true"}
                    onChange={(e) =>
                      setRowData({
                        ...rowData,
                        status: e.target.value,
                      })
                    }
                    autoFocus={false}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
                  />
                  <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                    Active
                  </label>
                </div>
                <div className="flex items-center gap-x-2">
                  <input
                    id="status"
                    name="status"
                    type="radio"
                    value="false"
                    checked={rowData.status === false || rowData.status === "false"}
                    onChange={(e) =>
                      setRowData({
                        ...rowData,
                        status: e.target.value,
                      })
                    }
                    autoFocus={false}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
                  />
                  <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900">
                    Inactive
                  </label>
                </div>
          </div>
        } 
      />
    <div className='border rounded-md border-gray-300 bg-white'>
      <VirtualizedTable hcss='max-h-[calc(100vh-19.2rem)] md:max-h-[calc(100vh-16.6rem)]' data={promptList || []} columns={columns} loader={promptListLoader} loadermsg="Fetching Prompts" noDataFoundmsg="No Prompts Found" allowSelect={false} additionalButtons={additionalButtons}/>
    </div>
    </div>
  )
}

export default ExplorePrompts