import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Layout from './components/Layout/Layout';
import Login from './components/Auth/Login';
import PageNotFound from './components/Pages/PageNotFound';
import ProtectedRoutes from './ProtectedRoutes';
import Projects from './components/Pages/Projects';
import Chatbot from './components/Pages/Chatbot';
import RIAOps from './components/Pages/RIAOps';
import AdminDashboard from './components/Pages/Admin/AdminDashboard';
import ProjectManagement from './components/Pages/Admin/ProjectManagement';
import UserManagement from './components/Pages/Admin/UserManagement';
import ClientManagement from './components/Pages/Admin/ClientManagement';
import ProjectAssignment from './components/Pages/Admin/ProjectAssignment';
import Profile from './components/Auth/Profile';

// const menuItems = [
//   {
//     name: 'Dashboard',
//     component: <Dashboard />,
//     icon: SwatchIcon,
//     to: '/dashboard',
//   },
//   {
//     name: 'RIA Chatbot',
//     component: <Chatbot/>,
//     icon: ChatBubbleLeftRightIcon,
//     to: `/chatbot`,
//   }
// ];

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      {/* <Route path="/" element={<App />} /> */}
      <Route path="/" element={<Layout component={<Login/>}/>} />
      <Route path='/login' element={<Layout component={<Login/>}/>} />
      {/*These routes are nested under ProtectedRoutes to enhance access only if it has logged in*/}
      <Route exact path="/" element={<ProtectedRoutes />}>
        <Route path='/admin' element={<Layout component={<AdminDashboard/>}/>} />
        <Route path='/admin/projects' element={<Layout component={<ProjectManagement/>}/>} />
        <Route path='/admin/assignprojects' element={<Layout component={<ProjectAssignment/>}/>} />
        <Route path='/admin/users' element={<Layout component={<UserManagement/>}/>} />
        <Route path='/admin/clients' element={<Layout component={<ClientManagement/>}/>} />
        <Route path='/projects' element={<Layout component={<Projects/>} resetLoading={true}/>} />
        <Route path='/profile' element={<Layout component={<Profile/>} resetLoading={true}/>} />
        <Route path='/riaops' element={<Layout component={<RIAOps/>}/>}/>
        <Route path='/chatbot/:projectCode' element={<Layout component={<Chatbot/>}/>}/>
        <Route path='*' element={<Layout component={<PageNotFound/>}/>} />
      </Route>
    </Routes>
  </Router>
);
reportWebVitals();
