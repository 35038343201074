import React from 'react'
import { Link } from 'react-router-dom'
import SidebarPanel from '../../Common/SidebarPanel';

function AdminDashboard() {
  
  const items = [
    {
      imgSrc: '/icons8-exchange-48.png',
      alt: 'conference-background-selected',
      text: 'Client Management',
      to: '/admin/clients'
    },
    {
      imgSrc: '/documents-folder--v1.png',
      alt: 'documents-folder--v1',
      text: 'Project Management',
      to: '/admin/projects'
    },    
    {
      imgSrc: '/conference-background-selected.png',
      alt: 'conference-background-selected',
      text: 'User Management',
      to: '/admin/users'
    },
    {
      imgSrc: '/icons8-user-folder-48.png',
      alt: 'documents-folder--v1',
      text: 'Project Assignment',
      to: '/admin/assignprojects'
    }
  ];

  if (localStorage.getItem('role') === "PMA") {
    items.splice(0, 1); // Remove the first item (index 0)
  }

  return (
    <>
    <SidebarPanel panelheader="Dashboard"/>
    <div className='p-3'>
    <div className='grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6'>
      {items.map((item, index) => (
        <Link
          to={item.to}
          key={index}
          className='bg-sky-900 cursor-default lg:cursor-pointer flex flex-col tracking-wider space-y-4 items-center rounded-md py-20 p-5'
        >
          <img width="40" height="40" src={item.imgSrc} alt={item.alt} />
          <p className='text-white'>{item.text}</p>
        </Link>
      ))}
    </div>
    </div>
    </>
  )
}

export default AdminDashboard