import { MicrophoneIcon, PaperAirplaneIcon, PauseCircleIcon } from "@heroicons/react/24/outline";
import { StopCircleIcon } from "@heroicons/react/24/solid";
import React from "react";

function MessageEditor({message,setMessage,inputRef,loader,handleSendMessage,handleEnterKey,isListening,toggleListening}) {
  return (
    <>
      <textarea
        id="queryTextarea"
        placeholder="Ask Anything..."
        value={message}
        autoFocus
        ref={inputRef}
        rows={1}
        className="block w-full rounded-md border border-gray-100 py-2.5 pl-3 pr-8 ring-0 placeholder:text-gray-400 focus:ring-0 focus:border-gray-100 leading-6 bg-white text-sm resize-none max-h-[30vh] xl:max-h-[13.5vh] chatboxscroll"
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => (!loader ? handleEnterKey(e) : null)}
      />
      <div className="absolute inset-y-0 right-1.5 flex items-end bottom-1.5">
        <button
          onClick={handleSendMessage}
          disabled={loader}
          className="p-1.5 pl-2 absolute right-0.5 border rounded-md bg-gray-50 cursor-default lg:cursor-pointer"
        >
          {!loader && <PaperAirplaneIcon className="h-5 w-5 -rotate-[35deg]" />}
          {loader && <StopCircleIcon className="h-5 w-5 text-gray-400" />}
        </button>

        {isListening ? (
          <button
            onClick={toggleListening}
            className="absolute py-1.5 px-2 right-11 border rounded-md bg-gray-50 cursor-default lg:cursor-pointer"
          >
            <PauseCircleIcon className="h-5 w-5" />
          </button>
        ) : (
          <button
            onClick={toggleListening}
            className="absolute py-1.5 px-2 right-11 border rounded-md bg-gray-50 cursor-default lg:cursor-pointer"
          >
            <MicrophoneIcon className="h-5 w-5" />
          </button>
        )}
      </div>
    </>
  );
}

export default MessageEditor;
