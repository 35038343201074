import React, { useEffect, useState } from "react";
import { getPromptList } from "../../Services/LLMOpsServices";
import Experimentation from "./RIAOpsComponents/Experimentation";
import DeployPrompt from "./RIAOpsComponents/DeployPrompt";
import SidebarPanel from "../../Common/SidebarPanel";
import ExplorePrompts from "./RIAOpsComponents/ExplorePrompts";
import { ActiveProjectswithClientName } from "../../Services/ProjectServices";
import { BeakerIcon } from "@heroicons/react/24/solid";
import { CircleStackIcon, RocketLaunchIcon } from "@heroicons/react/20/solid";
import { handleApiError } from "../../Common/APIUtils";
import Popup from "../../Common/Popup";
import { REACT_APP_SHOW_EXP_ENV } from "../../../http";

function RIAOps() {

  const [tabState, setTabState] = useState(1);

  // Storing prompts lists for prompts database
  const [promptList, setPromptList] = useState([]);
  const [promptListLoader, setPromptListLoader] = useState(false);

  // Handling Selections for User Query Prompt
  const [selectedprompt, setSelectedPrompt] = useState([]);
  const [expselectedData, setExpSelectedData] = useState([]);
  const [exprowData, setexpRowData]=useState([]);

  const [deployselectedData, setDeploySelectedData] = useState([]);

  const [options, setOptions]=useState([]);

  const refreshPromptsList = async () => {
    setPromptListLoader(true);
    getPromptList()
      .then((response) => {
        setPromptList(response.data);
        setTimeout(() => {
          setPromptListLoader(false);
        }, 500);
      })
      .catch((error) => {          
        const errorMessage = handleApiError(error);
        setMsg(errorMessage);
        setIsSuccess(false);
        openPopup();
      });
  };

  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [msg, setMsg] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);

  function closePopup() {
    setIsOpenPopup(false);
  }

  function openPopup() {
    setIsOpenPopup(true);
  }

  useEffect(()=>{
    const fetchPrompts = async () => {
      getPromptList()
        .then((response) => {
          setPromptList(response.data);
        })
        .catch((error) => {
          const errorMessage = handleApiError(error);
          setMsg(errorMessage);
          setIsSuccess(false);
          openPopup();
        });
    };
    const fetchActiveProjectsWithClients = async () => {
      ActiveProjectswithClientName()
        .then((response) => {
          if(response.status==='success' && response.data!==null){
            setOptions(response.data);
          }
        })
        .catch((error) => {
          const errorMessage = handleApiError(error);
          setMsg(errorMessage);
          setIsSuccess(false);
          openPopup();
        });
    };

    fetchPrompts();
    fetchActiveProjectsWithClients();
  },[])


  const tabs = [
    {
      id: 1,
      title: "Prompts",
      content: (
          <ExplorePrompts promptList={promptList} options={options} refreshPromptsList={refreshPromptsList} promptListLoader={promptListLoader} setPromptList={setPromptList}/>
      ),
    },
    REACT_APP_SHOW_EXP_ENV !== "production" && {
        id: 2,
        title: "Experiment",
        content: (
          <Experimentation promptList={promptList?.filter(prompt => prompt.catg==="EXP" && prompt.status===true)} options={options} refreshPromptsList={refreshPromptsList} refreshloader={promptListLoader} loadermsg="Fetching Prompts" selectedprompt={selectedprompt} setSelectedPrompt={setSelectedPrompt} rowData={exprowData} setRowData={setexpRowData} selectedData={expselectedData} setSelectedData={setExpSelectedData}/>
        ),
    },
    {
      id: 3,
      title: "Deploy",
      content: (
        <DeployPrompt promptList={promptList?.filter(prompt => (prompt.catg==="CLIENT" || prompt.catg==="PROJECT") && prompt.status===true)} globalPrompts={promptList?.filter(prompt => prompt.catg==="INDUSTRY" && prompt.status===true)} options={options} selectedData={deployselectedData} setSelectedData={setDeploySelectedData}/>
      ),
    }
  ].filter(Boolean);

  // Function to get the content for the selected tab
const getTabContent = () => {
  const selectedTab = tabs.find(tab => tab.id === tabState);
  return selectedTab ? selectedTab.content : null;
};

  return (
    <>
    <Popup
      isOpen={isOpenPopup}
      msg={msg}
      closeModal={closePopup}
      isSuccess={isSuccess}
    />
    <SidebarPanel panelheader="RIA Prompt Management" additionalButtons={<><button className={`inline-flex items-center px-2 py-1 text-base tracking-wider font-medium ${tabState===1 && 'text-sky-700'}`} onClick={()=>setTabState(1)}><CircleStackIcon className="h-4 w-auto"/>&nbsp;Prompts</button>{REACT_APP_SHOW_EXP_ENV !== "production" && <button className={`inline-flex items-center px-2 py-1 text-base tracking-wider font-medium ${tabState===2 && 'text-sky-700'}`} onClick={()=>setTabState(2)}><BeakerIcon className="h-4 w-auto"/>&nbsp;Experiment</button>}<button className={`inline-flex items-center px-2 py-1 text-base tracking-wider font-medium ${tabState===3 && 'text-sky-700'}`} onClick={()=>setTabState(3)}><RocketLaunchIcon className="h-4 w-auto"/>&nbsp;Deploy</button></>}
      panelcontent={getTabContent()}
    />
    </>
  );
}

export default RIAOps;