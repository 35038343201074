import React, { useState } from 'react'
import { extractData } from '../Services/ExtractionServices';
import Popup from './Popup';
import { sources } from './Constants';

function CommentList({ comments }) {
    return (
        <ul className='w-full list-inside prose-sm list-disc p-2 pt-0 text-sm text-justify'>
            {comments.map((comment, index) => (
              <>
                <div key={index}>{comment.author} ({comment.published_at}) {comment.likes} likes <br/>{comment?.text}</div>
                {comment?.replied_comments && 
                <>
                <div className='px-4'>
                Replies:<br/>
                  {comment?.replied_comments?.map((reply,index)=>(
                    <>
                      <div key={index}>{reply.author} ({reply.published_at}) {reply.likes} likes <br/>{reply?.text}</div>
                    </>
                  ))}
                </div>
                </>
                }
              </>
            ))}
        </ul>
    );
}

function TranscriptionList({ comments }) {
  return (
    <div>
      <table className="table-auto w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase">
          <tr>
            <th scope="col" className="py-3 px-6 border">
              text
            </th>
            <th scope="col" className="py-3 px-6 border">
              Start
            </th>
            <th scope="col" className="py-3 px-6 border">
              Duration
            </th>
          </tr>
        </thead>
        <tbody>
          {comments.map((value, index) => {
            return (
              <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="py-3 px-6 border">{value.text}</td>
                <td className="py-3 px-6 border">{value.start}</td>
                <td className="py-3 px-6 border">{value.duration}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
 
function ExtractDocuments({refetchFiles}) {
    const [formData, setFormData] = useState([]);
    const [show, setShow] = useState(false);
    const [loader, setLoader] =useState(false);
    const [result, setResult] = useState([]);
    const [extractedMetadata, setExtractedMetadata] = useState();
    const [commentState, setCommentState] = useState(false);
    const [resultTitle, setResultTitle] = useState('');

    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);
  
    function closePopup() {
      setIsOpenPopup(false);
    }
  
    function openPopup() {
      setIsOpenPopup(true);
    }

    const handleDataExtraction = () => {
        setLoader(true);
        if(formData.subtype==="comments"){
            setCommentState(true);
        }
        else{
            setCommentState(false);
        }
        extractData({"source":formData.source,"subtype":formData.subtype,"inputdata":formData.inputdata,"project_code":sessionStorage.getItem("projectCode")})
          .then(response => {
            if (response.status==='success') {
                setResult(response.data);
                setExtractedMetadata(response.metadata);
                setResultTitle(`${capitalizeFirstLetter(formData.source)} ${capitalizeFirstLetter(formData.subtype)} on ${response?.metadata?.title}`)
                setFormData({...formData, inputdata: ""})
                setLoader(false);
                setShow(true);
                refetchFiles();
            } else {
                setLoader(false);
                setShow(false);
                setMsg(response.message);
                setIsSuccess(false);
                openPopup();
            }
          });
      };

  return (
    <div className="w-full bg-white border h-full md:h-[calc(100vh-9.8rem)] rounded-md border-gray-300">
            <Popup
      isOpen={isOpenPopup}
      msg={msg}
      closeModal={closePopup}
      isSuccess={isSuccess}
    />
                <div className='flex flex-col md:flex-row gap-2 px-3 pb-3 border border-gray-300 bg-gray-50 rounded-t-md'>
                    <div className='w-full md:w-1/2 lg:w-1/6'>
                  <label htmlFor="source" className="block text-sm font-bold leading-6 text-gray-500 pt-2 ps-1">
                    Source
                  </label>
                  <select
                    id="source"
                    name="source"
                    disabled={loader}
                    value={formData.source} 
                    onChange={(e) => setFormData({...formData, source: e.target.value})}
                    className="block w-full rounded-md border-0 py-2 mt-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:max-w-xs lg:max-w-full sm:text-sm sm:leading-6"
                  >
                    <option value="">Select Source</option>
                    {sources.map((source, index)=> <option  key={index} value={source.value}>{source.name}</option>)}
                  </select>
                  </div>
                  <div className='w-full md:w-1/2 lg:w-1/6'>
                  <label htmlFor="subtype" className="block text-sm font-bold leading-6 text-gray-500 pt-2 ps-1">
                    Sub-Type
                  </label>
                  <select
                    id="subtype"
                    name="subtype"
                    disabled={loader}
                    value={formData.subtype} 
                    onChange={(e) => setFormData({...formData, subtype: e.target.value})}
                    className="block w-full rounded-md border-0 py-2 mt-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:max-w-xs lg:max-w-full sm:text-sm sm:leading-6"
                  >
                    <option value="">Select Sub-type</option>
                    {formData.source && sources.find(s => s.value === formData.source)?.subtypes.map((subtype, index) => (
                        <option key={index} value={subtype.value}>{subtype.name}</option>
                    ))}
                  </select>
                  </div>
                  <div className='w-full lg:w-4/6'>
                  <label htmlFor="inputdata" className="block text-sm font-bold leading-6 text-gray-500 pt-2 ps-1">
                    Source ID / Link
                  </label>
                  <input type='text' disabled={loader} value={formData.inputdata} onChange={(e) => setFormData({...formData, inputdata: e.target.value})} className="block w-full rounded-md border-0 py-2 mt-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:max-w-xs lg:max-w-full sm:text-sm sm:leading-6"/>
                  </div>
                  <div className='flex mt-2 md:mt-0 gap-2 items-end'>
                  <button
          type="button"
          disabled={loader}
          onClick={handleDataExtraction}
          className="flex w-fit h-fit justify-center rounded-lg bg-sky-100 px-4 py-2.5 text-sm font-medium text-sky-900 hover:bg-sky-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-sky-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer tracking-wider"
        >
          Extract
        </button>
        <button
          type="button"
          disabled={loader}
          className="flex w-fit h-fit justify-center rounded-lg bg-red-100 px-4 py-2.5 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer tracking-wider"
        >
          Cancel
        </button>
        </div>
                </div>
        {!show && !loader && <div className='flex  h-[calc(100vh-20rem)] items-center bg-white justify-center text-base'>Provide Inputs for Extracting Documents</div>}
        {/* {!loader && show && result && <div className='flex h-[calc(100vh-19rem)] overflow-y-auto text-sm p-2 text-justify'>{commentState?<CommentList comments={result}/>:result}</div>} */}
        {!loader && show && result && <div className='flex flex-col h-[calc(100vh-15.5rem)] text-sm text-justify pb-2 pt-0 overflow-y-auto'><div className='px-2'><p className='pt-2'>{resultTitle}</p><p>Publication - {extractedMetadata.publisher_name}</p><p>Headline - {extractedMetadata.title}</p><p>Date - {extractedMetadata.published_date}</p><p>Link - <a className='text-sky-900' href={extractedMetadata.link} target="_blank" without rel="noreferrer">{extractedMetadata.link}</a></p>{commentState?<>Total Comments & Replies - {extractedMetadata.total_comments_and_replies_count}<br/>Comments:</>:'Transcript:'}</div>{commentState?<CommentList comments={result}/>:<TranscriptionList comments={result}/>}</div>}
        {loader && <div className="p-2.5 text-base h-[calc(100vh-20rem)] flex items-center justify-center animate-pulse">
      <svg
        className="animate-spin mr-1.5 h-4 w-4 text-sky-700"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx={12}
          cy={12}
          r={10}
          stroke="currentColor"
          strokeWidth={4}
        />
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
      Extracting Data...
    </div>}
    </div>
  )
}

export default ExtractDocuments