import axios from "axios";
import { API_URL } from "../../http";
import { handleApiError } from "../Common/APIUtils";

// Function to fetch the deployed chatbot prompt with the user's old chat for RIA Chatbot
export const getDeployedChatbotPromptWithChat = async () => {
  try {
    const response = await axios.get(`${API_URL}/chatbot/latest`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      params: {
        project_code: sessionStorage.getItem("projectCode") || null
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// Function to pass the query & prompt and get the ai response
export const getAIResponse = async (postData) => {
    try {
      const response = await axios.post(`${API_URL}/chatbot/chat`, postData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return response.data;
    } catch (error) {
      const errMsg = handleApiError(error);
      return {'status': 'error', 'message': errMsg};
    }
};

// Function to fetch the chat for the particular query_id (chat_id) and limit Value
export const getChat = async (queryID, limitValue) => {
  try {
    const response = await axios.get(`${API_URL}/chatbot/chat_history/${queryID}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      params: {
        limitValue: limitValue || null
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// Function to fetch the chat titles and their chat ids
export const getChatsList = async () => {
  try {
    const response = await axios.get(`${API_URL}/chatbot/chats`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      params: {
        project_code: sessionStorage.getItem("projectCode") || null
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// Function to rename the chat title
export const updateChatTitle = async (patchData) => {
  try {
    const response = await axios.patch(`${API_URL}/chatbot/chats`, patchData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// Function to delete the particular chat
export const deleteChat = async (queryID) => {
  try {
    const response = await axios.delete(`${API_URL}/chatbot/chats`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      params: {
        queryID: queryID || null
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// Function to pass the query & prompt and get the ai response
export const submitFeedback = async (postData) => {
  try {
    const response = await axios.post(`${API_URL}/feedback`, postData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};