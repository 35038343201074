import React, { useEffect, useState } from 'react'
import SidebarPanel from '../../Common/SidebarPanel'
import VirtualizedTable from '../../Common/VirtualizedTable'
import { ArrowPathIcon, EnvelopeIcon, LockClosedIcon, PencilIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import Popup from '../../Common/Popup';
import ConfirmationPopup from '../../Common/ConfirmationPopup';
import InputModalsm from '../../Common/InputModalsm';
import { formattedDate } from '../../Common/CommonUtils';
import { deleteUser, getRolesList, getusersList, saveUser, updateUser, updateUserStatus, userReset } from '../../Services/UserServices';
import { getActiveClientsList } from '../../Services/ClientServices';

function UserManagement() {
    let [users, setUsers]=useState([]);
    let [clients, setClients]=useState([]);
    let [roles, setRoles]=useState([]);
    let [rowData,setRowData]=useState([]);
    let [userID, setuserID] = useState("");
    let [loader, setLoader] = useState(true);

    let [isOpenModal, setIsOpenModal] = useState(false);
    let [editState, setEditState] = useState(false);

    function closeModal() {
      setIsOpenModal(false);
      setRowData([]);
    }
  
    function openModal(option, user_data) {
      if(option==="create"){
        setEditState(false);
        setRowData([]);
      }
      else{
        setEditState(true);
        setRowData(user_data);
      }
      setIsOpenModal(true);
    }

    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);
  
    function closePopup() {
      setIsOpenPopup(false);
    }
  
    function openPopup() {
      setIsOpenPopup(true);
    }

    let [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
    let [modalTitle, setModalTitle] = useState('');
  
    function closeStatusModal() {
      setIsOpenStatusModal(false);
    }
  
    function openStatusModal(e) {
      setRowData(e);
      setModalTitle('Update User Status')
      setIsOpenStatusModal(true);
    }
  
    const handleStatusState = async() => {
      let statusData = { status: rowData.status === false || rowData.status === 'false' ? false : true };
      updateUserStatus({"_id":rowData._id,"status":statusData.status})
        .then(response => {
          if (response.status==='success') {
            closeStatusModal();
            setMsg(response.message);
            setIsSuccess(true);
            openPopup();
          } else {
            closeStatusModal();
            setMsg(response.message);
            setIsSuccess(false);
            openPopup();
          }
        })
        .finally(()=>{
          refreshUsersList();
        });
    };

    const refreshUsersList = async () => {
        setLoader(true);
        getusersList().then((response) => {
            if(response.status==='success' && response.data!==null && response.data.length>0){
            setUsers(response.data);
            }
            else{
            setUsers([]);
            }
        })
        .catch((error) => {
            setUsers([]);
            setMsg(error);
            setIsSuccess(false);
            openPopup();
        })
        .finally(()=>{
            setLoader(false);
        });
      };
  
    const handleSaveUser = () => {
        saveUser({"user_name":rowData.user_name,"email":rowData.email,"role_id":rowData.role_id,"client_code":rowData.client_code})
        .then(response => {
          if (response.status==='success') {
            closeModal();
            setMsg(response.message);
            setIsSuccess(true);
            openPopup();
          } else {
            closeModal();
            setMsg(response.message);
            setIsSuccess(false);
            openPopup();
          }
        })
        .finally(()=>{
          refreshUsersList();
        });
    };
  
    const handleEditUser = () => {
      updateUser({"_id":rowData._id,"user_name":rowData.user_name,"role_id":rowData.role_id,"client_code":rowData.client_code})
        .then(response => {
          if (response.status==='success') {
            closeModal();
            setMsg(response.message);
            setIsSuccess(true);
            openPopup();
          } else {
            closeModal();
            setMsg(response.message);
            setIsSuccess(false);
            openPopup();
          }
        })
        .finally(()=>{
          refreshUsersList();
        });
    };

    const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
    const [confirmationMsg, setConfirmationMsg] = useState("");
    const [confirmAction, setConfirmAction] = useState("");
  
    function closeConfirmPopup() {
      setIsOpenConfirmPopup(false);
    }
  
    function openConfirmPopup(id_prop, action) {
      setuserID(id_prop);
      setConfirmAction(action);
      if (action==='delete'){setConfirmationMsg("Are you sure that you want to delete this user?")}
      else if (action==='mail'){setConfirmationMsg("Are you sure that you want to resend an onboarding email to this user?")}
      else {setConfirmationMsg("Are you sure that you want to reset this user's password?")}
      setIsOpenConfirmPopup(true);
    }
  
    const deleteFn = async() => {
      setIsOpenConfirmPopup(false);
      const response = await deleteUser(userID);
      if (response.status === 'success') {
        setUsers((prevData) => {
          const newData = prevData.filter(
              (item) => item['_id'] !== userID
          );
          return newData;
        });
        setMsg(response.message);
        setIsSuccess(true);
        openPopup();
      } 
      else {
        setMsg(response.message);
        setIsSuccess(false);
        openPopup();
      }
    }

    const resetFn = async() => {
      setIsOpenConfirmPopup(false);
      const response = await userReset({_id:userID});
      if (response.status === 'success') {
        setMsg(response.message);
        setIsSuccess(true);
        openPopup();
      } 
      else {
        setMsg(response.message);
        setIsSuccess(false);
        openPopup();
      }
    }
  
    const DeleteButton = ({ _id }) => {  
      return (
          <button
              className="p-1 rounded-md border border-gray-300 text-red-700 disabled:text-gray-300"
              onClick={()=>openConfirmPopup(_id,'delete')}
          >
                  <TrashIcon height="1em" title='Delete User' />
          </button>
      );
    };
  
    const EditButton = ({ user }) => {  
      return (
          <button
              className="p-1 rounded-md border border-gray-300 text-sky-700 disabled:text-gray-300"
              disabled={!user.status}
              onClick={()=>openModal("edit",user)}
          >
                  <PencilIcon height="1em" title='Edit User' />
          </button>
      );
    };

    const AutoResetPasswordButton = ({ user }) => {  
      return (
          <button
              className="p-1 rounded-md border border-gray-300 text-green-700 disabled:text-gray-300"
              disabled={!user.status}
              onClick={()=>(user.flag && user.flag===1)? openConfirmPopup(user._id,'mail') : openConfirmPopup(user._id,'reset')}
          >
                  {(user.flag && user.flag===1)? <EnvelopeIcon height="1em" title='Send Onboarding email' /> : <LockClosedIcon height="1em" title='Reset Password' />}
          </button>
      );
    };
  
    const columns = React.useMemo(
      () => [
          {
              accessorKey: "index",
              enableColumnFilter: false,
              enableSorting: false,
              size: 50,
              header: "S.No."
          },
          localStorage.getItem('role') !== "PMA" && {
            accessorKey: "client_name",
            enableColumnFilter: true,
            size: 50,
            header: () => <span>Client</span>,
            cell: ({ row }) => (
              <div title={row.original.client_code}>
                  {row.original.client_name}
              </div>
            ),
          },
          {
            accessorKey: "user_id",
            enableColumnFilter: false,
            size: 50,
            header: () => <span>User ID</span>
          },
          {
              accessorKey: "user_name",
              enableColumnFilter: false,
              size: 700,
              header: () => <span>User Name</span>
          },
          {
            accessorKey: "email",
            enableColumnFilter: false,
            size: 700,
            header: () => <span>User Email</span>
          },
          localStorage.getItem('role') !== "PMA" && {
            accessorKey: "role",
            enableColumnFilter: true,
            size: 50,
            header: () => <span>Role</span>
          },
          {
            accessorKey: "created_by",
            enableColumnFilter: false,
            size: 50,
            header: () => <span>Created By</span>
          },
          {
            accessorKey: "created_date",
            enableColumnFilter: false,
            size: 50,
            header: () => <span>Created Date</span>,
            cell: ({ row }) => (
                <div>
                    {row.original.created_date && row.original.created_date !== "" &&
                        formattedDate(row.original.created_date)}
                </div>
            ),
          },
          {
            accessorKey: "modify_by",
            enableColumnFilter: false,
            size: 50,
            header: () => <span>Modified By</span>
          },
          {
            accessorKey: "modify_date",
            enableColumnFilter: false,
            size: 50,
            header: () => <span>Modified Date</span>,
            cell: ({ row }) => (
                <div>
                    {row.original.modify_date && row.original.modify_date !== "" &&
                        formattedDate(row.original.modify_date)}
                </div>
            ),
          },
          {
            accessorKey: "status",
            enableColumnFilter: true,
            enableSorting:false,
            size: 50,
            header: () => <span>Status</span>,
            cell:({ row }) => (
              <div className='flex justify-center'>
              <div  onClick={()=>openStatusModal(row.original)}
                  className={`${row.original.status === true ? 'bg-gray-100 text-green-900' : 'bg-gray-100 text-red-900'} text-xs w-fit px-2 py-1 font-bold rounded-md cursor-default lg:cursor-pointer`}
              >
                  {row.original.status === true && "Active"}
                  {row.original.status === false && "Inactive"}
              </div>
              </div>      
            )
          },
          {
            accessorKey: "_id",
            enableColumnFilter: false,
            enableSorting: false,
            size: 50,
            header: () => <center>Actions</center>,
            cell: ({ row }) => (
              <div className="flex gap-2 items-center justify-center">
                  <AutoResetPasswordButton user={row.original}/>
                  <EditButton user={row.original}/>
                  {localStorage.getItem('role') !== "PMA" && <DeleteButton _id={row.original._id}/>}
              </div>
          ),
          }
      ].filter(Boolean),
      []
    );
  
    const additionalButtons = (
      <div className='flex gap-2'>
          <span className="group relative">
            <div className="absolute right-7 top-1/2 z-20 mr-0 -translate-y-1/2 whitespace-nowrap rounded-[5px] py-1.5 px-3.5 text-sm text-white opacity-0 group-hover:opacity-100">
              <div className="bottom-full right-0 rounded-md bg-gray-400 px-4 py-2.5 text-xs text-white whitespace-nowrap">
                Add New User
              </div>
            </div>
            <button className="inline-flex items-center rounded-md p-2 text-gray-700 ring-1 ring-inset ring-gray-300" onClick={()=>openModal("create",null)}>
              <PlusCircleIcon className="h-5 w-auto"/>
            </button>
        </span>
        <button className="inline-flex items-center rounded-md p-2 text-gray-700 ring-1 ring-inset ring-gray-300" onClick={refreshUsersList}><ArrowPathIcon className="h-5 w-auto"/></button> 
      </div>
    );

    useEffect(()=>{
        const fetchUsersList = async () => {
            getusersList()
            .then((response) => {
              if(response.status==='success' && response.data!==null && response.data.length>0){
                setUsers(response.data);
              }
            })
            .catch((error) => {
              setUsers([]);
              setMsg(error);
              setIsSuccess(false);
              openPopup();
            })
            .finally(()=>{
              setLoader(false);
            });
        };
        const fetchClientsList = async () => {
          getActiveClientsList()
          .then((response) => {
            if(response.status==='success' && response.data!==null && response.data.length>0){
              setClients(response.data);
            }
          })
          .catch((error) => {
            setClients([]);
            setMsg(error);
            setIsSuccess(false);
            openPopup();
          })
          .finally(()=>{
            setLoader(false);
          });
        };
        const fetchRolesList = async () => {
          getRolesList()
          .then((response) => {
            if(response.status==='success' && response.data!==null && response.data.length>0){
              setRoles(response.data);
            }
          })
          .catch((error) => {
            setRoles([]);
            setMsg(error);
            setIsSuccess(false);
            openPopup();
          });
        };

        if(localStorage.getItem('role') !== "PMA"){fetchRolesList(); fetchClientsList();}
        fetchUsersList();
      },[])

  return (
    <>
    <SidebarPanel panelheader="User Management" navigateback={true}/>
    <Popup
      isOpen={isOpenPopup}
      msg={msg}
      closeModal={closePopup}
      isSuccess={isSuccess}
    />
    <ConfirmationPopup
        isOpenConfirmPopup={isOpenConfirmPopup}
        confirmationMsg={confirmationMsg}
        closeConfirmPopup={closeConfirmPopup}
        onStateChange={confirmAction==='delete'?deleteFn:resetFn}
    />
    <InputModalsm
        isOpen={isOpenModal}
        closeModal={closeModal}
        title={editState?"Edit User":"New User"}
        saveName="Save"
        savefn={editState?handleEditUser:handleSaveUser}
        modalInputs={
            <div className="w-full bg-white space-y-1">
              {localStorage.getItem('role') !== "PMA" && 
            <>
            <label htmlFor="client" className="block text-sm font-bold leading-6 text-gray-500 ps-1">
              Client Name
            </label>
            <select
                  id="client"
                  name="client"
                  className="block w-auto md:w-full rounded-md border-0 py-2 mt-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs lg:max-w-full sm:text-sm sm:leading-6"
                  value={rowData.client_code} 
                  onChange={(e) => setRowData({...rowData, client_code: e.target.value})}
                >
                  <option value="">Select Client</option>
                  {clients.map((item, index) => (
                    <option key={index} value={item.client_code}>
                      {item.client_name}
                    </option>
                  ))}
                  {rowData.client_code && !clients.some(item => item.client_code === rowData.client_code) && (
                    <option value={rowData.client_code}>
                        {rowData.client_code}
                    </option>
                  )}
                </select>
            <label htmlFor="role" className="block text-sm font-bold leading-6 text-gray-500 pt-2 ps-1">
              Role
            </label>
            <select
                  id="role"
                  name="role"
                  className="block w-auto md:w-full rounded-md border-0 py-2 mt-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs lg:max-w-full sm:text-sm sm:leading-6"
                  value={rowData.role_id} 
                  onChange={(e) => setRowData({...rowData, role_id: e.target.value})}
                >
                  <option value="">Select Role</option>
                  {roles.map((item, index) => (
                    <option key={index} value={item.role_id}>
                      {item.role}
                    </option>
                  ))}
                </select>
              </>
            }
            <label htmlFor="name" className="block text-sm font-bold leading-6 text-gray-500 pt-2 ps-1">
              Name
            </label>
            <input
              type='text'
              autoFocus
              required
              id='name'                 
              value={rowData.user_name} 
              onChange={(e) => setRowData({...rowData, user_name: e.target.value})}
              className={`block w-full text-sm rounded-md border-0 ring-1 ring-gray-300 focus:ring-1 focus:ring-gray-200 p-2`}
            />
            {!editState &&
              <>
                <label htmlFor="email" className="block text-sm font-bold leading-6 text-gray-500 pt-2 ps-1">
                  Email
                </label>
                <input
                  type='text'
                  autoFocus
                  required
                  id='email'                 
                  value={rowData.email} 
                  onChange={(e) => setRowData({...rowData, email: e.target.value})}
                  className={`block w-full text-sm rounded-md border-0 ring-1 ring-gray-300 focus:ring-1 focus:ring-gray-200 p-2`}
                />
              </>
            }
            </div>
        }
    />
    <InputModalsm isOpen={isOpenStatusModal} closeModal={closeStatusModal} title={modalTitle} 
        saveName="Save" 
        savefn={handleStatusState}
        modalInputs={
          <div className='flex gap-5'>
          <div className="flex items-center gap-x-2">
                  <input
                    id="status"
                    name="status"
                    type="radio"
                    value="true"
                    checked={rowData.status === true || rowData.status === "true"}
                    onChange={(e) =>
                      setRowData({
                        ...rowData,
                        status: e.target.value,
                      })
                    }
                    autoFocus={false}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
                  />
                  <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                    Active
                  </label>
                </div>
                <div className="flex items-center gap-x-2">
                  <input
                    id="status"
                    name="status"
                    type="radio"
                    value="false"
                    checked={rowData.status === false || rowData.status === "false"}
                    onChange={(e) =>
                      setRowData({
                        ...rowData,
                        status: e.target.value,
                      })
                    }
                    autoFocus={false}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
                  />
                  <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900">
                    Inactive
                  </label>
                </div>
          </div>
        } 
      />
    <div className='m-3 border rounded border-gray-300 bg-white'>
    <VirtualizedTable hcss='max-h-[calc(100vh-16.8rem)] md:max-h-[calc(100vh-17rem)]' data={users} columns={columns} loader={loader} loadermsg="Fetching Users" noDataFoundmsg="No Users Found" allowSelect={false} additionalButtons={additionalButtons}/>
    </div>
    </>
  )
}

export default UserManagement