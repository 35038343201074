import axios from "axios";
import { API_URL } from "../../http";
import { handleApiError } from "../Common/APIUtils";

// Function to save the prompt
export const extractData = async (postData) => {
    try {
      const response = await axios.post(`${API_URL}/yt/extract`, postData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return response.data;
    } catch (error) {
      const errMsg = handleApiError(error);
      return {'status': 'error', 'message': errMsg};
    }
  };