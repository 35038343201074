import React, { useEffect, useState } from 'react'
import Popup from '../../Common/Popup';
import { getProfileInfo, updateProfile } from '../../Services/AuthServices';

export default function UpdateProfile() {
    let [data, setData]=useState({});  
    const [inputError, setInputError] = useState({
        name: '',
        email: ''
    });
    const [responseError, setResponseError]=useState(false);
    const [successMsg, setSuccessMsg]=useState('');
  
    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);
  
    function closePopup() {
      setIsOpenPopup(false);
    }
  
    function openPopup() {
      setIsOpenPopup(true);
    }

    const clearError = () => {
        setTimeout(() => {
          setSuccessMsg(false);
          setResponseError(false);
        }, 2000);
    };
    
    const validateUsername = (value) => {
        // Regular expression for no spaces in between and not starting with a space
        const namePattern = /^(?!\s)([a-zA-Z]+\s)*[a-zA-Z]+$/;
        const errormsgname= namePattern.test(value);
        return errormsgname;
    };

    const updateProfileInfo = (event) => {
        event.preventDefault();
    
    // Input validation
    setResponseError('');
    setInputError({
        name: '',
        email: ''
    })
    
    if (!data.name) {
        setInputError(prevState => ({...prevState, 'name': 'Please enter your name'}));
    } else if (!validateUsername(data.name)) {
        setInputError(prevState => ({...prevState, 'name': 'Please enter a valid name'}));
    }
    
    if (data.name && validateUsername(data.name)) {
        updateProfile({name:data.name}).then((response) => {
            if (response.status === "success") {
                setData({...data, name:response.data.name});
                setSuccessMsg(true);
                window.document.getElementById("sucsp").innerHTML=response.message;
                clearError();
            } else {
                setResponseError(true);
                window.document.getElementById("errdsp").innerHTML=response.message;
                clearError();
            } 
        })
    }};

    useEffect(()=>{
        const fetchProfileInfo = async () => {
          getProfileInfo()
            .then((response) => {
                if(response.status==='success' && response.data!==null){
                    setData(response.data);
                }
                else{
                    setMsg(response.message);
                    setIsSuccess(false);
                    openPopup();
                }
            })
            .catch((error) => {
              setMsg(error.message);
              setIsSuccess(false);
              openPopup();
            });
        };
        fetchProfileInfo();
    },[])

  return (
    <div className='border border-gray-300 bg-white rounded-md tracking-wider overflow-hidden p-4 pb-6 max-w-md'>
        <Popup
        isOpen={isOpenPopup}
        msg={msg}
        closeModal={closePopup}
        isSuccess={isSuccess}
        /> 
        <p className='mb-4 text-base text-sky-900'>Easily edit and manage your personal details here.</p>
        <p id="errdsp" className={`text-red-500 sm:mx-auto sm:w-full sm:max-w-sm border shadow text-center text-sm p-2 bg-gray-50 my-4 ${responseError ? 'block' : 'hidden'}`}></p>
        <p id="sucsp" className={`text-blue-800 sm:mx-auto sm:w-full sm:max-w-sm border shadow text-center text-sm p-2 bg-gray-50 my-4 ${successMsg ? 'block' : 'hidden'}`}></p>
        <form className='space-y-3.5' onSubmit={updateProfileInfo}>
        <div className="sm:col-span-3">
              <label htmlFor="name" className="block text-sm font-semibold leading-6 text-gray-900">
                Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="name"
                  id="name"
                  defaultValue={data.name}
                  onChange={(e) =>setData({...data, name: e.target.value})}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                />
                {inputError.name && <p className="text-xs mt-2 text-red-500">{inputError.name}</p>}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  disabled={true}
                  defaultValue={data.email}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6 disabled:bg-gray-100"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="inline-flex mt-2 justify-center rounded-lg border border-transparent bg-sky-900 px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-0 cursor-default lg:cursor-pointer"
               >
                Update Profile
              </button>
            </div>
            </form>
        
    </div>
  )
}
