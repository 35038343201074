import React, { useCallback, useEffect, useState } from "react";
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { deleteFile, downloadFile } from '../Services/FilesServices';
import Popup from './Popup';
import NoDataFound from "./NoDataFound";
import { ArrowDownTrayIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon, TrashIcon } from "@heroicons/react/24/outline";
import { DebouncedInput, IndeterminateCheckbox } from "./Filters";
import DataLoader from "./DataLoader";
import ConfirmationPopup from "./ConfirmationPopup";

function getFileTypeFromFilename(filename) {
    let extension = filename.split('.').pop().toLowerCase();
  
    // Map file extensions to MIME types
    const mimeTypes = {
      "pdf": "application/pdf",
      "doc": "application/msword",
      "docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "txt": "text/plain",
    };
  
    // Default to 'application/octet-stream' if the extension is not recognized
    return mimeTypes[extension] || 'application/octet-stream';
}

function FilesTable({data, setData, loader, loadermsg, noDataFoundmsg, additionalButtons, allowSelect, sessioncheckkey}) {

    // const [data, setData] = useState([]);

    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);
  
    function closePopup() {
      setIsOpenPopup(false);
    }
  
    function openPopup() {
      setIsOpenPopup(true);
    }

    let [fileID, setfileID] = useState('');
    const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
    const [confirmationMsg, setConfirmationMsg] = useState("");
  
    function closeConfirmPopup() {
      setIsOpenConfirmPopup(false);
    }
  
    function openConfirmPopup(id_prop) {
      setfileID(id_prop);
      setConfirmationMsg("Are you sure that you want to delete this file?");
      setIsOpenConfirmPopup(true);
    }

    const DownloadButton = ({ file_id, fileName }) => {

        const downloadFn = async() => {
          const response = await downloadFile(file_id);
              if (response.status === 'success' && response.data !== null) {
                  // Decode the base64-encoded content to binary
                   const binaryContent = atob(response.data);
    
                  // Create a Uint8Array from the binary content
                  const uint8Array = new Uint8Array(binaryContent.length);
                  for (let i = 0; i < binaryContent.length; i++) {
                    uint8Array[i] = binaryContent.charCodeAt(i);
                  }
    
                  // Create a Blob from the Uint8Array
                  const blob = new Blob([uint8Array], { type: getFileTypeFromFilename(fileName) }); // Adjust type based on your file type
    
                  // Create a URL object from the Blob
                  const url = window.URL.createObjectURL(blob);
    
                  // Create a link element
                  const link = document.createElement("a");
                  link.href = url;
                  link.download = fileName;
    
                  // Append the link to the document body
                  document.body.appendChild(link);
    
                  // Trigger the download
                  link.click();
    
                  // Cleanup
                  document.body.removeChild(link);
                  window.URL.revokeObjectURL(url);
              } 
              else {
                setMsg(response.message);
                setIsSuccess(false);
                openPopup();
              }
        }
        return (
            <button
                className="p-1 rounded-md border border-gray-300 text-sky-700 disabled:text-gray-300"
                onClick={downloadFn}
            >
                    <ArrowDownTrayIcon height="1em" title="File Download"/>
            </button>
        );
    };

    const deleteFn = async() => {
        setIsOpenConfirmPopup(false);
        const response = await deleteFile(fileID);
            if (response.status === 'success') {
              setData((prevData) => {
                const newData = prevData.filter(
                    (item) => item['id'] !== fileID
                );
                return newData;
              });
              setSelectedIds((prevSelectedIds) => {
                const updatedIds = prevSelectedIds.filter((selectedId) => selectedId !== fileID);
                // Store the array of selected row IDs in sessionStorage
                sessionStorage.setItem(sessioncheckkey, JSON.stringify(updatedIds));
                return updatedIds;
              });
            } 
            else {
              setMsg(response.message);
              setIsSuccess(false);
              openPopup();
            }
      }
      
      const DeleteButton = ({ file_id }) => {
      
        return (
            <button
                className="p-1 rounded-md border border-gray-300 text-red-700 disabled:text-gray-300"
                onClick={()=>openConfirmPopup(file_id)}
            >
                    <TrashIcon height="1em" title='Delete File' />
            </button>
        );
      };

    const [rowSelection, setRowSelection] = React.useState({});
    const [sorting, setSorting] = React.useState([]);
    const [selectedIds, setSelectedIds] = useState([]);

    useEffect(()=>{
        // Retrieve selected row IDs from sessionStorage
        const storedSelectedIds = sessionStorage.getItem(sessioncheckkey);
        const parsedSelectedIds = storedSelectedIds ? JSON.parse(storedSelectedIds) : [];
      
        // Update selectedIds state
        setSelectedIds(parsedSelectedIds);
    },[sessioncheckkey])      

    const handleSelectAll = useCallback(() => {
        if(Object.values(data).every((item) => selectedIds.includes(item.id))){
            setSelectedIds([]);
            // Store the array of selected row IDs in sessionStorage
            sessionStorage.setItem(sessioncheckkey, JSON.stringify([]));
        }
        else{
            const selectedRowIds = [];
            data.forEach((item) => {
                selectedRowIds.push(item.id);
            });
            setSelectedIds(selectedRowIds);
            // Store the array of selected row IDs in sessionStorage
            sessionStorage.setItem(sessioncheckkey, JSON.stringify(selectedRowIds));
        }
    },[data, selectedIds, sessioncheckkey]);

    const handleCheckboxChange = useCallback((id) => {
        setSelectedIds((prevSelectedIds) => {
          const updatedIds = prevSelectedIds.includes(id)
            ? prevSelectedIds.filter((selectedId) => selectedId !== id)
            : [...prevSelectedIds, id];
      
          // Store the array of selected row IDs in sessionStorage
          sessionStorage.setItem(sessioncheckkey, JSON.stringify(updatedIds));
      
          return updatedIds;
        });
    },[sessioncheckkey]);

    const columns = React.useMemo(
        () => [
            {
                id: "select",
                size: 10,
                header:
                    <IndeterminateCheckbox
                        {...{
                            checked: Object.values(data).every((item) => selectedIds.includes(item.id)),
                            disabled: !allowSelect,
                            indeterminate: !Object.values(data).every((item) => selectedIds.includes(item.id)) && selectedIds.length > 0,
                            onChange: ()=> handleSelectAll(),
                        }}
                    />
                ,
                cell: ({ row }) => (
                    <center className="px-1">
                        <IndeterminateCheckbox
                            {...{
                                checked: selectedIds.includes(row.original.id) || false,
                                disabled: !row.getCanSelect(),
                                indeterminate: row.getIsSomeSelected(),
                                onChange: () => handleCheckboxChange(row.original.id),
                            }}
                        />
                    </center>
                ),
            },
            {
                accessorKey: "index",
                enableColumnFilter: false,
                enableSorting: false,
                size: 50,
                header: "S.No."
            },
            {
                accessorKey: "Filename",
                enableColumnFilter: false,
                size: 1000,
                header: () => <span>Filename</span>
            },
            {
                accessorKey: "Upload Date",
                enableColumnFilter: false,
                size: 100,
                header: "Upload Date",
            },
            {
                accessorKey: "id",
                header: "Actions",
                size: 50,
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                    <div className="flex gap-2 items-center justify-center">
                        <DownloadButton file_id={row.original.id} fileName={row.original.Filename}/>
                        <DeleteButton file_id={row.original.id}/>
                    </div>
                ),
            },
        ],
        [handleCheckboxChange, handleSelectAll, allowSelect, data, selectedIds]
    );

    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(20);
    const [globalFilter, setGlobalFilter] = React.useState("");
  
  
    const pagination = React.useMemo(
      () => ({
        pageIndex,
        pageSize,
      }),
      [pageIndex, pageSize]
    )

    // Modify the pagination section
    const pageCount = Math.ceil(data.length / pageSize);
    const pageNumbers = [];

    for (let i = 0; i < pageCount; i++) {
      pageNumbers.push(i);
    }

    const handlePageChange = (e) => {
      const newPageSize = Number(e.target.value);
      setPageSize(newPageSize);
      setPageIndex(0)
    };
  

    const table = useReactTable({
        data,
        columns,
        state: {
            rowSelection,
            sorting,
            pagination,
            globalFilter
        },
        enableRowSelection: allowSelect,
        onRowSelectionChange: setRowSelection,
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel()
    });

    const renderTableCellIndex = (row) => {
        return table.getSortedRowModel().rows.indexOf(row)+1 || row.index+1;
    };

    const tableContainerRef = React.useRef(null);

    const { rows } = table.getRowModel();
    
    const rowVirtualizer = useVirtualizer({
        count: rows.length,
        estimateSize: () => 33, //estimate row height for accurate scrollbar dragging
        getScrollElement: () => tableContainerRef.current,
        //measure dynamic row height, except in firefox because it measures table border height incorrectly
        measureElement:
          typeof window !== 'undefined' &&
          navigator.userAgent.indexOf('Firefox') === -1
            ? element => element?.getBoundingClientRect().height
            : undefined,
        overscan: data.length,
    })

  return (
    <div className="border rounded-md border-gray-300 bg-white font-GoogleSans">
        <Popup
        isOpen={isOpenPopup}
        msg={msg}
        closeModal={closePopup}
        isSuccess={isSuccess}
      />
                         <ConfirmationPopup
                isOpenConfirmPopup={isOpenConfirmPopup}
                confirmationMsg={confirmationMsg}
                closeConfirmPopup={closeConfirmPopup}
                onStateChange={deleteFn}
            />
            <div className="flex justify-between m-2 gap-2">
        <DebouncedInput value={globalFilter ?? ""}  onChange={(value) => setGlobalFilter(String(value))}  placeholder="Search..."/>
            {additionalButtons}
      </div>
      {loader && <div className="border-t"><DataLoader msg={loadermsg}/></div>}
            {!loader && data.length === 0 && <div className="border-t"><NoDataFound msg={noDataFoundmsg}/></div>}
            {!loader && data.length > 0 && (
                <>
                    <div
                        ref={tableContainerRef}
                        className="resp-processtable-h border-y overflow-auto border-gray-200 chatboxscroll"
                    >
                        <table className="flex-inline w-full border-collapse bg-white">
                            <thead className="bg-gray-100 text-sky-700">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th
                                                    className={`border-r border-y p-2 whitespace-nowrap border-gray-300 ${header.column.id==='id' && 'border-r-0'}`}
                                                    key={header.id}
                                                    colSpan={header.colSpan}
                                                    style={{
                                                        width: header.getSize(),
                                                    }}
                                                >
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            <div
                                                                className={`${
                                                                    header.column.getCanSort() && header.column.id!=='index' 
                                                                        ? "cursor-pointer select-none justify-start"
                                                                        : "justify-center"
                                                                } flex`}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                            >
                                                                {flexRender(
                                                                    header
                                                                        .column
                                                                        .columnDef
                                                                        .header,
                                                                    header.getContext()
                                                                )}
                                                                {{
                                                                    asc: " 🔼",
                                                                    desc: " 🔽",
                                                                }[
                                                                    header.column.getIsSorted()
                                                                ] || null}
                                                            </div>
                                                        </>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                                    const row = rows[virtualRow.index];
                                    return (
                                        <tr
                                        
                  data-index={virtualRow.index} //needed for dynamic row height measurement
                  ref={node => rowVirtualizer.measureElement(node)} //measure dynamic row height
                                            key={row.id}
                                            className="bg-white transition duration-300 ease-in-out hover:bg-gray-50"
                                        >
                                            {row
                                                .getVisibleCells()
                                                .map((cell) => {
                                                    return (
                                                        <td
                                                            className={`border-r ${cell.column.id==='id' && 'border-r-0'} border-y border-gray-300 p-2 whitespace-nowrap ${cell.column.id==='index' && 'text-center'}`}
                                                            key={cell.id}
                                                        >
                                                            {cell.column.id==='index' && renderTableCellIndex(row)}
                                                            {cell.column.id!=='index' && flexRender(cell.column.columnDef.cell,cell.getContext())}
                                                        </td>
                                                    );
                                                })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='flex gap-1 justify-between flex-row-reverse m-2'>
        <nav className="isolate inline-flex -space-x-px mx-2 md:m-0 bg-white rounded-md text-gray-900" aria-label="Pagination">
            <button title='First Page' onClick={() => setPageIndex(0)}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <ChevronDoubleLeftIcon className="h-4 w-4" aria-hidden="true" />
            </button>
            <button title='Previous Page'
            onClick={() => setPageIndex(pageIndex-1)}
            disabled={pageIndex===0}
              aria-current="page"
              className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
            </button>
            {pageNumbers.map((pageNumber, index) => {
            if (pageNumber === 0 || pageNumber === table.getPageCount() - 1 || Math.abs(pageNumber - pageIndex) <= 1) {
              return (
                <button
                  className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                  key={index}
                  onClick={()=>setPageIndex(pageNumber)}
                >
                  {pageNumber + 1}
                </button>
              );
            } else if (pageNumber === 1 || pageNumber === table.getPageCount() - 2) {
              return <button className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0' key={index}>...</button>;
            }
            return null;
          })}
            <button title='Next Page' onClick={() => setPageIndex(pageIndex+1)}  disabled={pageIndex===(table.getPageCount() - 1)}
              className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
           </button>
            <button title='Last Page' onClick={() => setPageIndex(table.getPageCount() - 1)}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <ChevronDoubleRightIcon className="h-4 w-4" aria-hidden="true" />
            </button>
          </nav>
          <div>
          <div className="sm:col-span-3 flex gap-3">
              <div>
                <select
                  id="records"
                  name="records"
                  value={pageSize}
                  onChange={handlePageChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-700 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  {[20,50,100,500,1000].map((page)=>(
                  <option className='p-1' key={page} value={page}>Show {page}</option>
                  ))}
                </select>
              </div>
              {Object.keys(rowSelection).length > 0 &&
                <>
                  <button className="rounded-md hidden cursor-default lg:cursor-pointer p-2 md:inline-flex text-sm bg-gray-100 border-gray-300 border">
                    Download
                  </button>
                  <button className="rounded-md hidden cursor-default lg:cursor-pointer p-2 md:inline-flex text-sm bg-gray-100 border-gray-300 border">
                    Delete
                  </button>
                </>
              }
            </div>
          </div>
        </div>
                </>
            )}
    </div>
  )
}

export default FilesTable