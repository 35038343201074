import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { BreakTextFileName } from "./CommonUtils";
import { getAllFilesList, uploadFiles } from "../Services/FilesServices";
import Popup from "./Popup";
import { ArrowPathIcon, CloudArrowUpIcon } from "@heroicons/react/24/outline";
import DataLoader from "./DataLoader";
import FilesTable from "./FilesTable";
import ExtractDocuments from "./ExtractDocuments";

function FileHandler({ files, setFiles, tabState }) {
  let [show, setShow] = useState(true);
  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [msg, setMsg] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  function closePopup() {
    setIsOpenPopup(false);
  }

  function openPopup() {
    setIsOpenPopup(true);
  }

  const acceptedFileTypes = {
    "application/pdf": [".pdf"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    "text/plain": [".txt"],
  };

  const maxFileSize = {
    ".pdf": 5 * 1024 * 1024, // 5MB
    ".doc": 5 * 1024 * 1024, // 5MB
    ".docx": 5 * 1024 * 1024, // 5MB
    ".txt": 5 * 1024 * 1024, // 5MB
  };

  function getFileExtension(filename) {
    return "." + filename.split(".").pop().toLowerCase();
  }

  function handleFileChange(acceptedFiles) {
    const validatedFiles = acceptedFiles.filter((file) => {
      const fileExtension = getFileExtension(file.name);
      const fileSize = file.size;

      if (!acceptedFileTypes[file.type]?.includes(fileExtension)) {
        setMsg("Invalid file type. Only csv files are allowed.");
        setIsSuccess(false);
        openPopup();
        return false;
      }

      if (fileSize > maxFileSize[fileExtension]) {
        setMsg(`File size exceeds the limit for ${fileExtension} files.`);
        setIsSuccess(false);
        openPopup();
        return false;
      }

      return true;
    });

    setFiles(validatedFiles);
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFileTypes,
    maxSize: Math.max(...Object.values(maxFileSize)),
    onDrop: handleFileChange,
    multiple: true,
  });

  const handleFilesUpload = async() => {
    if (files.length > 0) {
      setIsLoading(true);
      const formData = new FormData();
        // Loop through files and append them to the FormData
        files.forEach((file, index) => {
          formData.append('file', file);
        });
        formData.append('project_code',sessionStorage.getItem("projectCode"))
        const response = await uploadFiles(formData);
        if (response.status === 'success' && response.data !== null) {
          setMsg(response.message);
          setFiles([]);
          setIsSuccess(true);
          setTimeout(() => {
            setIsLoading(false);
            openPopup();
          }, 100);
          setTimeout(()=>{
            fetchFiles(true);
          },300);
        } else {
          setMsg(response.message);
          setIsSuccess(false);
          setTimeout(() => {
            setIsLoading(false);
            openPopup();
          }, 100);
        }
    } else {
      setMsg("No Files/Options are Selected.");
      setIsSuccess(false);
      openPopup();
    }
  };

  const [data, setData] = useState([]);
  const [dataloader, setDataLoader] = useState(true);

  const fetchFiles = async(reset) => {
    setShow(true);
    setDataLoader(true);
    const response = await getAllFilesList({'project_code':sessionStorage.getItem("projectCode")});
        if (response.status === 'success' && response.data !== null) {
          const keyOrder = [
            "Filename",
            "Upload Date",
            "Default",
            "id",
          ];
        
          // Map over the entries and create objects with the desired key order
          const formattedData = response.data.map((entry) => {
            const formattedEntry = {};
            keyOrder.forEach((key) => {
                formattedEntry[key] = entry[key];
            });
            return formattedEntry;
          });

          if(reset===true){
            const selectedRowIds = [];
            formattedData.filter(item => !item.Default).forEach((item) => {
              selectedRowIds.push(item.id);
            });
            // Retrieve selected row IDs from sessionStorage
            const storedSelectedIds = sessionStorage.getItem("loadedDocs");
            const parsedSelectedIds = storedSelectedIds ? JSON.parse(storedSelectedIds) : [];
            parsedSelectedIds?.forEach((id)=>selectedRowIds.push(id));
            // Store the array of selected row IDs in sessionStorage
            sessionStorage.setItem("loadedDocs", JSON.stringify(selectedRowIds));
          }
          
          setData(formattedData);
          setTimeout(() => {
            setDataLoader(false);
          }, 150);

        } else {
          setDataLoader(false);
          setMsg(response.message);
          setIsSuccess(false);
          openPopup();
        }
  }

  useEffect(()=>{
    const ResumeFiles = async() => {
      setShow(true);
      setDataLoader(true);
      const response = await getAllFilesList({'project_code':sessionStorage.getItem("projectCode")});
          if (response.status === 'success' && response.data !== null) {
            const keyOrder = [
              "Filename",
              "Upload Date",
              "Default",
              "id",
            ];
  
          // Map over the entries and create objects with the desired key order
          const formattedData = response.data.map((entry) => {
              const formattedEntry = {};
              keyOrder.forEach((key) => {
                  formattedEntry[key] = entry[key];
              });
              return formattedEntry;
          });
            setData(formattedData);
            setTimeout(() => {
              setDataLoader(false);
            }, 100);
          } else {
            setDataLoader(false);
            setMsg(response.message);
            setIsSuccess(false);
            openPopup();
          }
    }
    ResumeFiles();
  },[])

  const additionalButtons = (
    <div className="flex gap-2">
      <div className="toptooltip">
        <button className="inline-flex items-center rounded-md p-2 text-sm tracking-wider font-medium text-gray-700 ring-1 ring-inset ring-gray-300" onClick={fetchFiles}><ArrowPathIcon className="h-5 w-auto"/></button>
        <span className="tooltiptexttop p-1 hidden lg:block bg-sky-700 text-sm w-fit whitespace-nowrap px-2">Refresh Documents</span>
      </div>
      <div className="toptooltip">
        <button type="button" onClick={() => setShow(false)} className="inline-flex items-center rounded-md p-2 text-sm tracking-wider font-medium text-gray-700 ring-1 ring-inset ring-gray-300"><CloudArrowUpIcon className="h-5 w-auto"/></button>
        <span className="tooltiptexttop p-1 hidden lg:block bg-sky-700 text-sm w-fit whitespace-nowrap px-2">Upload Documents</span>
      </div>
    </div>
  );
  

  const tabs = [
    {
        id: 1,
        title: "Recently Uploaded Documents",
        content: (
          <div className="p-2">
            <FilesTable data={data.filter(item => !item.Default)} setData={setData} allowDelete={true} loader={dataloader} loadermsg="Fetching Documents" noDataFoundmsg="No Documents Found" allowSelect={false} sessioncheckkey="loadedDocs" additionalButtons={additionalButtons}/>
          </div>
        ),
    },
    {
        id: 2,
        title: "Document Database",
        content: (
          <div className="p-2">
            <FilesTable data={data.filter(item => item.Default)} setData={setData} allowDelete={true} loader={dataloader} loadermsg="Fetching Documents"  noDataFoundmsg="No Documents Found" allowSelect={true} sessioncheckkey="loadedDocs" additionalButtons={additionalButtons}/>
          </div>
        ),
    },
    {
      id: 3,
      title: "Extract Document",
      content: (
        <div className="p-2">
          <ExtractDocuments refetchFiles={fetchFiles}/>
        </div>
      ),
    }
  ]

  // Function to get the content for the selected tab
  const getTabContent = () => {
    const selectedTab = tabs.find(tab => tab.id === tabState);
    return selectedTab ? selectedTab.content : null;
  };

  useEffect(()=>{
    setShow(true);
  },[tabState])

  return (
    <>
      <Popup
        isOpen={isOpenPopup}
        msg={msg}
        closeModal={closePopup}
        isSuccess={isSuccess}
      />
      {/* <Tabs tabs={tabs}/> */}
      {show &&
        getTabContent()
      }
      {!show &&
      <div className="p-2 h-[calc(100vh-14rem)] md:h-[calc(100vh-13.5rem)] rounded-md border border-dashed border-gray-900 m-3 mb-1 overflow-auto">
        <div
          className={`flex justify-center items-center p-3 ${
            files.length > 4 ? "" : "h-full"
          }`}
        >
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <div className="text-center">
              <div className="flex justify-center text-sm leading-6 text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-0 focus-within:border-white focus-within:ring-whitefocus-within:ring-offset-2 hover:text-indigo-500"
                >
                  <span className="hidden md:block">
                    {files.length < 1 ? "Upload" : "Re-Upload"} Files
                  </span>
                  <span className="block md:hidden">
                    {files.length < 1 ? "Upload" : "Re-Upload"} Files
                  </span>
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs leading-5 text-gray-600">
                Each PDF/DOC/TXT File limit up to 5MB
              </p>
              <aside>{files.map((file) => BreakTextFileName(file))}</aside>
            </div>
          </div>
        </div>
      </div>
      }
      {!show && <div className={`flex gap-2 py-2 px-3`}>
        <>
        <button
          type="button"
          className={`flex w-full justify-center rounded-lg border border-transparent bg-sky-100 text-sm font-medium text-sky-900 hover:bg-sky-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-sky-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer tracking-wider ${!isLoading && 'px-4 py-2.5'}`}
          disabled={isLoading}
          onClick={handleFilesUpload}
        >
          {!isLoading && <>Upload<span className="hidden md:block">&nbsp;Files</span></>}
          {isLoading && <DataLoader msg="Uploading Files"/>}
        </button>
        <button
          type="button"
          onClick={fetchFiles}
          className="flex w-full justify-center rounded-lg border border-transparent bg-sky-100 px-4 py-2.5 text-sm font-medium text-sky-900 hover:bg-sky-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-sky-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer tracking-wider"
        >
          Show<span className="hidden md:block">&nbsp;Files</span>
        </button>
        <button
          type="button"
          onClick={() => setFiles([])}
          className="flex w-full justify-center rounded-lg border border-transparent bg-red-100 px-4 py-2.5 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer tracking-wider"
        >
          Clear<span className="hidden md:block">&nbsp;Files</span>
        </button>
        </>
      </div>
      }
    </>
  );
}

export default FileHandler;
