import React, { useState } from "react";
import {ClipboardIcon, HandThumbDownIcon, HandThumbUpIcon} from "@heroicons/react/24/outline"
import InputModal from "../../../Common/InputModal";
import { submitFeedback } from "../../../Services/ChatbotServices";
import showdown from "showdown";

function MessageItem({msg, index, listref, setPopupMsg, setIsSuccess, openPopup}) {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    const textToCopy = msg.message;
    navigator.clipboard.writeText(textToCopy)
      .then(() => setIsCopied(true));

    // Reset the "Copied" state after a brief delay
    setTimeout(() => setIsCopied(false), 2000);
  };

  let [isOpenModal, setIsOpenModal] = useState(false);
  let [isGoodFeedback, setIsGoodFeedback] = useState(false);
  let [feedbackMsg, setFeedbackMsg] = useState({});
  let [feedback, setFeedback] = useState('');

  function closeModal() {
    setIsOpenModal(false);
  }

  function openModal(feedbackState,msg) {
    setIsGoodFeedback(feedbackState);
    setFeedbackMsg(msg);
    setIsOpenModal(true);
  }

  const handleSubmitFeedback = () => {
    closeModal();
    submitFeedback({"chat_history_id":feedbackMsg.id,"feedback":feedback,"status":isGoodFeedback})
      .then(response => {
        if (response.status==='success') {
          setPopupMsg(response.message);
          setIsSuccess(true);
        } else {
          setPopupMsg(response.message);
          setIsSuccess(false);
        }
        openPopup();
      })
  };

  return (
    <li
      key={index}
      ref={listref}
      className={`flex items-end font-GoogleSans gap-1.5 ${
        msg.user === "sender" ? "self-end" : ""
      }`}
    >
      <InputModal
        isOpen={isOpenModal}
        closeModal={closeModal}
        title={<div className="flex items-center gap-2"><div className={`rounded-full p-[0.3rem] ${isGoodFeedback?'bg-gray-300 text-gray-900':'bg-red-300 text-red-600'}`}>{isGoodFeedback?<HandThumbUpIcon className="h-5 w-auto"/>:<HandThumbDownIcon className="h-5 w-auto"/>}</div>Provide Feedback</div>}
        saveName="Submit Feedback"
        savefn={handleSubmitFeedback}
        modalInputs={
          <div className="w-full bg-white space-y-1">
            <textarea autoFocus placeholder={` ${isGoodFeedback?'Share your positive experience! How has RIA helped you?':'What was the issue with the response? How could it be improved?'}`} id='feedbackTextarea' onChange={(e) => setFeedback(e.target.value)} className={`block w-full text-sm rounded-md border-0 ring-1 ring-gray-200 focus:ring-1 focus:ring-gray-200 p-3 h-52 resize-none max-h-[30vh] chatboxscroll`}/>
          </div>
        }
      />
      <div className="flex-shrink-0 hidden md:block relative">
        {msg.user !== "sender" && (
          <div className="bg-gray-300 px-1.5 py-2 rounded-full text-sky-900 text-xs font-bold tracking-wider">
            RIA
          </div>
        )}
      </div>
      <div
        className={`flex flex-col ${
          msg.user !== "sender"
            ? "bg-gray-100 rounded-lg md:rounded-bl-none md:rounded-t-xl md:rounded-br-xl pt-2"
            : "bg-blue-500 rounded-lg md:rounded-br-none md:rounded-t-xl md:rounded-bl-xl pt-2"
        }`}
      >
        {msg.user !== "sender" && (
          <>
            <p className="font-bold text-sky-900 flex md:hidden justify-between gap-2 px-3 text-xs capitalize tracking-wider">
              <span>{msg.user}</span>
              <span>{msg.chat_date_time}</span>
            </p>
            <p className="font-bold text-sky-900 hidden md:flex justify-end gap-2 px-3 text-xs capitalize tracking-wider">
              {msg.chat_date_time}
            </p>
          </>
        )}
        {msg.user === "sender" && (
          <>
            <p className="font-bold text-white flex md:hidden justify-between gap-2 text-xs px-2 capitalize tracking-wider">
              <span>You</span>
              <span>{msg.human_msg_date_time}</span>
            </p>
            <p className="font-bold text-white hidden md:block text-xs px-3 capitalize tracking-wider">
              {msg.human_msg_date_time}
            </p>
          </>
        )}
        <article className={`${
            msg.user === "sender"
              ? "bg-blue-500 text-white prose-code:text-white"
              : "bg-gray-100 text-black"
          } p-1.5 px-3 inline-flex rounded-lg text-xs md:text-sm break-words hyphens-normal whitespace-pre-wrap markdown prose-li:marker:text-transparent list-hyphen prose prose-code:font-thin w-full text-pretty md:text-justify lg:max-w-[calc(100vw-35rem)]`}
          dangerouslySetInnerHTML={{ __html: msg.message.includes('```')? (msg.message.includes('Answer:```')? msg.message.replace(/Answer:```/g, "").replace(/```/g, "").replace(/(?:\n{2,})/g, "<br/><br/>").replace(/\n/g, "<br/>") :  msg.message.replace(/```[^\n]*([\s\S]*?)```/g, "$1").replace(/(?:\n{2,})/g, "<br/><br/>").replace(/\n/g, "<br/>") ) : new showdown.Converter().makeHtml(msg.message.replace(/(?:\n{2,})/g, "<br/><br/>").replace(/\n/g, "<br/>"))}}
        />
        {msg.user!=="sender" && <div className="flex justify-end items-center pt-0.5 pb-2 px-3 gap-1">{isCopied && <p className="font-bold text-xs text-gray-400">Copied!</p>}<button className="rounded-md p-1 bg-white focus:bg-sky-700 focus:text-white"  onClick={copyToClipboard}><ClipboardIcon className='h-4 w-auto'/></button><button className="rounded-md p-1 bg-white focus:bg-sky-700 focus:text-white" onClick={()=>openModal(true, msg)}><HandThumbUpIcon className='h-4 w-auto'/></button><button className="rounded-md p-1 bg-white focus:bg-sky-700 focus:text-white" onClick={()=>openModal(false, msg)}><HandThumbDownIcon className='h-4 w-auto'/></button></div>}
      </div>
      <div className="flex-shrink-0 hidden md:block relative">
        {msg.user === "sender" && (
          <div className="bg-gray-300 px-1.5 py-2 rounded-full text-black text-xs font-bold tracking-wider">
            You
          </div>
        )}
      </div>
    </li>
  );
}

export default MessageItem;
