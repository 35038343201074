import React, { useEffect, useState } from "react";
import { deployUpdatePrompt, getDeployedPromptsList, updateDeployedPromptStatus } from "../../../Services/LLMOpsServices";
import SearchableSingleSelector from "../../../Common/SearchableSingleSelector";
import Popup from "../../../Common/Popup";
import VirtualizedTable from "../../../Common/VirtualizedTable";
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import InputModalsm from "../../../Common/InputModalsm";
import { formattedDate } from "../../../Common/CommonUtils";

function DeployPrompt({
  promptList,
  options,
  selectedData,
  setSelectedData,
  globalPrompts
}) {
  // Handling Selections for deploy prompt
  const [selectedDeployPrompt, setSelectedDeployPrompt] = useState([]);
  const [deployedPrompts, setDeployedPrompts] = useState([]);

  let [loader, setLoader] = useState(true);
  let [rowData,setRowData]=useState([]);

  // Handling Error/Success messages for the API Response such as saved, error occured, etc
  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [msg, setMsg] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);

  function closePopup() {
    setIsOpenPopup(false);
  }

  function openPopup() {
    setIsOpenPopup(true);
  }

  const refreshDeployedPromptsList = async () => {
    setLoader(true);
    getDeployedPromptsList()
      .then((response) => {
        setDeployedPrompts(response.data);
      })
      .catch((error) => {
        setMsg(error.message);
        setIsSuccess(false);
        openPopup();
      })
      .finally(()=>{
        setLoader(false);
      });
  };

  let [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
  let [modalTitle, setModalTitle] = useState('');

  function closeStatusModal() {
    setIsOpenStatusModal(false);
  }

  function openStatusModal(e) {
    setRowData(e);
    setModalTitle('Update Prompt Deployment Status')
    setIsOpenStatusModal(true);
  }

  const handleStatusState = async() => {
    let statusData = { status: rowData.status === false || rowData.status === 'false' ? false : true };
    updateDeployedPromptStatus({"_id":rowData._id,"status":statusData.status,"project_code":rowData.project_code,"client_code":rowData.client_code})
      .then(response => {
        if (response.status==='success') {
          closeStatusModal();
          setMsg(response.message);
          setIsSuccess(true);
          openPopup();
        } else {
          closeStatusModal();
          setMsg(response.message);
          setIsSuccess(false);
          openPopup();
        }
      })
      .finally(()=>{
        refreshDeployedPromptsList();
      });
  };

  const deployPrompt = () => {
    let deply_prm = {
      prompt_id: selectedDeployPrompt.id,
      project_code: selectedData.project_code,
      client_code: selectedData.client_code,
    };
    deployUpdatePrompt(deply_prm).then((response) => {
      if (response.status === "success") {
        setMsg(response.message);
        setIsSuccess(true);
      } else {
        setMsg(response.message);
        setIsSuccess(false);
      }
      openPopup();
      refreshDeployedPromptsList();
    });
  };

  // const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  // const [confirmationMsg, setConfirmationMsg] = useState("");
  // const [id, setID] = useState("");

  // function closeConfirmPopup() {
  //   setIsOpenConfirmPopup(false);
  // }

  // function openConfirmPopup(id_prop) {
  //   setID(id_prop);
  //   setConfirmationMsg("Are you sure that you want to delete this deployed prompt?");
  //   setIsOpenConfirmPopup(true);
  // }

  // const DeleteButton = ({ id_prop }) => {  
  //   return (
  //       <button
  //           className="p-1 rounded-md border border-gray-300 text-red-700 disabled:text-gray-300"
  //           onClick={()=>openConfirmPopup(id_prop)}
  //       >
  //               <TrashIcon height="1em" title='Delete Project' />
  //       </button>
  //   );
  // };

  const columns = React.useMemo(
    () => [
        {
            accessorKey: "index",
            enableColumnFilter: false,
            enableSorting: false,
            size: 50,
            header: "S.No."
        },
        {
          accessorKey: "client_name",
          enableColumnFilter: true,
          size: 50,
          header: () => <span>Client</span>
        },
        {
          accessorKey: "project_name",
          enableColumnFilter: true,
          size: 50,
          header: () => <span>Project</span>,
          cell: ({ row }) => (
            <div>
                {row.original.project_name && row.original.project_name}{!row.original.project_name && 'NA'}
            </div>
          ),
        },       
        {
          accessorKey: "catg",
          enableColumnFilter: true,
          size: 50,
          header: () => <span>Category</span>
        },
        {
          accessorKey: "prompt",
          enableColumnFilter: false,
          size: 800,
          header: () => <span>Deployed Prompt</span>,
          cell: ({ row }) => (
            <div>
                {row.original.prompt_id} - {row.original.prompt}
            </div>
          ),
        },
        {
          accessorKey: "created_date",
          enableColumnFilter: false,
          size: 50,
          header: () => <span>Created Date</span>,
          cell: ({ row }) => (
              <div>
                  {row.original.created_date && row.original.created_date !== "" &&
                      formattedDate(row.original.created_date)}
              </div>
          ),
        },
        {
          accessorKey: "modify_date",
          enableColumnFilter: false,
          size: 50,
          header: () => <span>Modified Date</span>,
          cell: ({ row }) => (
              <div>
                  {row.original.modify_date && row.original.modify_date !== "" &&
                      formattedDate(row.original.modify_date)}
              </div>
          ),
        },
        {
          accessorKey: "status",
          enableColumnFilter: true,
          enableSorting:false,
          size: 50,
          header: () => <span>Status</span>,
          cell:({ row }) => (
            <div className='flex justify-center'>
            <div  onClick={()=>openStatusModal(row.original)}
                className={`${row.original.status === true ? 'bg-gray-100 text-green-900' : 'bg-gray-100 text-red-900'} text-xs w-fit px-2 py-1 font-bold rounded-md cursor-default lg:cursor-pointer`}
            >
                {row.original.status === true && "Active"}
                {row.original.status === false && "Inactive"}
            </div>
            </div>      
          )
        },
        // {
        //   accessorKey: "id",
        //   enableColumnFilter: false,
        //   enableSorting: false,
        //   size: 50,
        //   header: () => <center>Actions</center>,
        //   cell: ({ row }) => (
        //     <div className="flex gap-2 items-center justify-center">
        //         <DeleteButton project_code={row.original.project_code}/>
        //     </div>
        // ),
        // }
    ],
    []
  );

  const additionalButtons = (
    <div className='flex gap-2'>
      <button className="inline-flex items-center rounded-md p-2 text-gray-700 ring-1 ring-inset ring-gray-300" onClick={refreshDeployedPromptsList}><ArrowPathIcon className="h-5 w-auto"/></button> 
    </div>
  );

  useEffect(()=>{
    const fetchDeployedPrompts = async () => {
      getDeployedPromptsList()
      .then((response) => {
        if(response.status==='success' && response.data!==null && response.data.length>0){
          setDeployedPrompts(response.data);
        }
      })
      .catch((error) => {
        setMsg(error.message);
        setIsSuccess(false);
        openPopup();
      })
      .finally(()=>{
        setLoader(false);
      });
    };

    fetchDeployedPrompts();
  },[]);

  return (
    <>
      <Popup
        isOpen={isOpenPopup}
        msg={msg}
        closeModal={closePopup}
        isSuccess={isSuccess}
      />
      {/* <ConfirmationPopup
        isOpenConfirmPopup={isOpenConfirmPopup}
        confirmationMsg={confirmationMsg}
        closeConfirmPopup={closeConfirmPopup}
        onStateChange={deleteFn}
      /> */}
      <InputModalsm isOpen={isOpenStatusModal} closeModal={closeStatusModal} title={modalTitle} 
        saveName="Save" 
        savefn={handleStatusState}
        modalInputs={
          <div className='flex gap-5'>
          <div className="flex items-center gap-x-2">
                  <input
                    id="status"
                    name="status"
                    type="radio"
                    value="true"
                    checked={rowData.status === true || rowData.status === "true"}
                    onChange={(e) =>
                      setRowData({
                        ...rowData,
                        status: e.target.value,
                      })
                    }
                    autoFocus={false}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
                  />
                  <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                    Active
                  </label>
                </div>
                <div className="flex items-center gap-x-2">
                  <input
                    id="status"
                    name="status"
                    type="radio"
                    value="false"
                    checked={rowData.status === false || rowData.status === "false"}
                    onChange={(e) =>
                      setRowData({
                        ...rowData,
                        status: e.target.value,
                      })
                    }
                    autoFocus={false}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
                  />
                  <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900">
                    Inactive
                  </label>
                </div>
          </div>
        } 
      />
      <div className="border rounded-md border-gray-300 m-2 bg-white">
        <div className="flex flex-col md:flex-row items-end gap-3 p-2 pb-4 bg-zinc-50 rounded-md">
          <div className="w-full md:w-3/12 space-y-1">
            <label
              htmlFor="name"
              className="text-sm font-medium leading-6 text-gray-900 text-left ms-1"
            >
              Client Name
            </label>
            <select
              id="client"
              name="client"
              className="block text-sm w-full rounded-md border border-gray-300 py-2 mt-1 text-gray-900 ring-0 focus:ring-0 focus:border-gray-300 sm:max-w-xs lg:max-w-full sm:text-sm sm:leading-6"
              value={selectedData.client_code}
              onChange={(e) =>
                setSelectedData({ ...selectedData, client_code: e.target.value })
              }
            >
              <option value="">Select Client</option>
              {options &&
                [...new Set(options.map((item) => item.client_code))].map(
                  (clientCode, index) => {
                    const clientName = options.find(
                      (item) => item.client_code === clientCode
                    ).client_name;
                    return (
                      <option key={index} value={clientCode}>
                        {clientName}
                      </option>
                    );
                  }
                )}
            </select>
          </div>
          <div className="w-full md:w-3/12">
            <label
              htmlFor="projectName"
              className="text-sm font-medium leading-6 text-gray-900 text-left ms-1"
            >
              Project Name
            </label>
            <select
              id="projectName"
              name="projectName"
              className="block text-sm w-full rounded-md border border-gray-300 py-2 mt-1 text-gray-900 ring-0 focus:ring-0 focus:border-gray-300 sm:max-w-xs lg:max-w-full sm:text-sm sm:leading-6"
              value={selectedData.project_code}
              onChange={(e) =>
                setSelectedData({ ...selectedData, project_code: e.target.value })
              }
            >
              <option value="">Select Project</option>
              {options
                ?.filter(
                  (client) => client.client_code === selectedData.client_code
                )
                ?.map((item, index) => (
                  <option key={index} value={item.project_code}>
                    {item.project_name}
                  </option>
                ))}
            </select>
          </div>
          <div className="w-full space-y-1">
            <label className="block text-sm font-medium leading-6 text-gray-900 text-left ms-1">
              Select Prompt
            </label>
            <SearchableSingleSelector
              items={[
                ...(promptList || [])?.filter((prompt) => {
                  if (!selectedData.project_code && selectedData.client_code) {
                      return prompt.catg === "CLIENT" && prompt.client_code === selectedData.client_code;
                  } else if (selectedData.project_code) {
                      return (prompt.catg === "CLIENT" && prompt.client_code === selectedData.client_code) || (prompt.catg === "PROJECT" && prompt.project_code === selectedData.project_code);
                  }
                  return false;
              }),
              ...(globalPrompts || [])
              ]}
              additionalshowprop="catg,id"
              selected={selectedDeployPrompt}
              setSelected={setSelectedDeployPrompt}
              filterby="prompt"
              nooverlap={true}
              selectorIndex="prompt"
            />
          </div>
          <button
            type="button"
            className="flex w-fit h-fit whitespace-nowrap mt-2 justify-center rounded-lg border border-transparent bg-sky-800 px-4 py-2.5 text-sm font-medium text-white hover:bg-sky-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-sky-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer tracking-wider"
            onClick={deployPrompt}
          >
            Deploy Prompt
          </button>
        </div>
      </div>
      <div className="border rounded-md border-gray-300 m-2 bg-white">
        <VirtualizedTable hcss='max-h-[calc(100vh-21rem)] md:max-h-[calc(100vh-23rem)]' data={deployedPrompts} columns={columns} loader={loader} loadermsg="Fetching Deployed Prompts" noDataFoundmsg="No Prompts Deployed" allowSelect={false} additionalButtons={additionalButtons}/>
      </div>
    </>
  );
}

export default DeployPrompt;
