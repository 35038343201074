import React from "react";

function NoDataFound({msg}) {
  return (
    <div className="p-2.5 text-sm flex">
      {msg || 'No Data Found...'}
    </div>
  );
}

export default NoDataFound;
