import React, { useEffect, useState } from 'react'
import SidebarPanel from '../../Common/SidebarPanel'
import VirtualizedTable from '../../Common/VirtualizedTable'
import { ArrowPathIcon, PencilIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import Popup from '../../Common/Popup';
import ConfirmationPopup from '../../Common/ConfirmationPopup';
import InputModalsm from '../../Common/InputModalsm';
import { formattedDate } from '../../Common/CommonUtils';
import { deleteClient, getClientsList, saveClient, updateClient, updateClientStatus } from '../../Services/ClientServices';

function ClientManagement() {
    let [clients, setClients]=useState([])
    let [rowData,setRowData]=useState([]);
    let [clientCode, setclientCode] = useState("");
    let [loader, setLoader] = useState(true);

    let [isOpenModal, setIsOpenModal] = useState(false);
    let [editState, setEditState] = useState(false);

    function closeModal() {
      setIsOpenModal(false);
      setRowData([]);
    }
  
    function openModal(option, client_data) {
      if(option==="create"){
        setEditState(false);
        setRowData([]);
      }
      else{
        setEditState(true);
        setRowData(client_data);
      }
      setIsOpenModal(true);
    }

    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);
  
    function closePopup() {
      setIsOpenPopup(false);
    }
  
    function openPopup() {
      setIsOpenPopup(true);
    }

    const refreshClientsList = async () => {
        setLoader(true);
        getClientsList().then((response) => {
            if(response.status==='success' && response.data!==null && response.data.length>0){
            setClients(response.data);
            }
            else{
            setClients([]);
            }
        })
        .catch((error) => {
          setClients([]);
          setMsg(error);
          setIsSuccess(false);
          openPopup();
        })
        .finally(()=>{
            setLoader(false);
        });
      };

    let [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
    let [modalTitle, setModalTitle] = useState('');
  
    function closeStatusModal() {
      setIsOpenStatusModal(false);
    }
  
    function openStatusModal(e) {
      setRowData(e);
      setModalTitle('Update Client Status')
      setIsOpenStatusModal(true);
    }
    
    const handleStatusState = async() => {
      let statusData = { status: rowData.status === false || rowData.status === 'false' ? false : true };
      updateClientStatus({"client_code":rowData.client_code,"status":statusData.status})
        .then(response => {
          if (response.status==='success') {
            closeStatusModal();
            setMsg(response.message);
            setIsSuccess(true);
            openPopup();
          } else {
            closeStatusModal();
            setMsg(response.message);
            setIsSuccess(false);
            openPopup();
          }
        })
        .finally(()=>{
          refreshClientsList();
        });
    };
  
    const handleSaveClient = () => {
        saveClient({"client_name":rowData.client_name})
        .then(response => {
          if (response.status==='success') {
            closeModal();
            setMsg(response.message);
            setIsSuccess(true);
            openPopup();
          } else {
            closeModal();
            setMsg(response.message);
            setIsSuccess(false);
            openPopup();
          }
        })
        .finally(()=>{
          refreshClientsList();
        });
    };
  
    const handleEditClient = () => {
      updateClient({"client_code":rowData.client_code,"client_name":rowData.client_name})
        .then(response => {
          if (response.status==='success') {
            closeModal();
            setMsg(response.message);
            setIsSuccess(true);
            openPopup();
          } else {
            closeModal();
            setMsg(response.message);
            setIsSuccess(false);
            openPopup();
          }
        })
        .finally(()=>{
          refreshClientsList();
        });
    };

    const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
    const [confirmationMsg, setConfirmationMsg] = useState("");
  
    function closeConfirmPopup() {
      setIsOpenConfirmPopup(false);
    }
  
    function openConfirmPopup(id_prop) {
      setclientCode(id_prop);
      setConfirmationMsg("Are you sure that you want to delete this client?");
      setIsOpenConfirmPopup(true);
    }
  
    const deleteFn = async() => {
      setIsOpenConfirmPopup(false);
      const response = await deleteClient(clientCode);
      if (response.status === 'success') {
        setClients((prevData) => {
          const newData = prevData.filter(
              (item) => item['client_code'] !== clientCode
          );
          return newData;
        });
        setMsg(response.message);
        setIsSuccess(true);
        openPopup();
      } 
      else {
        setMsg(response.message);
        setIsSuccess(false);
        openPopup();
      }
    }
  
    const DeleteButton = ({ client_code }) => {  
      return (
          <button
              className="p-1 rounded-md border border-gray-300 text-red-700 disabled:text-gray-300"
              onClick={()=>openConfirmPopup(client_code)}
          >
                  <TrashIcon height="1em" title='Delete Client' />
          </button>
      );
    };
  
    const EditButton = ({ client }) => {  
      return (
          <button
              className="p-1 rounded-md border border-gray-300 text-sky-700 disabled:text-gray-300"
              disabled={!client.status}
              onClick={()=>openModal("edit",client)}
          >
                  <PencilIcon height="1em" title='Edit Client' />
          </button>
      );
    };
  
    const columns = React.useMemo(
      () => [
          {
              accessorKey: "index",
              enableColumnFilter: false,
              enableSorting: false,
              size: 50,
              header: "S.No."
          },
          {
            accessorKey: "client_code",
            enableColumnFilter: false,
            size: 100,
            header: () => <span>Client Code</span>
          },
          {
              accessorKey: "client_name",
              enableColumnFilter: false,
              size: 800,
              header: () => <span>Client Name</span>
          },
          {
            accessorKey: "created_by",
            enableColumnFilter: false,
            size: 100,
            header: () => <span>Created By</span>
          },
          {
            accessorKey: "created_date",
            enableColumnFilter: false,
            size: 100,
            header: () => <span>Created Date</span>,
            cell: ({ row }) => (
                <div>
                    {row.original.created_date && row.original.created_date !== "" &&
                        formattedDate(row.original.created_date)}
                </div>
            ),
          },
          {
            accessorKey: "modify_by",
            enableColumnFilter: false,
            size: 100,
            header: () => <span>Modified By</span>
          },
          {
            accessorKey: "modify_date",
            enableColumnFilter: false,
            size: 100,
            header: () => <span>Modified Date</span>,
            cell: ({ row }) => (
                <div>
                    {row.original.modify_date && row.original.modify_date !== "" &&
                        formattedDate(row.original.modify_date)}
                </div>
            ),
          },
          {
            accessorKey: "status",
            enableColumnFilter: true,
            enableSorting:false,
            size: 100,
            header: () => <span>Status</span>,
            cell:({ row }) => (
              <div className='flex justify-center'>
              <div  onClick={()=>openStatusModal(row.original)}
                  className={`${row.original.status === true ? 'bg-gray-100 text-green-900' : 'bg-gray-100 text-red-900'} text-xs w-fit px-2 py-1 font-bold rounded-md cursor-default lg:cursor-pointer`}
              >
                  {row.original.status === true && "Active"}
                  {row.original.status === false && "Inactive"}
              </div>
              </div>      
            )
          },
          {
            accessorKey: "id",
            enableColumnFilter: false,
            enableSorting: false,
            size: 100,
            header: () => <center>Actions</center>,
            cell: ({ row }) => (
              <div className="flex gap-2 items-center justify-center">
                  <EditButton client={row.original}/>
                  <DeleteButton client_code={row.original.client_code}/>
              </div>
          ),
          }
      ],
      []
    );
  
    const additionalButtons = (
      <div className='flex gap-2'>
          <span className="group relative">
            <div className="absolute right-7 top-1/2 z-20 mr-0 -translate-y-1/2 whitespace-nowrap rounded-[5px] py-1.5 px-3.5 text-sm text-white opacity-0 group-hover:opacity-100">
              <div className="bottom-full right-0 rounded-md bg-gray-400 px-4 py-2.5 text-xs text-white whitespace-nowrap">
                Create New Client
              </div>
            </div>
            <button className="inline-flex items-center rounded-md p-2 text-gray-700 ring-1 ring-inset ring-gray-300" onClick={()=>openModal("create",null)}>
              <PlusCircleIcon className="h-5 w-auto"/>
            </button>
        </span>
        <button className="inline-flex items-center rounded-md p-2 text-gray-700 ring-1 ring-inset ring-gray-300" onClick={refreshClientsList}><ArrowPathIcon className="h-5 w-auto"/></button> 
      </div>
    );

    useEffect(()=>{
        const fetchClientsList = async () => {
            getClientsList()
            .then((response) => {
              if(response.status==='success' && response.data!==null && response.data.length>0){
                setClients(response.data);
              }
            })
            .catch((error) => {
              setClients([]);
              setMsg(error);
              setIsSuccess(false);
              openPopup();
            })
            .finally(()=>{
              setLoader(false);
            });
        };
    
        fetchClientsList();
      },[])

  return (
    <>
    <SidebarPanel panelheader="Client Management" navigateback={true}/>
    <Popup
      isOpen={isOpenPopup}
      msg={msg}
      closeModal={closePopup}
      isSuccess={isSuccess}
    />
    <ConfirmationPopup
        isOpenConfirmPopup={isOpenConfirmPopup}
        confirmationMsg={confirmationMsg}
        closeConfirmPopup={closeConfirmPopup}
        onStateChange={deleteFn}
    />
    <InputModalsm
        isOpen={isOpenModal}
        closeModal={closeModal}
        title={editState?"Update Client Details":"Create New Client"}
        saveName="Save"
        savefn={editState?handleEditClient:handleSaveClient}
        modalInputs={
            <div className="w-full bg-white space-y-1">
            <label htmlFor="name" className="block text-sm font-bold leading-6 text-gray-500 ps-1">
              Client Name
            </label>
            <input
              type='text'
              autoFocus
              required
              id='clientName'                  
              value={rowData.client_name} 
              onChange={(e) => setRowData({...rowData, client_name: e.target.value})}
              className={`block w-full text-sm rounded-md border-0 ring-1 ring-gray-300 focus:ring-1 focus:ring-gray-200 p-2`}
            />
            </div>
        }
    />
    <InputModalsm isOpen={isOpenStatusModal} closeModal={closeStatusModal} title={modalTitle} 
        saveName="Save" 
        savefn={handleStatusState}
        modalInputs={
          <div className='flex gap-5'>
          <div className="flex items-center gap-x-2">
                  <input
                    id="status"
                    name="status"
                    type="radio"
                    value="true"
                    checked={rowData.status === true || rowData.status === "true"}
                    onChange={(e) =>
                      setRowData({
                        ...rowData,
                        status: e.target.value,
                      })
                    }
                    autoFocus={false}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
                  />
                  <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                    Active
                  </label>
                </div>
                <div className="flex items-center gap-x-2">
                  <input
                    id="status"
                    name="status"
                    type="radio"
                    value="false"
                    checked={rowData.status === false || rowData.status === "false"}
                    onChange={(e) =>
                      setRowData({
                        ...rowData,
                        status: e.target.value,
                      })
                    }
                    autoFocus={false}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
                  />
                  <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900">
                    Inactive
                  </label>
                </div>
          </div>
        } 
      />
    <div className='m-3 border rounded border-gray-300 bg-white'>
    <VirtualizedTable hcss='max-h-[calc(100vh-16.8rem)] md:max-h-[calc(100vh-17rem)]' data={clients} columns={columns} loader={loader} loadermsg="Fetching Clients" noDataFoundmsg="No Clients Found" allowSelect={false} additionalButtons={additionalButtons}/>
    </div>
    </>
  )
}

export default ClientManagement