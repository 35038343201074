import axios from "axios";
import { API_URL } from "../../http";
import { handleApiError } from "../Common/APIUtils";

export const getProjectAssignmentList = async () => {
  try {
    const response = await axios.get(`${API_URL}/projectassignment`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// Function to save the project
export const saveProjectAssignment = async (postData) => {
  try {
    const response = await axios.post(`${API_URL}/projectassignment`, postData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// update project
export const updateProjectAssignment = async (patchData) => {
  try {
    const response = await axios.patch(`${API_URL}/projectassignment`, patchData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// update project
export const updateProjectAssignmentStatus = async (patchData) => {
  try {
    const response = await axios.patch(`${API_URL}/projectassignment/status`, patchData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// delete project
export const deleteProjectAssignment = async (_id) => {
  try {
    const response = await axios.delete(`${API_URL}/projectassignment`,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },     
      params: {
        _id: _id
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};

// Function to fetch the deployed chatbot prompt with the user's old chat for RIA Chatbot
export const GetUserAssignedActiveProjects = async () => {
  try {
    const response = await axios.get(`${API_URL}/projectassignment/users`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    const errMsg = handleApiError(error);
    return {'status': 'error', 'message': errMsg};
  }
};