import React, { useEffect, useState } from 'react'
import SidebarPanel from '../../Common/SidebarPanel'
import VirtualizedTable from '../../Common/VirtualizedTable'
import { ArrowPathIcon, PencilIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { deleteProject, getProjectsList, saveProject, updateProject, updateProjectStatus } from '../../Services/ProjectServices';
import Popup from '../../Common/Popup';
import ConfirmationPopup from '../../Common/ConfirmationPopup';
import InputModalsm from '../../Common/InputModalsm';
import { formattedDate } from '../../Common/CommonUtils';
import { getActiveClientsList } from '../../Services/ClientServices';

function ProjectManagement() {
    let [projects, setProjects]=useState([]);
    let [clients, setClients]=useState([])
    let [rowData,setRowData]=useState([]);
    let [projectCode, setprojectCode] = useState("");
    let [loader, setLoader] = useState(true);
    let [editGPT, setEditGPT] = useState(false);
    let [isOpenModal, setIsOpenModal] = useState(false);
    let [editState, setEditState] = useState(false);

    function closeModal() {
      setIsOpenModal(false);
      setRowData([]);
    }
  
    function openModal(option, project_data) {
      if(option==="create"){
        setEditGPT(true);
        setEditState(false);
        setRowData([]);
      }
      else{
        setEditGPT(false);
        setEditState(true);
        setRowData(project_data);
      }
      setIsOpenModal(true);
    }

    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);
  
    function closePopup() {
      setIsOpenPopup(false);
    }
  
    function openPopup() {
      setIsOpenPopup(true);
    }

    let [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
    let [modalTitle, setModalTitle] = useState('');
  
    function closeStatusModal() {
      setIsOpenStatusModal(false);
    }
  
    function openStatusModal(e) {
      setRowData(e);
      setModalTitle('Update Project Status')
      setIsOpenStatusModal(true);
    }
  
    const handleStatusState = async() => {
      let statusData = { status: rowData.status === false || rowData.status === 'false' ? false : true };
      updateProjectStatus({"project_code":rowData.project_code,"status":statusData.status})
        .then(response => {
          if (response.status==='success') {
            closeStatusModal();
            setMsg(response.message);
            setIsSuccess(true);
            openPopup();
          } else {
            closeStatusModal();
            setMsg(response.message);
            setIsSuccess(false);
            openPopup();
          }
        })
        .finally(()=>{
          refreshProjectsList();
        });
    };

    const refreshProjectsList = async () => {
        setLoader(true);
        getProjectsList().then((response) => {
            if(response.status==='success' && response.data!==null && response.data.length>0){
            setProjects(response.data);
            }
            else{
            setProjects([]);
            }
        })
        .catch((error) => {
            setProjects([]);
            setMsg(error);
            setIsSuccess(false);
            openPopup();
        })
        .finally(()=>{
            setLoader(false);
        });
      };
  
    const handleSaveProject = () => {
        saveProject({"project_name":rowData.project_name,"client_code":rowData.client_code,"gpt_key":rowData.gpt_key})
        .then(response => {
          if (response.status==='success') {
            closeModal();
            setMsg(response.message);
            setIsSuccess(true);
            openPopup();
          } else {
            closeModal();
            setMsg(response.message);
            setIsSuccess(false);
            openPopup();
          }
        })
        .finally(()=>{
          refreshProjectsList();
        });
    };
  
    const handleEditProject = () => {
      updateProject({"project_code":rowData.project_code,"project_name":rowData.project_name,"client_code":rowData.client_code,"gpt_key":rowData.gpt_key})
        .then(response => {
          if (response.status==='success') {
            closeModal();
            setMsg(response.message);
            setIsSuccess(true);
            openPopup();
          } else {
            closeModal();
            setMsg(response.message);
            setIsSuccess(false);
            openPopup();
          }
        })
        .finally(()=>{
          refreshProjectsList();
        });
    };

    const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
    const [confirmationMsg, setConfirmationMsg] = useState("");
  
    function closeConfirmPopup() {
      setIsOpenConfirmPopup(false);
    }
  
    function openConfirmPopup(id_prop) {
      setprojectCode(id_prop);
      setConfirmationMsg("Are you sure that you want to delete this project?");
      setIsOpenConfirmPopup(true);
    }
  
    const deleteFn = async() => {
      setIsOpenConfirmPopup(false);
      const response = await deleteProject(projectCode);
      if (response.status === 'success') {
        setProjects((prevData) => {
          const newData = prevData.filter(
              (item) => item['project_code'] !== projectCode
          );
          return newData;
        });
        setMsg(response.message);
        setIsSuccess(true);
        openPopup();
      } 
      else {
        setMsg(response.message);
        setIsSuccess(false);
        openPopup();
      }
    }
  
    const DeleteButton = ({ project_code }) => {  
      return (
          <button
              className="p-1 rounded-md border border-gray-300 text-red-700 disabled:text-gray-300"
              onClick={()=>openConfirmPopup(project_code)}
          >
                  <TrashIcon height="1em" title='Delete Project' />
          </button>
      );
    };
  
    const EditButton = ({ project }) => {  
      return (
          <button
              className="p-1 rounded-md border border-gray-300 text-sky-700 disabled:text-gray-300"
              disabled={!project.status}
              onClick={()=>openModal("edit",project)}
          >
                  <PencilIcon height="1em" title='Edit Project' />
          </button>
      );
    };
  
    const columns = React.useMemo(
      () => [
          {
              accessorKey: "index",
              enableColumnFilter: false,
              enableSorting: false,
              size: 50,
              header: "S.No."
          },
          localStorage.getItem('role') !== "PMA" && {
            accessorKey: "client_name",
            enableColumnFilter: true,
            size: 100,
            header: () => <span>Client Name</span>,
            cell: ({ row }) => (
              <div title={row.original.client_code}>
                  {row.original.client_name}
              </div>
            ),
          },
          {
            accessorKey: "project_code",
            enableColumnFilter: false,
            size: 100,
            header: () => <span>Project Code</span>
          },
          {
            accessorKey: "project_name",
            enableColumnFilter: false,
            size: 500,
            header: () => <span>Project Name</span>,
            cell: ({ row }) => (
              <div title={row.original.project_name}>
                <span className='md:hidden'>
                {row.original.project_name}
                </span>
                <span className='hidden md:flex'>
                  {row.original.project_name !== null
                      ? row.original.project_name.slice(0, 40) +
                        (row.original.project_name.length > 40 ? "..." : "")
                      : null}
                </span>
              </div>
            ),
          },
          {
            accessorKey: "created_by",
            enableColumnFilter: false,
            size: 50,
            header: () => <span>Created By</span>
          },
          {
            accessorKey: "created_date",
            enableColumnFilter: false,
            size: 50,
            header: () => <span>Created Date</span>,
            cell: ({ row }) => (
                <div>
                    {row.original.created_date && row.original.created_date !== "" &&
                        formattedDate(row.original.created_date)}
                </div>
            ),
          },
          {
            accessorKey: "modify_by",
            enableColumnFilter: false,
            size: 50,
            header: () => <span>Modified By</span>
          },
          {
            accessorKey: "modify_date",
            enableColumnFilter: false,
            size: 50,
            header: () => <span>Modified Date</span>,
            cell: ({ row }) => (
                <div>
                    {row.original.modify_date && row.original.modify_date !== "" &&
                        formattedDate(row.original.modify_date)}
                </div>
            ),
          },
          {
            accessorKey: "status",
            enableColumnFilter: true,
            enableSorting:false,
            size: 50,
            header: () => <span>Status</span>,
            cell:({ row }) => (
              <div className='flex justify-center'>
              <div  onClick={()=>openStatusModal(row.original)}
                  className={`${row.original.status === true ? 'bg-gray-100 text-green-900' : 'bg-gray-100 text-red-900'} text-xs w-fit px-2 py-1 font-bold rounded-md cursor-default lg:cursor-pointer`}
              >
                  {row.original.status === true && "Active"}
                  {row.original.status === false && "Inactive"}
              </div>
              </div>      
            )
          },
          {
            accessorKey: "id",
            enableColumnFilter: false,
            enableSorting: false,
            size: 50,
            header: () => <center>Actions</center>,
            cell: ({ row }) => (
              <div className="flex gap-2 items-center justify-center">
                  <EditButton project={row.original}/>
                  {localStorage.getItem('role') !== "PMA" && <DeleteButton project_code={row.original.project_code}/>}
              </div>
          ),
          }
      ].filter(Boolean),
      []
    );
  
    const additionalButtons = (
      <div className='flex gap-2'>
          <span className="group relative">
            <div className="absolute right-7 top-1/2 z-20 mr-0 -translate-y-1/2 whitespace-nowrap rounded-[5px] py-1.5 px-3.5 text-sm text-white opacity-0 group-hover:opacity-100">
              <div className="bottom-full right-0 rounded-md bg-gray-400 px-4 py-2.5 text-xs text-white whitespace-nowrap">
                Add New Project
              </div>
            </div>
            <button className="inline-flex items-center rounded-md p-2 text-gray-700 ring-1 ring-inset ring-gray-300" onClick={()=>openModal("create",null)}>
              <PlusCircleIcon className="h-5 w-auto"/>
            </button>
        </span>
        <button className="inline-flex items-center rounded-md p-2 text-gray-700 ring-1 ring-inset ring-gray-300" onClick={refreshProjectsList}><ArrowPathIcon className="h-5 w-auto"/></button> 
      </div>
    );

    useEffect(()=>{
        const fetchProjectList = async () => {
            getProjectsList()
            .then((response) => {
              if(response.status==='success' && response.data!==null && response.data.length>0){
                setProjects(response.data);
              }
            })
            .catch((error) => {
              setProjects([]);
              setMsg(error);
              setIsSuccess(false);
              openPopup();
            })
            .finally(()=>{
              setLoader(false);
            });
        };
        const fetchClientsList = async () => {
          getActiveClientsList()
          .then((response) => {
            if(response.status==='success' && response.data!==null && response.data.length>0){
              setClients(response.data);
            }
          })
          .catch((error) => {
            setClients([]);
            setMsg(error);
            setIsSuccess(false);
            openPopup();
          })
          .finally(()=>{
            setLoader(false);
          });
        };
    
        fetchProjectList();
        if(localStorage.getItem('role') !== "PMA"){fetchClientsList()}
      },[])

  return (
    <>
    <SidebarPanel panelheader="Project Management" navigateback={true}/>
    <Popup
      isOpen={isOpenPopup}
      msg={msg}
      closeModal={closePopup}
      isSuccess={isSuccess}
    />
    <ConfirmationPopup
        isOpenConfirmPopup={isOpenConfirmPopup}
        confirmationMsg={confirmationMsg}
        closeConfirmPopup={closeConfirmPopup}
        onStateChange={deleteFn}
    />
    <InputModalsm
        isOpen={isOpenModal}
        closeModal={closeModal}
        title={editState?"Edit Project":"New Project"}
        saveName="Save"
        savefn={editState?handleEditProject:handleSaveProject}
        modalInputs={
            <div className="w-full bg-white space-y-1">
            {localStorage.getItem('role') !== "PMA" && 
            <>
            <label htmlFor="name" className="block text-sm font-bold leading-6 text-gray-500 ps-1">
              Client Name
            </label>
            <select
                  id="client"
                  name="client"
                  className="block w-auto md:w-full rounded-md border-0 py-2 mt-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs lg:max-w-full sm:text-sm sm:leading-6"
                  value={rowData.client_code} 
                  onChange={(e) => setRowData({...rowData, client_code: e.target.value})}
                >
                  <option value="">Select Client</option>
                  {clients.map((item, index) => (
                    <option key={index} value={item.client_code}>
                      {item.client_name}
                    </option>
                  ))}
                {rowData.client_code && !clients.some(item => item.client_code === rowData.client_code) && (
                    <option value={rowData.client_code}>
                        {rowData.client_name}
                    </option>
                )}
                </select>
            </>
            }
            <label htmlFor="name" className="block text-sm font-bold leading-6 text-gray-500 pt-2.5 ps-1">
              Project Name
            </label>
            <input
              type='text'
              autoFocus
              required
              id='projectName'                 
              value={rowData.project_name} 
              onChange={(e) => setRowData({...rowData, project_name: e.target.value})}
              className={`block w-full text-sm rounded-md border-0 ring-1 ring-gray-300 focus:ring-1 focus:ring-gray-200 p-2`}
            /> 
            <label htmlFor="gpt-key" className="block text-sm font-bold leading-6 text-gray-500 pt-2.5 ps-1">
              {editState && 'Masked'} GPT Key
            </label>
            <div className='flex items-center gap-x-2'>
              <input
                type='text'
                required
                id='gpt-key' 
                disabled={!editGPT}                  
                value={rowData.gpt_key} 
                onChange={(e) => setRowData({...rowData, gpt_key: e.target.value})}
                className={`block w-full text-sm rounded-md border-0 ring-1 ring-gray-300 focus:ring-1 focus:ring-gray-200 disabled:bg-gray-100 p-2`}
              />
              {editState && <div className='rounded-md border border-gray-300 p-2 text-blue-600' onClick={()=>{setRowData({...rowData, gpt_key: ''});setEditGPT(true);}}><PencilIcon className='h-5 w-auto'/></div>}
            </div>
            </div>
        }
    />
    <InputModalsm isOpen={isOpenStatusModal} closeModal={closeStatusModal} title={modalTitle} 
        saveName="Save" 
        savefn={handleStatusState}
        modalInputs={
          <div className='flex gap-5'>
          <div className="flex items-center gap-x-2">
                  <input
                    id="status"
                    name="status"
                    type="radio"
                    value="true"
                    checked={rowData.status === true || rowData.status === "true"}
                    onChange={(e) =>
                      setRowData({
                        ...rowData,
                        status: e.target.value,
                      })
                    }
                    autoFocus={false}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
                  />
                  <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                    Active
                  </label>
                </div>
                <div className="flex items-center gap-x-2">
                  <input
                    id="status"
                    name="status"
                    type="radio"
                    value="false"
                    checked={rowData.status === false || rowData.status === "false"}
                    onChange={(e) =>
                      setRowData({
                        ...rowData,
                        status: e.target.value,
                      })
                    }
                    autoFocus={false}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
                  />
                  <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900">
                    Inactive
                  </label>
                </div>
          </div>
        } 
      />
    <div className='m-3 border rounded border-gray-300 bg-white'>
    <VirtualizedTable hcss='max-h-[calc(100vh-16.8rem)] md:max-h-[calc(100vh-17rem)]' data={projects} columns={columns} loader={loader} loadermsg="Fetching Projects" noDataFoundmsg="No Projects Found" allowSelect={false} additionalButtons={additionalButtons}/>
    </div>
    </>
  )
}

export default ProjectManagement