import React, { useEffect, useRef, useState } from "react";
import annyang from 'annyang';
import { ChatBubbleLeftEllipsisIcon, ChatBubbleLeftRightIcon } from "@heroicons/react/24/outline";
import { getAIResponse, getChat, getDeployedChatbotPromptWithChat } from "../../Services/ChatbotServices";
import SlideOver from "../../Common/SlideOver";
import ChatList from "./ChatList";
import { getFormattedCurrentDateTime } from "../../Common/CommonUtils";
import MessageItem from "./Components/MessageItem";
import RIALoader from "./Components/RIALoader";
import MessageEditor from "./Components/MessageEditor";
import SidebarPanel from "../../Common/SidebarPanel";
import Popup from "../../Common/Popup";
import { useNavigate } from "react-router-dom";
import { decryptData } from "../../Services/ProjectServices";

function Chatbot() {
  let  projectName  = sessionStorage.getItem("projectName");
  const navigate=useNavigate();
  const [promptID, setPromptID] = useState(null);
  const [queryID, setQueryID] = useState(null);
  const [message, setMessage] = useState("");
  const [receivedMessages, setReceivedMessages] = useState([]);
  const [loader, setLoader] = useState(false);

  const [open, setOpen] = useState(false);

  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [popupMsg, setPopupMsg] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);

  function closePopup() {
    setIsOpenPopup(false);
  }

  function openPopup() {
    setIsOpenPopup(true);
  }
  
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const ref = useRef(null);

  const [isListening, setIsListening] = useState(false)

  const prevPhrasesRef = useRef('');

  useEffect(() => {
    if (annyang && isListening) {
      annyang.start();
      annyang.addCallback('result', (phrases) => {
        // Get the first phrase
        const currentPhrase = phrases[0].replace('.', '').toLowerCase();
        // Check if it's different from the last one
        if (currentPhrase !== prevPhrasesRef.current) {
          // Update the ref
          prevPhrasesRef.current = currentPhrase;
          // Update the state
          setMessage((prevPhrases) => (prevPhrases ? prevPhrases + ' ' + currentPhrase : currentPhrase));
        }
      });
    } else {
      annyang.abort();
    }

    return () => {
      annyang.abort();
    };
  }, [isListening]);

  const toggleListening = () => {
    setIsListening((prevState) => !prevState);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.lastElementChild?.scrollIntoView()
    ref.current?.lastElementChild?.scrollIntoView()
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth'});
    }
  };

  const [st,setST]=useState(false);
  
  const scrollToBottomTrigger = () => {
    setST(!st);
  }
  
  const newChat = () =>{
    setQueryID(null);
    setMessage("");
    setReceivedMessages([]);
    setLoader(false);
    inputRef.current.focus();
  }

  const handleSelectedChat = (queryIdprop) => {
    if(queryIdprop && queryIdprop!=="null" && queryIdprop!==undefined && queryIdprop!==null){
      setReceivedMessages([]);
      setQueryID(queryIdprop);
      sessionStorage.setItem('chatbotQueryID',queryIdprop);
      getChat(queryIdprop, null).then(response => {
        if (response.status==='success' && response.data !== null) {
          setReceivedMessages(response.data);
          //setting the selected chats - old or lastly loaded files
          sessionStorage.setItem('loadedDocs',JSON.stringify(response.old_doc_list))
        }
      })
    }
  };

  const handleAIResponse = (query) => {
    setMessage("");
    setLoader(true);
    // Scroll to the last message when the component mounts or when new messages are received
    scrollToBottom();
    getAIResponse({"promptID":promptID,"query":query,"queryID":queryID,"status":true,"limitValue":null, "fileIds": JSON.parse(sessionStorage.getItem('loadedDocs')), "projectCode":sessionStorage.getItem("projectCode")})
      .then(response => {
        if (response.status==='success') {
          const aiMessage = {
            id: response.id,
            user: "RIA",
            message: response.data,
            chat_date_time: getFormattedCurrentDateTime()
          };
          setLoader(false);
          setReceivedMessages((prevMessages) => [...prevMessages, aiMessage]);
          if((queryID===undefined || queryID===null) && response.query_id) {
            setQueryID(response.query_id);
            sessionStorage.setItem('chatbotQueryID',response.query_id);
          }
          // Scroll to the last message when the component mounts or when new messages are received
          scrollToBottom();
        } else {
          const aiMessage = {
            user: "RIA",
            message: response.message,
            chat_date_time: getFormattedCurrentDateTime()
          };
          setLoader(false);
          setReceivedMessages((prevMessages) => [...prevMessages, aiMessage]);
          if((queryID===undefined || queryID===null) && response.query_id) { 
            setQueryID(response.query_id); 
            sessionStorage.setItem('chatbotQueryID',response.query_id);
          }
          // Scroll to the last message when the component mounts or when new messages are received
          scrollToBottom();
        }
      })
  };


  const handleSendMessage = () => {
    const newMessage = {
      user: "sender",
      message: message,
      human_msg_date_time: getFormattedCurrentDateTime()
    };
    setIsListening(false);
    setReceivedMessages((prevMessages) => [...prevMessages, newMessage]);
    // Scroll to the last message when the component mounts or when new messages are received
    scrollToBottom();
    handleAIResponse(message);
  };

  const handleEnterKey = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(()=>{
  // Validate sessionStorage.getItem('projectCode')
  const projectCode = sessionStorage.getItem('projectCode');
  if (!projectCode || projectCode === null || projectCode === undefined) {
    navigate('/');
  }
  else{ 
    const fetchPrompt = async () => {
      const response = await getDeployedChatbotPromptWithChat();
      if (response.status === 'success' && response.data !== null) {
        setPromptID(response.data.id);
        sessionStorage.setItem('chatpromptID', response.data.id);
        setReceivedMessages(response.old_chat || []);
        setQueryID(response.query_id);
        if(response.query_id!==null){sessionStorage.setItem('chatbotQueryID',response.query_id)}
        sessionStorage.setItem('loadedDocs',JSON.stringify(response.old_doc_list))
      } else {
        setPromptID('');
      }
    };
    fetchPrompt();
  } 
  },[navigate])

  let [areh, setareh] = useState(1);
  
  useEffect(() => {
    const textarea = document.getElementById('queryTextarea');
    if (textarea) {
      const defaultRows = 1;
      const maxRows = 10;
      
      textarea.rows = defaultRows;
  
      const lineHeight = 44;
  
      const calculatedRows = Math.min(maxRows, Math.ceil(textarea.scrollHeight / lineHeight));
  
      textarea.rows = calculatedRows;
      textarea.scrollTop = textarea.scrollHeight; 
      setareh(calculatedRows);
    }
  }, [message]);


  useEffect(() => {
    messagesEndRef.current?.lastElementChild?.scrollIntoView()
    ref.current?.lastElementChild?.scrollIntoView()
    if (receivedMessages.length) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [st, receivedMessages.length]);

  return (
    <div className="tracking-wider">
      <Popup
        isOpen={isOpenPopup}
        msg={popupMsg}
        closeModal={closePopup}
        isSuccess={isSuccess}
      />
      <SlideOver open={open} setOpen={setOpen} title="Chats" content={<ChatList selectedqueryId={parseInt(queryID)} handleSelectedChat={handleSelectedChat} handleNewChat={newChat}/>}/>
        <SidebarPanel
          panelheader={`${decryptData(projectName)}`}
          scrollToBottomTrigger={scrollToBottomTrigger}
          backbuttonName="Chatbot"
          navigateback={true}
          info={<article className="font-GoogleSans text-wrap max-w-56 md:max-w-full">Interact with RIA Chatbot to get realtime insights from documents</article>}
          additionalButtons={
            <>
            <div className="bottomtooltip">
             <button className="inline-flex items-center px-2 py-1 text-sm tracking-wider font-medium" onClick={newChat}><ChatBubbleLeftEllipsisIcon className="h-[1.05rem] w-auto"/>&nbsp;New Chat</button>
             <span className="tooltiptextbottom p-1 mb-7 py-1.5 me-2 hidden lg:block bg-sky-700 w-fit whitespace-nowrap text-xs px-2">Initiate a fresh chat without any prior history or context</span>
            </div>
            <div className="bottomtooltip">
            <button className="inline-flex items-center px-2 py-1 text-sm tracking-wider font-medium" onClick={()=>setOpen(true)}><ChatBubbleLeftRightIcon className="h-[1.05rem] w-auto"/>&nbsp;View Chats</button>
             <span className="tooltiptextbottom p-1 mb-7 me-2 hidden lg:block bg-sky-700 w-fit whitespace-nowrap text-xs px-2">View Chat History</span>
            </div>
            </>
          }
          upddoc={true}
          panelcontent={
            <>
              <div className="m-2 mt-0 pb-0 pt-1 px-1">
                <div id="chatbox" className={`bg-white rounded-md p-4 my-2 min-w-full h-[calc(100vh-16.5rem)] ${areh>3?'md:h-[calc(100vh-19rem)]': `${areh<2?'md:h-[calc(100vh-13.6rem)]':`${areh===2?'md:h-[calc(100vh-15.2rem)]':'md:h-[calc(100vh-16.6rem)]'}` }`} overflow-y-auto ct chatboxscroll`}>
                  <ul className="flex flex-col gap-2.5">
                    {receivedMessages.map((msg,index) => (
                      <MessageItem key={index} msg={msg} index={index} setPopupMsg={setPopupMsg} setIsSuccess={setIsSuccess} openPopup={openPopup}/>
                    ))}
                    {loader && 
                      <RIALoader/>
                    }
                    {/* This empty div with a ref will be used to scroll to the bottom */}
                    <div className='mr' ref={messagesEndRef}/>
                    <div ref={ref} />
                  </ul>
                  {receivedMessages.length === 0 && (
                    <div className="flex items-center justify-center h-[calc(100vh-19.5rem)] md:h-[calc(100vh-17rem)] text-xl tracking-widest">
                      How can I help you today?
                    </div>
                  )}
                </div>
                <div className="relative border w-full rounded-md">
                  <MessageEditor message={message} setMessage={setMessage} inputRef={inputRef} loader={loader} handleSendMessage={handleSendMessage} handleEnterKey={handleEnterKey} isListening={isListening} toggleListening={toggleListening}/>
                </div>
              </div>
            </>
          }
        />
      </div>
  );
}

export default Chatbot;
