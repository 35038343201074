import React from "react";

function Loader() {
  return (
    <div className="flex flex-col gap-4 bg-white items-center justify-center fixed w-full overflow-auto font-GoogleSans"style={{ minHeight: "86vh" }}>
      <img className="h-10" src="./loading-bars.svg" alt="Loading..." />
    </div>
  );
}

export default Loader;
