import React from "react";

function RIALoader() {
  return (
    <li className="flex items-center">
      <div className="flex-shrink-0 hidden md:block relative">
          <div className="bg-gray-300 px-1.5 py-2 rounded-full text-sky-900 text-xs font-bold tracking-wider">
            RIA
          </div>
      </div>
      <div className="py-2.5 px-2 inline-flex rounded-lg text-sm animate-bounce mt-2 ">
        <span className="flex space-x-2 animate-pulse">
          <span className="w-1 h-1 bg-gray-500 rounded-full"></span>
          <span className="w-1 h-1 bg-gray-500 rounded-full"></span>
          <span className="w-1 h-1 bg-gray-500 rounded-full"></span>
        </span>
      </div>
    </li>
  );
}

export default RIALoader;
